.landing-page{
    background-image: url('../../Assets/images/landing-image.png');
    background-size: cover;
    background-repeat: no-repeat;
    min-height: calc(100vh - 234px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 14% 10%;
    &__text{
        text-align: center;
        width: 100%;
        margin: 2rem;
        font-family: EMPrintSemiBold;
        color: white;
        font-size: 2.5rem;
    }
    Button{
        font-family: EMPrintRegular;
        border: none;
        font-size: 1.25rem;
        border-radius: 0px;
        background: #7c3f81;
        background-image: linear-gradient(45deg, #75346f 0, #6e54af 100%);
        color: white;
        transition: all 0.5s ease;
    }
    Button:hover{
        color: white;
        background: #7c3f81;
    }
}