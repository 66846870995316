@import "../../Assets/constants";
.event-header{
    background: #fafafa;
    border-bottom: 1.5px solid #eaeaea;
    height: 131px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    padding: 0 32px;
    z-index: 10;
    width: 100%;
    @include respond-to('smaller') {
        height: 95px !important;
    }
    @include respond-to('small') {
        padding: 0px 12px;
    }
    &__title{
        width: 100%;
        display: flex;
        justify-content: center;
        font-family: EMPrintSemiBold;
        font-size: 2.0125rem;
        @include respond-to('small') {
            font-size:1.2rem;
        }
    }
    &__button{
        min-width: 11rem;
        position: relative;
        display:flex;
        flex-direction: row;
        align-items: center;
        @include respond-to('small') {
            min-width: 8rem;
        }
        &.em-c-btn{
            height: 3.75rem;
            &--primary{
                width: 9rem;
            }
        }
        .em-c-tooltip{
            &__content{
                font-family: EMPrintRegular;
                font-size: 0.8rem;
                margin: 0 0 -0.8rem -60px;
                padding: 2px;
                width: fit-content;
                min-width: 2rem;
                max-width: none;
            }
            .em-c-btn.btn-icon {
                @include respond-to('small') {
                    width: 3.5rem;
                    &.em-u-margin-left-double {
                        margin-left: 0.5rem !important;
                    }
                }
            }
        }
    }
    &__next-button{
        min-width: 11rem;
        display: flex;
        justify-content: flex-end;
        @include respond-to('small') {
            min-width: 8rem;
        }
    }
}