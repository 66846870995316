@import "../../Assets/constants";
.user-card{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 35rem;
    margin-bottom: 0.35rem;
    padding: 0.8rem 1rem;
    border: 1px solid #CCCCCD;
    border-radius: 4px;
    cursor: pointer;
    @include respond-to('smaller') {
        width: 17rem !important;
    }
    @include respond-to('small') {
        width: 20rem;
    }
    @include respond-to('medium') {
        width: 20rem;
    }
    &--selected{
        border-color: #00B468;
        background-color: rgba(0, 180, 104, 0.1);
    }
    &__info{
        width: 90%;
        margin-left: 1rem;
        font-size: .875rem;
        overflow: hidden;
        white-space: nowrap;
        &--name, &--email{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        &--name{
            font-family: EMPrintSemibold;
            margin-bottom: .3rem;
        }        
        &--email{
            font-family: EMPrintLight;
        }
    }
    &__avatar{
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 50%;
    }
    &__icon{
        width: 1.5rem;
        height: 1.5rem;;
        color: #00B468;
    }
}