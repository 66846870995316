@import "../../Assets/constants";
.disclaimer-popup {
    background: white;
    position: relative;
    padding: 3rem 5rem;
    border-radius: 3px;
    text-align: center;
    @include respond-to('small') {
        width: 22rem;
        padding: 2rem;
    }
    @include respond-to('medium') {
        width: 25rem;
        padding: 2rem;
    }

    &__header {
        font-family: EMPrintLight;
        font-size: 2.5rem;
        text-align: center;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 2rem;
        border-bottom: 1px solid #e2e2e2;
        opacity: 1;
    }
    &__question {
        margin-top: 1.5rem;
        font-size: 1.125rem;
        font-family: EMPrintRegular;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-bottom: 4rem;
        max-width: 35.45rem;
        @include respond-to('small') {
            margin-bottom: 2rem;
        }
        @include respond-to('medium') {
            margin-bottom: 2rem;
        }
    }

    &__buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        .em-c-btn{
            min-width: 15.625rem;
            height: 3.875rem;
            @include respond-to('small') {
                min-width: 6rem;
            }
            @include respond-to('medium') {
                min-width: 6rem;
            }
        }
        .em-c-field{
            margin-bottom: 0;
            .em-c-field__body{
                margin-bottom: 0;
            }
        }
    }
}

.overlay{
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, .7);
    position: fixed;
    z-index: 10000 !important; 
}