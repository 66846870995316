.error-modal {
    min-height: 13.75rem;
    width: 32.5rem;
    background: white;
    position: relative;
    padding: 3rem;
    border-radius: 3px;
    align-items: center;
    @media (max-width: 40rem) {
        width: 80%;
        min-width: 15rem;
        position: fixed;
    }
    &__question {
        font-size: 1.5rem;
        font-family: EMPrintSemibold;
        text-align: center;
    }
    &__sub-question {
        margin-top: 0.4rem;
        font-size: 1.1rem;
        font-family: EMprintRegular;
        text-align: center;
    }
    &__buttons {
        margin-top: 1.5rem;
        display:flex;
        justify-content: center;
        flex-direction: row;
        .em-c-btn{
            min-width: 5.625rem;
            height: 3rem;
        }
        .em-c-field{
            margin-bottom: 0;
            .em-c-field__body{
                margin-bottom: 0;
            }
        }
    }
}
.overlay{
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, .6);
    position: fixed;
    z-index: 10000 !important; 
}