// Breakpoints
$ipad-pro-size: 85rem;
$ipad-size: 49rem;
$iphone-size: 35rem;
$iphone-older-size: 25rem;
$smaller-size: 25.5rem;
$small-desktop-size: 65rem;
$desktop-size: calc($ipad-pro-size + 1px);

@mixin respond-to($media) {
    @if $media == smaller {
        @media only screen and (max-width: $smaller-size) {
            @content;
        }
    }
    @if $media == small {
        @media only screen and (max-width: $iphone-size) {
            @content;
        }
    }
    @else if $media == medium {
        @media only screen and (min-width: $iphone-size + 0.1) and (max-width: $ipad-size) {
            @content;
        }
    }
    @else if $media == larger-medium {
        @media only screen and (min-width: $ipad-size + 0.1) and (max-width: $small-desktop-size - 0.1) {
            @content;
        }
    }
    @else if $media == large {
        @media only screen and (min-width: $small-desktop-size) {
            @content;
        }
    }
}