.question-page {
    height: 100vh;
    width: 100vw;
    position: absolute;
    &__container{
        height: 100%;
    }
    .loading-indicator{
        margin: 0px;
    }
}