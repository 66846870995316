@import "../../Assets/constants";
.user-picker{
    height: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    font-family: EMPrintRegular;
    .em-c-alert{
        width: 35rem;
        margin: 0 auto;
    }
    &__body{
        @include respond-to('small') {
            .checkin-confirmation {
                &__subtitle{
                    font-size: 2rem;
                }
                &__picture{
                    width: 12rem;
                    height: 12rem;
                }
                &__title{
                    font-size: 1.5rem;
                }
            }
        }
        &-container{
            display: contents;
        }
        height: 100%;
        padding: 0 15%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &--title{
            font-family: EMPrintLight;
            font-size: 3rem;
            margin: 0 0 3rem;
            text-align: center;
            @include respond-to('small') {
                margin-top: 8rem;
                text-align: center;
                font-size: 2rem;
            }
            @include respond-to('medium') {
                margin-top: 8rem;
                text-align: center;
                font-size: 2rem;
            }
        }  
        &--input-field{
            border: 1px solid #D0D0D0;
            border-radius: 4px;
            padding: 0.5rem 1rem;
            width: 35rem;
            @include respond-to('smaller') {
                width: 17rem !important;
            }
            @include respond-to('small') {
                width: 20rem;
            }
            @include respond-to('medium') {
                width: 20rem;
            }
            &--title{
                font-family: EMPrintLight;
                font-size: 0.8rem;
                color: #545459;
            }
            input{
                border:none;
            }
            .em-c-input{
                padding: 5px 0!important;
            }
        }
        &--loading{
            margin: 2rem 0;
            width: 100%;
        }
        .em-c-field{
            margin-bottom: 5px;
            .em-c-field__body .em-c-input:focus-visible{
                outline: unset;
            }
        }
        &--list{
            overflow: scroll;
            overflow-x: hidden;
            max-height: 25rem;
        }
        &--button{
            &.em-c-btn--primary.em-c-btn {
                margin-top: 3.5rem;
                height: 3.5rem;
                width: 10.5rem;    
            }     
        }
    }
}