@import "../../Assets/constants";
.attendeelist-pane {
    width: 100%;
    &__title{
        display: flex;
        justify-content: space-between;
        margin: 0.5rem 0; 
        @include respond-to('small') {
        }
        &-text{
            width: 12rem;
            font-size: 1.5rem;
            font-family: EMPrintSemibold;
        }
        &-icon{
            display: flex;
            justify-content: flex-end;
            margin: 0.8rem;
        }
        &.expanded{
            @include respond-to('small') {
                display: block;
            }
            @include respond-to('medium') {
                flex-direction: column;
            }
        }
        &-search{
            width: 70%;
            @include respond-to('smaller') {
                width: 74% !important;
                margin: 1rem 0 !important;
            }
            @include respond-to('small') {
                width: 90%;
                margin: 1rem 0;
            }
            @include respond-to('medium') {
                width: 90%;
                margin: 1rem 0; 
            }
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            animation-name: search-showup;
            animation-duration: 1.5s;     
            &--field{
                display: flex;
                input{
                    border-radius: 0px;
                }
            }
            &--status{
                font-family: EMPrintRegular;
                font-size: 1rem;
                display: flex;
                flex-direction: row;
                margin: 0.5rem 0;
                cursor: context-menu;
                .active{
                    padding: 0.3rem;
                    margin-right: 0.4rem;
                    border-radius: 4px;
                    color: white;
                    background: #0C69B0;
                }
                .inactive{
                    padding: 0.3rem;
                    margin-right: 0.4rem;
                    border-radius: 4px;
                    background: #EBEBEB;
                }
            }
        }
    }
    &__paginationContainer {
        justify-content: flex-end;
        display: flex;
        padding-top: 1rem;
    } 
    .em-l-grid {
        &__item {
            min-width: 0 !important;
            @include respond-to('small') {
                width: inherit !important;
            }
            @include respond-to('medium') {
                width: 100% !important;
            }
            @include respond-to('large') {
                width: 50%;
            }
        }
    }
    @keyframes search-showup {
        from {width: 0px;}
        to {width: 90%;}
    }
}