.switch-toggle{
    display: flex;
    margin: 0.8rem 0;
    justify-content: space-between;
    font-size: 1rem;
    &.disabled{
        color: #ccc;
        .slider{
            background-color: #f1eeee;
            cursor: no-drop;
        }
    }
    .switch{
        position: relative;
        display: inline-block;
        width: 40px;
        height: 24px;
    }
    .slider{
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }
    .slider:before {
        position: absolute;
        content: "";
        height: 19px;
        width: 19px;
        left: 3px;
        bottom: 2.5px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }
    input{
        opacity: 0;
        width: 0;
        height: 0;
    }
    input:checked + .slider {
        background-color: #235694;
    }
    input:focus + .slider {
        box-shadow: 0 0 1px #235694;
    }       
    input:checked + .slider:before {
        -webkit-transform: translateX(14.5px);
        -ms-transform: translateX(14.5px);
        transform: translateX(14.5px);
    }
    .slider.round {
        border-radius: 40px;
    }   
    .slider.round:before {
        border-radius: 50%;
    } 
}