.setting-page{
    background: #FAFAFA 0% 0% no-repeat padding-box;
    opacity: 1;
    padding-bottom: 2rem;
    min-height: calc(100% - 233px);
    &__loading {
        height: 42rem;
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
            height: 43.58rem;
        }
    }
    .loading-indicator{
        margin: 0px;
        padding: 18rem 0 18rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .em-c-alert.em-c-alert--warning {
        margin-bottom: 1rem;
    }
}