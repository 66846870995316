@import "../../Assets/constants";
.error-card{
    background: white;
    padding: 1.625rem;
    border: 1px solid #BFBFBF;
    margin-top: 1rem;
    border-bottom: 1px solid transparent;
    background: linear-gradient(0.25turn, #3a397b, #0C69B0) bottom white no-repeat;
    background-size: 100% 5px;
    &__container {
        max-width: 48rem;
        padding: 3.5rem;
        @include respond-to('small') {
            padding: 1.5rem;
        }
        @include respond-to('medium') {
            padding: 2rem;
        }
    }
    &__text {
        color: #545454;
        font-family: EMPrintRegular;
        font-size: 2.85rem;
        @include respond-to('small') {
            font-size: 1.9rem;
        }
        @include respond-to('medium') {
            font-size: 2rem;
        }
    }
    &__subtext{
        margin-top: 2rem;
        font-family: EMPrintRegular;  
        font-size: 1.2rem;
        color: gray;
        @include respond-to('small') {
            font-size: 1rem;
        }
        &--link {
            margin-top: 0.5rem;
            font-size: 1.2rem;
            color: #0c69b0;
            font-family: EMPrintRegular;
        }
    }    
}