@import "./constants";

.date-indicator {
    height: 3.375rem;
    width: 3.375rem;
    min-width: 3.375rem;
    border: 1px solid $hover-color;
    display: inline-block;
    &__header {
        background: rgba(12, 105, 176, .1);
        color: $hover-color;
        font-family: EMPrintSemiBold;
        text-transform: uppercase;
        text-align: center;
        font-size: .75rem;
        padding: .1rem 0;
    }
    &__body {
        text-align: center;
        font-size: 1.625rem;
        font-family: EMprintBold;
        color: $hover-color;
        margin-top: .05rem;
    }
}
.date-indicator-badge {
    padding: .5rem .3rem .375rem;
    margin-top: .4rem;
    display: block;
}