@import "../../Assets/constants";
.sub-header{
    margin: 1.5rem 0 0.8rem 0;
    border-bottom: 1px solid #e5e5e5;
    background: white;
    &__container{
        &__title{
            font-family: EMPrintSemiBold;
            font-size: 2.2rem;
            @include respond-to('small'){
                font-size: 1.8rem;
            }
        } 
        &__sub-title{
            font-family: EMPrintLight;
            font-size: 1.2rem;
        }
        margin: 2rem 0 1.5rem 0;
        display: flex;
        justify-content: space-between;
        @include respond-to('small'){
            display: block;
        }
        @include respond-to('medium'){
            display: block;
        }
    }
    &__filter{
        display: flex;
    }
}