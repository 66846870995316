@import "../../Assets/constants";
.preview-data{
    background: white;  
    max-width: 90%;
    padding: 3rem 2rem;
    @include respond-to('medium') {
        min-width: 20rem;
    }
    &__buttons{
        display: flex;
        justify-content: flex-end;
        @include respond-to('medium') {
            justify-content: inherit;
        }
        .em-c-btn{
            margin-top: 2rem;
            border-radius: 0px;
            .spinner > div{
                background-color: white;
            }
        }
    }    
    &__nodata{
        margin-left: 0.5rem;   
        margin-top: 3rem;
        display: flex;
        font-size: 1.4rem;
        font-family: EMPrintRegular;
        color: #5d5d5d;
        height: 6rem;
        @include respond-to('medium') {
            font-size: 1rem;
            position: fixed;
        }
    }
    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }
    ::-webkit-scrollbar-track {
        background: #f1f1f1; 
    }
    ::-webkit-scrollbar-thumb {
        background: #a9a9a9; 
    }
    ::-webkit-scrollbar-thumb:hover {
        background: #888; 
    }   
    .em-c-table{
        &-object {
            max-height: 26rem;
            position: relative;
            overflow: auto;
            margin-bottom: 0;
            &__body-inner{
                overflow: inherit;
            }
        }
        &__cell {
            padding: 1.4rem 0.8rem;
            background: white;
        }
        &__row {
            font-size: 0.95rem;
        }
    }
}