.attendee-card {
    display: flex;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    height: 2.5rem;
    &__name {
        padding-top: 0.1rem;
        font-family: 'EMPrintRegular';
        font-size: 0.95rem;
        color: #767676;
        padding: 0.5rem 1rem;
    }
    &__status {
        display: flex;
        width: 1rem;
        &.checked-in {
            background: #3FAD3F;
        }
        &.not-checked-in {
            background: #D6D6D6;
        }
        &.walked-in {
            background: #B4D405;
        }
        &--text {
            font-family: 'EMPrintSemibold';
            font-size: 0.7rem;
            color: #FFFFFF;
            width: 100%;
        }
        &--icon {
            height: 1rem;
            margin: 0px 0.3rem 0 -0.15rem !important;
            &.not-checked-in {
                transform: rotate(45deg);
            }
            &.admin {
                width: 1rem;
                color: white;
            }
        }
    }
}