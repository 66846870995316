@import "../../Assets/constants";
.export-setting {
    &__course-id {
        display: flex;
        margin-bottom: 0.5rem;
        font-family: 'EMPrintSemibold';
        &--title {
            width: 7.5rem;
            font-family: 'EMPrintSemibold';
            font-size: 1.5rem;
            margin: 0.5rem 0;
        }
        &--input {
            border-radius: 0px;
            border: 1px solid #E5E5E5;
            @include respond-to('medium') {
                width: 15rem;
            }
        }
        &--detail {
            width: 52%;
            @include respond-to('smaller'){
                width: 71% !important;
            }
            .em-c-btn {
                border-radius: 0px;
                margin-top: 1rem;
                font-family: 'EMPrintRegular';
                @include respond-to('medium') {
                    margin-top: 0.5rem;
                    width: 13rem;
                }
                @include respond-to('small') {
                    margin-top: 0.5rem;
                    width: 13rem;
                }
                .spinner > div{
                    background-color: white;
                }
            }
        }
        &--list-selectbox {
            border-radius: 0;
            font-family: 'EMPrintRegular';
            @include respond-to('small') {
                option{
                    font-size: 0.65rem;
                }
                width: 20rem;
            }
        }
    }
    &__content {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
        &--section {
            display: flex;
            align-items: baseline;
            padding-bottom: 1rem;
            &__title {
                font-family: 'EMPrintSemibold';
                font-size: 1.5rem;
                padding-right: 0.5rem;
                padding-top: 1rem;
            }
            &__subtitle {
                font-family: 'EMPrintSemiBold';
                font-size: 1.2rem;
                padding-right: 0.5rem;
            }
            &__titlesection {
                font-family: 'EMPrintRegular';
                font-size: 1rem;
                margin: 0.5rem 0;
            }
            &__note{
                font-family: 'EMPrintRegular';
                font-size: 1rem;
            }
        }
        &--sectionexpand {
            display: flex;
            align-items: baseline;
        }
        &--title {
            font-family: 'EMPrintRegular';
            font-size: 1rem;
            margin: 0.5rem 0;
        }
        &--detail {
            font-family: 'EMPrintRegular';
            font-size: 1rem !important;
            margin: 1.5rem 0;
            width: 50%;
            padding-bottom: 1rem;
            @include respond-to('larger-medium'){
                width: 60%;
            }
            @include respond-to('medium'){
                min-width: 20rem;
            }
            @include respond-to('small'){
                min-width: 20rem;
            }
            &_item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 0.5rem 0;
                background: #FFFFFF;
                border: 1px solid #E5E5E5;
                padding: 0.5rem 1rem;
                &--list {
                    display: flex;
                    svg {
                        margin: 0.65rem;
                        cursor: none;
                    }
                    .em-c-tooltip__content {
                        width: fit-content;
                        @include respond-to('small'){
                            left: 30px;
                        }
                        @include respond-to('medium'){
                            left: 0px;
                        }
                    }
                    &-selectbox {
                        padding-right: 2rem;
                        border-radius: 0px;
                    }
                }
            }
        }
    }
    &__buttons {
        display: flex;
        padding-top: 2rem;
        .em-c-btn {
            border-radius: 0px;
            margin-right: 1rem;
            font-family: 'EMPrintRegular';
        }
    }
}