.year-picker{
    display: flex;
    color: #0C69B0;
    font-family: EMPrintBold;
    border: 0px;
    background-position: calc(100% + 0.2em) 50%;
    &.em-c-select{
        margin-left: -0.5rem;
        padding: 0.5rem 1.3rem 0.5rem 0.5rem;
    }
}