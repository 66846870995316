@import "../../Assets/constants";

.tutorial-modal{
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border-radius: 5px;
    background: white;
    width: 60%;
    height: 80%;
    overflow: scroll;
    padding: 2rem;
    video{
        height: 100%;
    }
    @include respond-to('medium') {
        max-width: 29rem;
    }
    @include respond-to('small') {
        max-width: 19rem;       
    }
    @include respond-to('smaller') {
        padding: 1.5rem !important;      
    }
    &__close{
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1rem;
    }
    &__link{
        font-family: 'EMPrintRegular';
        margin-top: 1rem;
        a{
            color: #3190d9;
            text-decoration: underline;
            margin-left: 0.5rem;
        }
    }
}