@import "../../Assets/constants";
.question{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    &__body{
        height: calc(100% - 131px);
        padding: 0 15%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @include respond-to('smaller') {
            height: calc(100% - 95px) !important;
        }
    }
    &__content{
        &--walkin{
            margin-bottom: 8rem;
            max-width: 41.45rem;
            @include respond-to('medium') {
                margin-bottom: 4rem;
            }
        }
        &--badge{
            margin-bottom: 8rem;
            max-width: 44.48rem;
            @include respond-to('smaller') {
                margin-bottom: 3rem !important;
            }
            @include respond-to('small') {
                margin-bottom: 5rem;
            }
            @include respond-to('medium') {
                margin-bottom: 4rem;
            }
        } 
    }
    &__text{
        font-family: EMPrintLight;
        font-size: 3.125rem;
        text-align: center;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 2rem;
        border-bottom: 1px solid #e2e2e2;
        opacity: 1;
        @include respond-to('smaller') {
            font-size: 2.2rem !important;
            margin-top: 0 !important;
        }
        @include respond-to('small') {
            font-size: 2.4rem;
            margin-top: 2rem;
        }
        @include respond-to('medium') {
            font-size: 2.5rem;
        }
    }
    &__subtext{
        margin-top: 1.5rem;
        font-size: 1.125rem;
        font-family: EMPrintRegular;
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }
    &__buttons{
        width: 100%;
        margin: 0 auto;
        .em-l-grid__item.em-u-margin-left {
            margin-left: 0;    
        }
    }
    &__checkbox{
        margin-top: 2rem;
    }
}
