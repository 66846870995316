@import "../../Assets/constants";
.version-card{
    background: white;
    padding: 3rem;
    border: 1px solid #BFBFBF;
    margin-top: 1rem;
    @include respond-to('medium') {
        min-width: 15rem;
        width: 100%;
    }
    &__body{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @include respond-to('smaller') {
            display: block;     
        }
        @include respond-to('small') {
            display: block;     
        }
        @include respond-to('medium') {
            display: block;     
        }

        &__title{
            font-family: EMprintSemibold;
            font-size: 2rem;
            margin: 0 0 2rem;
        }
        &__details{
            font-family: EMprintLight;
            font-size: 1.2rem;
        }
    }
}