// Breakpoints
$ipad-pro-size: 85rem;
$ipad-size: 49rem;
$iphone-size: 35rem;
$iphone-older-size: 25rem;
$smaller-size: 25.5rem;
$small-desktop-size: 60rem;
$desktop-size: calc($ipad-pro-size + 1px);
// Colors
$hover-color: #0C69B0;
$white: #fff;
$eventx-purple: #3a397b;
$background-color: #f5f5f5;
$disabled-color: #a5a5a5;
// Borders
$clean-border-color: #cccccd;
$clean-border: 1px solid $clean-border-color;