.homepage{
    background: #FAFAFA 0% 0% no-repeat padding-box;
    opacity: 1;
    padding-bottom: 2rem;
    min-height: calc(100% - 266px); 
    &__loading {
        height: 30rem;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}