@import "../../Assets/constants";
.footer.em-c-footer{
    min-height: 130px;
    margin-top: 0;
    z-index: 39;
    background-color: #545459;
    border-top-color: #4f4f54;
    padding: 0;
    @include respond-to('small') {
        padding-bottom: 1rem;
    }
    @include respond-to('medium') {
        padding-bottom: 1rem;
    }
    svg{
        fill: #fff;
    }
}

.footer{
    .em-c-footer__inner{
        margin-top: 3rem;
        @media (max-width: 70.55rem) {
            margin-top: 2rem;
        }
        &--left{
            font-size: 0.85rem;
            font-family: EMPrintLight;
            color: #cccccd;
            align-items: center;
            @include respond-to('small') {
                margin-bottom: 1rem;
            }
            @include respond-to('medium') {
                margin-bottom: 1rem;
            }
        }
    }
    .feedback{
        cursor: pointer;
        margin: 0.5rem 0;
        display: inline-flex;
        font-family: EMPrintSemibold;
        color: #e2e2e5;
        svg{
            margin-right: 0.5rem;
        }
    }
    .whats-new{
        cursor: pointer;
        margin: 0.5rem;
        display: inline-flex;
        font-family: EMPrintSemibold;
        color: #e2e2e5;
        svg{
            margin-right: 0.5rem;
        }
    }
}