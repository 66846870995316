@import "../../Assets/constants";
.access-setting{
    &__user-picker{
        position: absolute;
        margin-top: -2.5rem;
        z-index: 5000;
        margin-left: 8.8rem;
        @include respond-to('small') {
            width: 19.5rem;
            margin-left: 1.1rem;
            margin-top: -5.7rem;
        }
        @include respond-to('medium') {
            width: 26.5rem;
        }
        @include respond-to('larger-medium') {
            width: 25rem;
        }
        &.no-data{
            border: 1px solid #cccccd;
            font-family: EMPrintSemibold;
            padding: 0.7rem;
            width: 34.59rem;
            background: white;
            @include respond-to('small') {
                width: 19.5rem;
            }
            @include respond-to('medium') {
                width: 26.5rem;
            }
            @include respond-to('larger-medium') {
                width: 30rem;
            }
        }
        &--list{
            border: 1px solid #cccccd;
            width: 34.6rem;
            max-height: 17rem;
            overflow: auto;
            overflow-x: hidden;
            z-index: 5000;
            @include respond-to('small') {
                width: 19.5rem;
            }
            @include respond-to('medium') {
                width: 26.5rem;
            }
            @include respond-to('larger-medium') {
                width: 30rem;
            }
            .user-card{
                background-color: white; 
                padding: 0.5rem 0;
                border: none;
                margin-bottom: -0.3rem;
                @include respond-to('small') {
                    width: 19.5rem !important;
                }
                @include respond-to('medium') {
                    width: 26.5rem;
                }
                @include respond-to('larger-medium') {
                    width: 30rem;
                }
            }
        }
        &__not-exist{
            position: absolute;
            z-index: 5000;
            margin-top: -2.395rem;
            margin-left: 8.8rem;
            &.no-data{
                border: 1px solid #cccccd;
                font-family: EMPrintSemibold;
                padding: 0.7rem;
                width: 34.59rem;
                background: white;
                @include respond-to('small') {
                    width: 19.5rem;
                }
                @include respond-to('medium') {
                    width: 26.5rem;
                }
                @include respond-to('larger-medium') {
                    width: 30rem;
                }
            }
            @include respond-to('small') {
                margin-top: -5.6435rem;
                margin-left: 1.05rem;
            }
        }
    }
    &__icon{
        width: 1.5rem;
        height: 1.5rem;;
        color: #d82424;
        cursor: pointer;
    }
    &__list-loading{
        padding: 9rem !important;
    }
    &__body{
        @include respond-to('small') {
            min-width: 21.5rem !important;
        }
        @include respond-to('medium') {
            min-width: 42.5rem;
        }
        @include respond-to('larger-medium') {
            min-width: 42.5rem;
        }
    }
    .em-c-table-object {
        margin: 2rem 0;
        &__body-inner{
            background: white;
        }
    }
    .em-c-table-object__header{
        display: flex;
        border: 1px solid #e5e5e5;
        margin-bottom: 1.3rem;
        padding: 1rem;
        @include respond-to('small') {
            flex-direction: column;
            min-width: 21.5rem;
        }
        @include respond-to('medium') {
            min-width: 42.5rem;
        }
        @include respond-to('larger-medium') {
            min-width: 42.5rem;
        }
        .em-c-field__body {
            margin: 0px;
            .em-c-select.em-u-clickable {
                border-radius: 0px;
                width: 7rem;
                background-color: white;
            }
        }
        .em-c-search__body {
            width: 34.6rem;
            margin: 0 0.75rem;
            @include respond-to('small') {
                width: 19.5rem;
                margin: 0.5rem 0;
            }
            .em-c-search__input {
                width: 35rem;
                border-radius: 0px;
                height: 2.75rem;
                @include respond-to('small') {
                    width: 17rem;
                    max-width: 20rem;
                }
                @include respond-to('medium') {
                    max-width: 26.5rem;
                }
                @include respond-to('larger-medium') {
                    max-width: 30rem;
                }
            }
            .access-setting__body--loading {
                padding: 0.65rem;
                position: absolute;
                z-index: 100;
                margin: 0 28rem;
                @include respond-to('small') {
                    margin: 0 13.5rem;
                }
                @include respond-to('medium') {
                    margin: 0 20rem;
                }
                @include respond-to('larger-medium') {
                    margin: 0 23.5rem;
                }            
            }
        }
        .em-c-toolbar__item{
            margin: 0;
        }
    }
    .em-c-table__cell {
        padding: 1.6rem 0.8rem;
        font-family: EMPrintRegular;
        border: none;
        &.icon{
            text-align: center;
        }
    }
    .em-c-btn{
        border-radius: 0px;
        &--primary,&--disabled {
            font-family: EMprintRegular;
            padding: 0 1rem;
        }
    }
}