@import "../../Assets/constants";
.badge-reader{
    height: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    font-family: EMPrintRegular;
    &__body{
        height: calc(100% - 131px);
        padding: 0 15%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        @include respond-to('smaller') {
            height: calc(100% - 95px) !important;
        }
        @include respond-to('small') {
            padding: 0 11%;
        }
        &--title{
            font-family: EMPrintLight;
            font-size: 3.5rem;
            margin-bottom: 0.3rem;
            max-width: 39rem;
            &.tap{
                font-family: EMPrintRegular;
                font-size: 3rem;
                margin-bottom: 0.3rem;
                max-width: none;
                @include respond-to('small') {
                    max-width: 20rem;
                    font-size: 2.4rem;
                }
            }
            &.non-tap{
                @include respond-to('small') {
                max-width: 20rem;
                }
            }      
            @include respond-to('smaller') {
                max-width: 20rem;
                font-size: 2rem !important;
            }
            @include respond-to('small') {
                max-width: 20rem;
                font-size: 2.5rem;
            }
        }
        &--sub-title{
            margin: 1rem;
            font-size: 1.5rem;
            color: #585858;
            max-width: 45rem;
            &__tap{
                max-width: none;
            }
            @include respond-to('small') {
                font-size: 1.2rem;
                max-width: 17rem;
            }
            @include respond-to('medium') {
                max-width: 28rem;
            }
        }
        &--picture{
            width: 15rem;
            margin: 1rem 0 0 0;
            background: transparent;
            @include respond-to('smaller') {
                width: 10rem;
            }
        }
        &--link{
            font-family: EMPrintRegular;
            text-decoration: underline;
            color: #0C69B0;
            cursor: pointer;
        }
        &--hidden-input{
            width: 41rem;
            border: none;
            text-align: center;
            color: transparent;
            text-shadow: 0 0 0 black;
            font-size: 2.5rem;
            height: fit-content;
            &::placeholder{
                font-size: 1rem;
            }
        }
        &--loading-indicator {
            height: 45%;
        }
    }
    &__context{
        display: contents;
    }
}