@import "../../Assets/constants";
.event-card{
    background: white;
    padding: 1.625rem;
    border: 1px solid #BFBFBF;
    margin-top: 1rem;
    @include respond-to('medium') {
        min-width: 15rem;
        width: 100%;
    }
    &:hover{
        border-bottom: 1px solid transparent;
        background: linear-gradient(0.25turn, #3a397b, #0C69B0) bottom white no-repeat;
        background-size: 100% 5px;
    }
    &__body{
        display: flex;
        justify-content: space-between;
        @include respond-to('smaller') {
            display: block;     
        }
        @include respond-to('small') {
            display: block;     
        }
        @include respond-to('medium') {
            display: block;     
        }
        &--group{
            display: flex; 
            width: 88%;
            @include respond-to('small') {
                display: block;
                width: 100%;
            }
        }
        &-info{
            width: 100%;
            &--header{
                align-items: center;
                margin-top: 0.6rem;
                margin-left: 1.5rem;
                margin-bottom: 0.5rem;
                font-size: 2.2rem;
                font-family: EMprintSemibold;
                max-width: 70%;
                @include respond-to('small') {
                    align-items: center;
                    margin-left: 0;
                    margin-bottom: 0;
                    font-size: 1.5rem;
                    font-family: EMprintSemibold; 
                    max-width: 89%;
                    border-bottom: none;       
                }
                @include respond-to('medium') {
                    max-width: 100%;
                }
                border-bottom: 1px solid #cccccd;
                &__container{
                    margin-bottom: 0.75rem;
                    @include respond-to('small') {
                        margin-bottom: 0;
                    }
                    &__extend{
                        color: #f55b5b;
                        font-family: EMprintRegular;
                        font-size: 1.5rem;
                    }
                }
                &__tag{
                    display: flex;
                    margin-bottom: 0.75rem;
                }
            } 
            &--detail{
                width: 100%;
                display: flex;
                @include respond-to('small') {
                    display: block;
                }
                &__item{
                    font-size: 0.8125rem;
                    font-family: EMprintRegular;
                    display: flex;
                    align-items: center;
                    margin-top: 0.4rem;
                    margin-left: 1.5rem;
                    @include respond-to('small') {
                        margin-left: 0;
                    }
                    @include respond-to('smaller') {
                        margin-left: 0;
                    }
                    span{
                        margin-top: 0.8rem;
                        margin-left: 0.5rem;
                    }
                    &--icon{
                        margin-right: 1.5rem;
                        @include respond-to('small') {
                            margin-right: 0;
                        }                       
                    }
                }
            }
        }      
        &-buttons{
            font-size: 1rem;
            font-family: EMPrintRegular;
            display: flex; 
            height: 100%;
            justify-content: center;
            @include respond-to('smaller') {
                justify-content: space-evenly;
            }
            @include respond-to('small') {
                justify-content: space-evenly;
            }
            @include respond-to('medium') {
                flex-direction: none;
            }
            @include respond-to('larger-medium') {
                flex-direction: column;
            }
            @include respond-to('large') {
                flex-direction: column;
            }
            &__track-attendance{     
                margin: 1rem 0 0.1rem;      
                width: 11rem;
                height: 3.4rem;
                @include respond-to('smaller') {
                    width: 10rem;
                    height: 3rem;
                    margin: 1.5rem 0.5rem 0 0.5rem;  
                }
                @include respond-to('small') {
                    width: 10rem;
                    height: 3rem;
                    margin: 1.5rem 0.5rem 0 0.5rem;  
                }
                @include respond-to('medium') {
                    min-width: 14rem;
                    height: 3rem;
                    margin: 1.5rem 0.5rem 0 0.5rem; 
                }
                .spinner > div{
                    background-color: white;
                }
                &.em-c-btn--disabled{
                    background: #eaeaea;
                    color: #b1b1b1;
                }
            }
            &__setting{
                margin: 1rem 0 0.1rem;  
                width: 11rem;
                height: 3.4rem;
                @include respond-to('smaller') {
                    width: 10rem;
                    height: 3rem;
                    margin: 1.5rem 0.5rem 0 0.5rem;  
                }
                @include respond-to('small') {
                    width: 10rem;
                    height: 3rem;
                    margin: 1.5rem 0.5rem 0 0.5rem;  
                }
                @include respond-to('medium') {
                    min-width: 16rem;
                    height: 3rem;
                    margin: 1.5rem 0.5rem 0 0.5rem;  
                }
            }      
        }
    }
}