@import "../../Assets/constants";
.event-filter{
    display: flex;
    width: 10rem;
    align-items: center;
    justify-content: flex-end;
    &__container{
        cursor: pointer;
        display: flex;
        flex-direction: row-reverse;
    }
    &__text{
        font-size: 0.77rem;
        text-align: center;
        font-family: EMPrintLight;
        color: #0C69B0;
    }
    &__icon{
        margin-right: 0.5rem;
        width: 1.25rem;
        height: 1.25rem;
        color: #0C69B0;
        @include respond-to('smaller') {
            margin-right: 0.1rem;
        }
        @include respond-to('small') {
            margin-right: 0.1rem;
        }
        @include respond-to('larger-medium') {
            margin-right: 0;
        }
        &.disable{
            color: #0C69B0;
        }
    }
}