@import "../../Assets/constants";
.event-filter-modal{
    width: 25rem;
    min-height: 20rem;
    background: white;
    border-radius: 4px;
    padding: 2rem;
    @include respond-to('small') {
        width: 20rem;
    }
    &__header{
        font-family: EMPrintSemiBold;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        padding-bottom: 0.5rem;
        &--title{
            font-size: 2.2rem;
        }
        &--clear-btn{
            cursor: pointer;
            font-size: 1rem;
            color: #0C69B0;
            border-bottom: 1px solid #225896;
            justify-content: flex-end;
        }
        &--close{
            width: 100%;
            display: flex;
            justify-content: space-between !important;
            &-icon{
                cursor: pointer;
                width: 1.2rem;
                height: 1.2rem;
                color: black;
            }
        }
        &--close{
            width: 100%;
            display: flex;
            justify-content: flex-end;
            &-icon{
                cursor: pointer;
                width: 1.5rem;
                height: 1.5rem;
                color: black;
            }
        }
    }    
    &__list{
        hr{
            margin: 0px;
        }
    }
    &__error{
        font-family: EMPrintRegular;
        font-size: 1.2rem;
        color: gray;
    }
}
.overlay{
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: rgba(0, 0, 0, .4);
    position: fixed;
    z-index: 100; 
}