.tag{
    display: flex;
    align-items: center;
    padding: 0 0.7rem;
    margin-right: 0.75rem;
    background: black;
    color: white;
    font-size: 0.9rem;
    font-family: EMprintSemibold;
    border-radius: 32px;
    height: 1.5rem;
}