.app{
  height: 100vh;
  position: absolute;
  width: 100vw;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0px;  
  background: transparent; 
}
// input[type="search"] {
//   -webkit-appearance: searchfield;
// }
// input[type="search"]::-webkit-search-cancel-button {
//   -webkit-appearance: searchfield-cancel-button;
// }