@import "../../Assets/constants";
.circular-progress-bar {
    font-family: 'EMPrintSemibold';
    [data-test-id] {
        width: 25rem;
        height: 25rem;
        @include respond-to('small') {
            width: 20.5rem;
            height: 20.5rem;        
        }
        @include respond-to('medium') {
            width: 20rem;
            height: 20rem;
        }
    }
    &__bar {
        padding: 0.5rem;
        margin-bottom: 2.5rem;
    }
    &__detail {
        display: flex;
        justify-content: center;
        font-size: 1.5rem;
        margin: 0.5rem;
        &--number {
            font-family: 'EMPrintBold';
            font-size: 2.5rem;
            text-align: right;
            width: 35%;
            margin-right: 1rem;
        }
        &--text {
            display: flex;
            align-items: center;
            font-size: 1.15rem;
            width: 85%;
            margin-left: 1rem;
            width: 85%;
        }
    }
    &__number {
        display: flex;
        flex-direction: row;
        justify-content: center;
        &--first {
            font-size: 8rem;
        }
        &--total {
            display: flex;
            padding-bottom: 15%;
            font-size: 3rem;
            align-items: flex-end;
        }
    }
    &__status {
        display: flex;
        width: 0.8rem;
        &.checked-in {
            background: #3FAD3F;
        }
        &.not-checked-in {
            background: #D6D6D6;
        }
        &.walked-in {
            background: #B4D405;
        }
    }
}