@import "../../Assets/constants";
.email-input{
    border: 1px solid #CCCCCD;
    width: 31.5rem;
    overflow: auto;
    @include respond-to('medium') {
        width: 25rem;
        max-height: 3.5rem;
    }
    @include respond-to('small') {
        width: 15rem;
        max-height: 3.5rem;
    }
    &__container{
        display: flex;
        &--inner{
            width: 28rem;
            &-list{
                display: inline-block;
                font-family: 'EMprintRegular';
                font-size: 0.85rem;
                margin: 0.5rem 0.1rem 0.1rem 0.5rem;
                padding: 0.3rem;
                border-radius: 4px;
                background: rgb(232, 232, 233);
                &--remove{
                    cursor: context-menu;
                    margin-left: 0.5rem;
                }
                &--field{
                    width: 7rem;
                    border: none;
                }
            }
        }
        &--loading{
            width: 100%;
            padding: 0.5rem 1.1rem;
            text-align: end;
        }
    }
    &__user-picker{
        display: flex;
        &--list{
            padding-bottom: 1rem;
            margin-left: -1px;
            border: 1px solid #CCCCCD;
            overflow: auto;
            overflow-x: hidden;
            max-height: 18.75rem;
            background: white;
            position: absolute;
            width: 31.5rem;
            z-index: 1000;
            @include respond-to('medium') {
                width: 25rem;
            }
            @include respond-to('small') {
                width: 15rem;       
            }
            .user-card{
                border: none;
                border-radius: 0px;
                margin: 0 0 -0.8rem 0;
                background: white;
                &__info{
                    margin: 0px;   
                }
            }
            &.no-data{
                padding: 0.5rem;
            }
        }
    }
}