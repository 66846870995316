@import "../../Assets/constants";
.attendance-setting {
    margin-top: 2rem;
    &__loading{
        padding: 0 !important;
        height: 27rem;
    }
    &__left-pane {
        display: flex;
        width: 50%;
    }
    &__right-pane {
        @media (min-width: 47rem) {
            display: flex;
            min-width: 30rem;
        }
        @include respond-to('larger-medium') {
            display: flex;
            min-width: 30rem;
        }
        &__msgtext {
            font-size: 1.8rem;
            font-family: EMPrintRegular;
            align-items: center;
            justify-content: center;
            color: #5A5A5A;
        }
    }
    .em-l-grid {
        &__item {
            @media (min-width: 30rem) {
                min-width: 30rem;
            }
        }
    }

    .em-l-grid--2up.em-l-grid--break-fast .em-l-grid__item {
        @media (min-width: 30rem){
            width: none; 
        }
    }
}