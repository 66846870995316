@import "../../Assets/constants";
.setting-sub-header{
    margin: 1.5rem 0 0.9rem 0;
    border-bottom: 1px solid #e5e5e5;
    background: white;
    &__event-name{
        font-family: EMPrintSemiBold;
        font-size: 2.2rem;
        margin-bottom: 0.5rem;
        @include respond-to('small') {
            font-size: 1.75rem;
        }
    }
    &__tabbar{
        display: flex;
        &--tab{
            margin-right: 2.5rem;
            font-family: EMPrintRegular;
            font-size: 0.9375rem;
            padding: 0.8rem 0;
            @include respond-to('small') {
                display: flex;
                align-items: center;
                font-size: 0.75rem;
            }
        }
        .active{
            cursor: pointer;
            font-family: EMPrintSemiBold;
            color: #0C69B0;
            border-bottom: 2px solid #0C69B0; 
        }
        .disable{
            cursor: pointer;
            color: #767676;
        }
    }
}