.month-picker{
    display: flex;
    margin: 0 3rem;
    width: 100%;
    &__month{
        font-family: EMPrintRegular;
        font-size: 0.9375rem;
        margin: 0 0.8rem;
        padding: 0.8rem 0;
    }
    .active{
        cursor: pointer;
        font-family: EMPrintSemiBold;
        color: #0C69B0;
        border-bottom: 2px solid #0C69B0;
    }
    .disable{
        color: #bfbfbf;
    }
    .heldEvent{
        cursor: pointer;
        color: black;
    }
    &.em-c-select.em-u-clickable{
        font-family: EMPrintSemibold;
        border: none;
        width: 8rem;
        margin: 0;
        color: #0C69B0;
    }
}