@import "../../Assets/constants";
.checkin-confirmation{
    max-width: 47rem;
    min-height: 22rem;
    background: white;
    text-align: center;
    border-radius: 4px;
    font-family: EMPrintRegular;
    @include respond-to('small') {
        min-height: 19rem;
    }
    &__picture{
        width: 16rem;
        height: 16rem;
        border-radius: 50%;
        margin: 0.8rem;
        margin-top: 4.5rem;
        @include respond-to('small') {
            width: 9.5rem;
            height: 9.5rem;
            margin-top: 2.8rem;   
        }
        @include respond-to('larger-medium') {
            margin-top: 1.5rem;
        }
    }
    &__title{
        font-size: 1.7rem;
        max-width: 100%;
        margin: 1.2rem 0;
        color: #007096;
        @include respond-to('small') {
            margin: 1rem 0;
            font-size: 1.4rem;
            max-width: 17rem;
        }
    }
    &__subtitle{
        margin: 1rem 0;
        font-size: 2.2rem;
        color: #007096;
        @include respond-to('small') {
            font-size: 1.7rem;
        }
    }
}