.options-setting{
    &__content{
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
        &--title{
            font-family: 'EMPrintSemibold';
            font-size: 1.5rem;
            margin: 0.5rem 0;
            margin-bottom: 1.5rem;
        }
        &--options{
            font-family: 'EMPrintRegular';
            font-size: 1rem !important;
            background: #FFFFFF;
            border: 1px solid #E5E5E5;
            padding: 0.5rem 1rem;
        }
    }
}