@import "../../Assets/constants";
.event-types {
    align-items: center;
    margin-top: 0.5rem;    
    @include respond-to('small') {
        margin:  1rem 0;
        display: flex;
        justify-content: center;
    }
    .em-c-btn {
        font-family: EmPrintRegular;
        display: inline-block !important;
        border-style: solid;
        border-color: #0C69B0;
        border-radius: 0;
        width: 8rem;
        height: 2.25rem;
        padding: .4rem;
        @include respond-to('small') {
            font-size: 0.95rem;
            width: 6.5rem;
        }
        @include respond-to('smaller') {
            font-size: 0.8rem;
            width: 5.15rem;
        }
    }
    .selected {
        color: #fff;
        background-color: #0C69B0;
        font-family: EmPrintSemibold;
        @include respond-to('small') {
            font-size: 0.95rem;
            width: 6.5rem;
        }
        @include respond-to('smaller') {
            font-size: 0.8rem !important;
            width: 5.15rem !important;
        }
    }
}