@import "../../Assets/constants";
.em-c-btn .em-c-btn__text{
    margin-bottom: 1px;
}
.em-c-btn.btn-large{
    background: transparent linear-gradient(82deg, #002F6C 0%, #104E8F 50%, #3190D9 100%) 0% 0% no-repeat padding-box;
    font-size: 1.75rem;
    height: 5rem;
    font-family: EMPrintLight;
}
.em-l-grid__item.em-u-margin-left {
    @include respond-to('small') {
        margin-left: 0.1rem !important;
    }
}