@import "../../Assets/constants";
.header.em-c-header {
    background: transparent linear-gradient(85deg, #7A4183 0%, #A71065 100%, #D82424 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 6.5rem;
    margin-bottom: 0;
    span{
        margin-left: .3rem;
        opacity: .3;
    }
    &__body{
        padding: 1.8rem 0 2.625rem;
    }
    &__tutorial-link{
        cursor: pointer;
        display: flex;
        align-items: flex-end;
        font-size: 1rem;
        color: #FFFFFF;
        margin-bottom: 0.25rem;
        @include respond-to('small') {
            display: flex;
            cursor: pointer;
            justify-content: center;
            font-size: 0.8rem;
            margin: 0.5rem 0;
        }
    }
    .em-c-header__title {
        display: flex;
        justify-content: space-between;
        &-container{
            width: 100%;
        }
        @include respond-to('small') {
            font-size: 2.9rem;
            display: flex;
            flex-direction: column;
            &-container{
                width: 100%;
                align-items: center;
                padding-right: 0;
            }
            .landing{
                font-size: 2.3rem;
            }
        }
        @include respond-to('medium') {
            font-size: 2.9rem;
        }
    }
}