@charset "UTF-8";
/*-----------------------------------*\
    #STYLE
\*------------------------------------*/
/**
 * CONTENTS
 *
 * ABSTRACTS
 * Variables
 * Mixins
 * Themes
 *
 * BASE
 * Base
 * Body
 * Fonts
 * Forms
 * Headings
 * Links
 * Lists
 * Main
 * Media
 * Reset
 * Tables
 * Text
 *
 * COMPONENTS
 * Blocks
 * - Hero block
 * - Inset block
 * - Media block
 * - Promo block
 * - Stacked block
 * - Tile
 * - Well
 * Buttons
 * - Button
 * - Button bar
 * - Button group
 * - Tag
 * Cards
 * - Action card
 * - Card
 * - Contact card
 * - Expandable card
 * - Icon card
 * - Media card
 * - Picture card
 * - Solid card
 * Form controls
 * - Checkbox
 * - Checkbox dropdown
 * - Date picker
 * - Fieldset
 * - File upload
 * - People picker
 * - Radio button
 * - Range slider
 * - Search
 * - Select
 * - Switch
 * - Text area
 * - Text input
 * - Toggle
 * - Typeahead
 * Headers and footers
 * - Footer
 * - Horizontal header
 * - Vertical header
 * Icons and media
 * - Icon
 * - Image with caption
 * - Logo
 * - Profile image
 * - Video
 * Interactive panels
 * - Accordion
 * - Expandable button
 * - Tab
 * Lists and collections
 * - Bulleted list
 * - Card list
 * - Definition list
 * - Horizontal list
 * - Link list
 * - Masonry card list
 * Messaging
 * - Alert
 * - Badge
 * - Loading indicator
 * - Modal
 * - Progress bar
 * - Status label
 * - Tooltip
 * Navigation
 * - Back to top
 * - Breadcrumbs
 * - Pagination
 * - Progress tracker
 * - Tree navigation
 * Sections
 * - Section
 * Tables
 * - Complex table
 * - Display table
 * - Table
 * - Table with filter
 * - Table with groups
 * Text
 * - Heading
 * - Page header
 * - Quote
 * - Text link
 * - Text passage
 * Toolbars
 * - Collapsible toolbar
 * - Toolbar with controls
 * - Toolbar with form
 * - Toolbar with legend
 * - Toolbar with pagination
 * - Toolbar with search
 *
 * LAYOUT
 * Layout
 *
 * UTILITIES
 * Alignment
 * Clickable
 * Color
 * Display
 * Font size
 * Font weight
 * Links
 * Spacing
 * Visibility
 * Widths
 *
 */
/**
  * Import Sass partials
  *
  * 1) The file structure is following the 7-1 structure foundFooter
  *    from Sass Guidelines: https://sass-guidelin.es/#the-7-1-pattern
  */
/*------------------------------------*\
    #ABSTRACTS
\*------------------------------------*/
/**
  * 1) Themes define the global colors applied to the system.
  *    The default theme is red, but can be changed.
  */
/*------------------------------------*\
  #VARIABLES
\*------------------------------------*/
/**
 * CONTENTS
 *
 * COLORS
 * Application colors.....Colors that are used to create the design system's UI
 * Neutral colors.........Grayscale colors, including white and black
 * Dim colors.............Semi-transparent tints and tones
 * Utility colors.........Colors used to provide feedback
 *
 * TYPOGRAPHY
 * Font sizing............Font sizing
 *
 * LAYOUT
 * Max-widths.............Maximum layout container width
 * Sidebar width..........Width of sidebar found in two-column layouts
 * Wide sidebar width.....Width of wide sidebar found in two-column layouts.
 *
 * SPACING
 * Default spacing........Spacing between elements
 * Padding................Pads elements more than the default
 *
 * BORDERS
 * Border width...........Border thicknesses
 * Border radius..........Border radius definitions
 *
 * ANIMATION
 * Animation speed........Transition/animation speed variables
 * Animation easing.......Easing variables
 *
 * BREAKPOINTS
 * Breakpoints............Global breakpoint definitions
 */
/*------------------------------------*\
  #COLORS
\*------------------------------------*/
/**
 * Application colors
 * 1) Colors that are used to create the design system's UI
 */
/**
  * Neutral colors
  * 1) Neutral colors are grayscale values used throughout the application
  */
/**
  * Dim colors
  * 1) Dim colors are semi-transparent tints and tones
  */
/**
  * Utility colors
  * 1) Utility colors are colors used to provide feedback, such as alert messages, form validation, etc.
  */
/*------------------------------------*\
  #TYPOGRAPHY
\*------------------------------------*/
/**
  * Font sizing
  */
/*------------------------------------*\
  #LAYOUT
\*------------------------------------*/
/**
  * Max width
  */
/**
  * Width of sidebar found in two-column layouts.
  */
/**
  * Width of wide sidebar found in two-column layouts.
  */
/*------------------------------------*\
  #SPACING
\*------------------------------------*/
/**
  * Default spacing
  * 1) This defines the vertical spacing between components.
  */
/**
  * Padding
  * 1) Pads elements more than the default, but not quite double
  */
/*------------------------------------*\
  #BORDERS
\*------------------------------------*/
/**
  * Border width
  */
/**
  * Border radius
  */
/*------------------------------------*\
  #RADIUS
\*------------------------------------*/
/**
  * Border radius
  */
/**
  * Border radius buttons
  */
/**
  * Border radius inputs
  */
/*------------------------------------*\
  #BUTTONS
\*------------------------------------*/
/**
  * Button heights
  */
/*------------------------------------*\
  #ANIMATION
\*------------------------------------*/
/**
  * Transition speed
  */
/**
  * Animation easing
  */
/*------------------------------------*\
  #BREAKPOINTS
\*------------------------------------*/
/**
  * Breakpoints
  */
/*------------------------------------*\
  #BLUE THEME ASSIGNMENTS
\*------------------------------------*/
/*------------------------------------*\
  #DARK THEME ASSIGNMENTS
\*------------------------------------*/
.em-theme--dark {
  background: #111122;
  color: #fbfbfa;
}
.em-theme--dark .iffframe {
  border: 1px solid #343434;
  background-color: #111122;
}
.em-theme--dark pre {
  background: #212131;
  border: 1px solid #343434;
}
.em-theme--dark .em-c-field__required {
  color: #fe000c;
}
.em-theme--dark .em-c-field__label {
  color: #fbfbfa;
}
.em-theme--dark label {
  color: #fbfbfa;
}
.em-theme--dark pre code {
  border: 0px;
}
.em-theme--dark code {
  background: #212131;
  border: 1px solid #343434;
  color: #fff;
}
.em-theme--dark .show-more:before {
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0, #212131 50%, #212131 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #212131 50%, #212131 100%);
}
.em-theme--dark .em-c-page-header--underline {
  border-bottom: 3px solid #343434;
}
.em-theme--dark .em-c-header--vertical {
  background: #111122;
}
.em-theme--dark .em-c-btn--is-dropdown {
  background-color: #111122;
  color: #00a3e0;
  border: 1px solid #4d4d4d;
}
.em-theme--dark .em-c-dropdown-check.em-is-active .em-c-btn--is-dropdown,
.em-theme--dark .em-c-dropdown-check.em-is-active .em-c-dropdown-check__panel {
  background-color: #111122;
  border-bottom: 1px solid #111122;
}
.em-theme--dark .em-c-btn--secondary:not(.em-c-btn--disabled) {
  color: #00a3e0;
}
.em-theme--dark .em-c-btn--secondary:not(.em-c-btn--disabled):hover,
.em-theme--dark .em-c-btn--secondary:not(.em-c-btn--disabled):focus {
  border-color: #00a3e0;
  color: #00a3e0;
}
.em-theme--dark .em-c-btn--tertiary:not(.em-c-btn--disabled) {
  color: #00a3e0;
}
.em-theme--dark .em-c-btn--tertiary:not(.em-c-btn--disabled):hover,
.em-theme--dark .em-c-btn--tertiary:not(.em-c-btn--disabled):focus {
  border: 0;
  color: #fff;
}
.em-theme--dark .em-c-btn--tertiary:not(.em-c-btn--disabled):hover {
  color: #3190d9;
}
.em-theme--dark .em-c-btn--bare:not(.em-c-btn--disabled) {
  color: #00a3e0;
}
.em-theme--dark .em-c-btn--bare:not(.em-c-btn--disabled):hover,
.em-theme--dark .em-c-btn--bare:not(.em-c-btn--disabled):focus {
  background-color: rgba(255, 255, 255, 0.2);
  color: #00a3e0;
}
.em-theme--dark .em-c-hero .em-c-btn--reversed,
.em-theme--dark .em-c-promo-block .em-c-btn--reversed {
  color: #fff;
}
.em-theme--dark .em-c-hero .em-c-btn--reversed:hover,
.em-theme--dark .em-c-promo-block .em-c-btn--reversed:hover {
  color: #111122;
}
.em-theme--dark .em-c-card {
  border: 1px solid #4d4d4d;
  background-color: #212131;
  color: #fff;
}
.em-theme--dark .em-c-card .em-c-card__footer {
  background: #212131;
}
.em-theme--dark .em-c-card.em-c-card--raised {
  box-shadow: none;
}
.em-theme--dark .em-c-card.em-c-card--capped {
  border-top: 10px solid #00a3e0;
}
.em-theme--dark .em-c-card.em-c-card--vermilion {
  border-top-color: #460a13;
}
.em-theme--dark .em-c-card.em-c-card--gray {
  border-top-color: #a5a5a5;
}
.em-theme--dark .em-c-card.em-c-card--bordered {
  border: 1px solid #808080;
}
.em-theme--dark .em-c-card.em-c-card--flat-lightblue {
  background-color: #e1efff;
  border-color: #e1efff;
  color: #111122;
}
.em-theme--dark .em-c-card.em-c-card--flat-lightgray {
  background-color: #eeeeee;
  border-color: #eeeeee;
  color: #111122;
}
.em-theme--dark .em-c-card.em-c-card--flat-lavender {
  background-color: #ebe1f2;
  border-color: #ebe1f2;
  color: #111122;
}
.em-theme--dark .em-c-icon-card {
  border: 1px solid #4d4d4d;
}
.em-theme--dark .em-c-icon--card .em-c-icon--card__body {
  color: #fff;
}
.em-theme--dark a.em-c-card__body {
  text-decoration: none;
  color: #fff;
}
.em-theme--dark .em-c-card__status-dot {
  border: 2px #212131 solid;
}
.em-theme--dark .em-c-promo-block {
  background: #212131;
}
.em-theme--dark .em-c-solid-card {
  border: 1px solid #4d4d4d;
}
.em-theme--dark .em-c-solid-card .em-c-solid-card__footer {
  background: #111122;
  color: #fff;
}
.em-theme--dark .em-c-solid-card.em-c-solid-card--red .em-c-solid-card__footer {
  background: #ad1723;
}
.em-theme--dark .em-c-solid-card.em-c-solid-card--blue .em-c-solid-card__footer {
  background: rgba(0, 0, 0, 0.2);
}
.em-theme--dark .em-c-solid-card .em-c-solid-card__body:not(.em-c-solid-card--blue .em-c-solid-card__body):not(.em-c-solid-card--red .em-c-solid-card__body):not(a.em-c-solid-card__body:hover):not(a.em-c-solid-card__body:focus),
.em-theme--dark .em-c-solid-card .em-c-toolbar {
  background: #212131;
  color: #fff;
  border-top: #343434;
  border-bottom: #343434;
}
.em-theme--dark .em-c-solid-card.em-c-solid-card--blue .em-c-link-list__link, .em-theme--dark .em-c-solid-card.em-c-solid-card--red .em-c-link-list__link {
  color: inherit;
}
.em-theme--dark .em-c-solid-card a.em-c-solid-card__body:hover, .em-theme--dark .em-c-solid-card a.em-c-solid-card__body:focus, .em-theme--dark .em-c-solid-card a.em-c-solid-card__body:hover .em-c-solid-card__icon {
  color: #111122;
  background: #f5f5f5;
}
.em-theme--dark .em-c-solid-card__icon {
  fill: #fff;
  color: #fff;
}
.em-theme--dark a.em-c-stacked-block__title {
  color: #00a3e0;
}
.em-theme--dark .em-c-stacked-block__title:hover {
  color: #fff;
  text-decoration: none;
}
.em-theme--dark .em-c-stacked-block__footer {
  color: #e5e5e5;
}
.em-theme--dark .em-c-stacked-block--large .em-c-stacked-block__header {
  border-bottom: 1px solid #4d4d4d;
}
.em-theme--dark .em-c-tile:not(.em-c-tile--blue):not(.em-c-tile--red):not(.em-c-tile--black):hover, .em-theme--dark .em-c-tile.em-c-tile--black {
  background: #212131;
  color: #fff;
}
.em-theme--dark .em-c-tile:not(.em-c-tile--red):not(.em-c-tile--blue):not(.em-c-tile--black) {
  color: #111122;
}
.em-theme--dark .em-c-tile:not(.em-c-tile--red):not(.em-c-tile--blue):not(.em-c-tile--black):hover {
  background: #104e8f;
}
.em-theme--dark .em-c-tile.em-c-tile--black {
  background: #212131;
  color: #fff;
}
.em-theme--dark .em-c-tile.em-c-tile--black:hover {
  background: #f5f5f5;
  color: #111122;
}
.em-theme--dark a.em-c-tags:not(.em-c-tags--light),
.em-theme--dark div.em-c-tags:not(.em-c-tags--light) {
  background: #212131;
}
.em-theme--dark .em-c-tags__link {
  background-color: #fff;
  color: #111122;
}
.em-theme--dark .em-c-checkbox--container .em-c-checkbox--checkmark:after {
  left: 5px;
  top: 1px;
  width: 4px;
  height: 9px;
  border: solid #111122;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.em-theme--dark .em-c-option-list:not(.em-c-option-list__horizontal) .em-c-option-list__item {
  border-top: 1px solid #343434;
  border-bottom: 1px solid #343434;
}
.em-theme--dark .em-c-option-list:not(.em-c-option-list__horizontal) .em-c-field.em-is-disabled .em-c-option-list__item {
  background-color: #343434;
  border-color: #4d4d4d;
}
.em-theme--dark .em-c-option-list:not(.em-c-option-list__horizontal) .em-c-option-list__item:hover, .em-theme--dark .em-c-option-list:not(.em-c-option-list__horizontal) .em-c-option-list__item:focus {
  background: #212131;
}
.em-theme--dark .em-c-option-list:not(.em-c-option-list__horizontal) .em-c-option-list__item:has(input[type=radio]:checked), .em-theme--dark .em-c-option-list:not(.em-c-option-list__horizontal) .em-c-option-list__item:has(input[type=checkbox]:checked) {
  background: #021834;
}
.em-theme--dark .em-c-field.em-has-error .em-c-option-list__item:has(input[type=radio]:checked),
.em-theme--dark .em-c-field.em-has-error .em-c-option-list__item:has(input[type=checkbox]:checked) {
  background: #fe000c;
  border-color: #fe000c;
}
.em-theme--dark .em-c-option-list:not(.em-c-option-list__horizontal) .em-has-error .em-c-checkbox--container input:checked ~ .em-c-checkbox--checkmark {
  background-color: #fe000c;
  border: 1px #fe000c solid;
}
.em-theme--dark .em-c-checkbox--container input:checked ~ .em-c-checkbox--checkmark {
  background-color: #00a3e0;
}
.em-theme--dark .em-c-field.em-has-error .em-c-option-list__item:has(input[type=radio]:checked),
.em-theme--dark .em-c-field.em-has-error .em-c-option-list__item:has(input[type=checkbox]:checked) {
  background-color: #460a13;
  border: 1px #460a13 solid;
}
.em-theme--dark .em-is-disabled .em-c-option-list__item {
  background: rgba(50, 50, 50, 0.4) !important;
  border-color: #343434;
}
.em-theme--dark .em-c-checkbox--checkmark {
  background: #111122;
}
.em-theme--dark .em-c-dropdown-check.em-is-active .em-c-dropdown-check__panel {
  background-color: #111122;
  border-color: #0c69b0;
}
.em-theme--dark .flatpickr-calendar {
  background-color: #111122;
}
.em-theme--dark .flatpickr-months option {
  background: #111122 !important;
  color: #fff;
}
.em-theme--dark .flatpickr-months option:hover {
  background: #212131 !important;
}
.em-theme--dark .flatpickr-day {
  background: #15151f;
}
.em-theme--dark .flatpickr-day.prevMonthDay,
.em-theme--dark .flatpickr-day.nextMonthDay {
  background: #111122;
  color: rgba(50, 50, 50, 0.4) !important;
}
.em-theme--dark span.flatpickr-next-month > svg,
.em-theme--dark span.flatpickr-prev-month > svg,
.em-theme--dark span.flatpickr-weekday {
  color: #a5a5a5 !important;
}
.em-theme--dark span.flatpickr-next-month:hover > svg > path,
.em-theme--dark span.flatpickr-prev-month:hover > svg > path {
  fill: #fff;
}
.em-theme--dark .em-c-fieldset {
  border-bottom-color: #343434;
}
.em-theme--dark .em-c-fieldset .em-c-fieldset__legend {
  color: #fbfbfa;
}
.em-theme--dark .em-c-field.em-has-error .em-c-textarea {
  border: 1px solid #fe000c;
}
.em-theme--dark .em-c-field.em-is-disabled .em-c-textarea:disabled {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}
.em-theme--dark textarea:not(:disabled),
.em-theme--dark input {
  background: #212131;
  color: #fff;
  border-color: #212131;
}
.em-theme--dark .em-c-field .em-c-field__note {
  color: #fff;
}
.em-theme--dark .em-c-field .em-c-field__menu {
  border-color: #212131;
}
.em-theme--dark .em-c-field.em-is-disabled .em-c-input:disabled {
  background-color: #343434;
  border-color: #4d4d4d;
}
.em-theme--dark .em-c-field.em-is-active .em-c-input:focus {
  border-color: #343434;
}
.em-theme--dark .em-c-field.em-is-valid .em-c-field__note {
  color: #00af53;
}
.em-theme--dark .em-c-field.em-is-valid .em-c-field__icon {
  color: #00af53;
  fill: #00af53;
}
.em-theme--dark .em-c-field.em-has-error .em-c-input {
  border: 1px solid #d82424;
}
.em-theme--dark .em-c-field.em-has-error .em-c-field__note,
.em-theme--dark .em-c-field.em-has-error .em-c-input::placeholder {
  color: #d82424;
}
.em-theme--dark .em-c-field.em-has-error .em-c-field__icon {
  color: #d82424;
  fill: #d82424;
}
.em-theme--dark .em-c-field.em-c-field--file-upload.em-has-error .em-c-field__body {
  border-color: #d82424;
}
.em-theme--dark .em-c-field .em-c-field__block-icon {
  color: #00af53;
  fill: #00af53;
}
.em-theme--dark .em-c-field.em-has-error .em-c-field__block-icon {
  color: #d82424;
  fill: #d82424;
}
.em-theme--dark .em-c-field .em-c-typeahead-list__item {
  background-color: #111122;
  color: #fff;
}
.em-theme--dark .em-c-field .em-c-typeahead-list__item:hover {
  background-color: #104e8f;
}
.em-theme--dark .em-has-error .em-c-radio--container .em-c-radio--checkmark:after {
  background: #d82424;
}
.em-theme--dark .em-c-radio--container .em-c-radio--checkmark:after {
  background: #00a3e0;
}
.em-theme--dark .em-c-range-slider input[type=range] {
  border: 1px solid #343434;
}
.em-theme--dark select:not(:disabled) {
  border: 1px solid #343434;
  color: #00a3e0;
}
.em-theme--dark optgroup {
  background: #111122;
}
.em-theme--dark .em-c-field.em-has-error .em-c-select {
  border: 1px solid #fe000c;
}
.em-theme--dark .em-c-field.em-has-error .em-c-field__note {
  color: #fe000c;
}
@media (min-width: 62em) {
  .em-theme--dark .em-c-header--vertical .em-c-header__nav-container {
    background: #111122;
    color: #fff;
  }
}
.em-theme--dark .em-c-tree__link {
  color: #fff;
}
.em-theme--dark .em-c-tree__link:hover {
  background-color: #111122;
}
.em-theme--dark .em-c-header--vertical .em-c-tree__link.em-is-current.em-c-tree__link--has-children {
  color: #fff;
}
.em-theme--dark .em-c-tree__link.em-is-current,
.em-theme--dark .em-c-tree__link:hover {
  background-color: #212131;
}
.em-theme--dark .em-c-tree__link.em-is-active {
  color: #fff;
}
.em-theme--dark .em-c-header .em-c-tree__link.em-is-active .em-c-tree__icon {
  color: #fff;
}
.em-theme--dark .em-c-tree__link.em-is-active .em-c-tree__icon {
  color: #fff;
}
.em-theme--dark .em-c-tree__list.em-is-active {
  border-left: 1px solid #808080;
}
.em-theme--dark .em-c-accordion__header,
.em-theme--dark .em-c-accordion__body,
.em-theme--dark .em-c-accordion {
  border-color: #343434;
}
.em-theme--dark .em-c-accordion__title {
  background: #212131;
  color: #bbbbbd;
  border-color: #343434;
}
.em-theme--dark .em-c-accordion__title:hover {
  background-color: rgba(50, 50, 50, 0.4);
}
.em-theme--dark .em-c-accordion__icon {
  color: #bbbbbd;
}
.em-theme--dark .em-c-tabs__link {
  color: #3190d9;
}
.em-theme--dark .em-c-tabs__link:hover {
  color: #00a3e0;
}
.em-theme--dark .em-c-tabs__link.em-is-active {
  background: #111122;
  color: #00a3e0;
  border-color: #343434;
  border-bottom: 1px solid #111122;
}
.em-theme--dark .em-c-tabs__body {
  border-color: #343434;
}
.em-theme--dark .em-c-tabs__list {
  border-top-left-radius: 5px;
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  overflow-y: visible;
  background: none;
  background-image: linear-gradient(to top, #6d6d6d, #111122 1px);
}
.em-theme--dark .inclusive-dates-calendar {
  background-color: #212131 !important;
  border-color: #212131 !important;
}
.em-theme--dark .sc-inclusive-dates-calendar {
  color: #fff !important;
}
.em-theme--dark .inclusive-dates__calendar-button {
  background-color: #212131 !important;
  content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><rect x="9" y="4" width="6" height="2" fill="white"/><path d="M21,23H3a2,2,0,0,1-2-2V6A2,2,0,0,1,3,4H5V6H3V21H21V6H19V4h2a2,2,0,0,1,2,2V21A2,2,0,0,1,21,23Z" fill="white"/><rect x="6" y="2" width="2" height="6" fill="white"/><rect x="16" y="2" width="2" height="6" fill="white"/><rect x="6" y="11" width="2" height="2" fill="currentColor"/><rect x="5" y="10" width="4" height="4" rx="1" ry="1" fill="white"/><rect x="11" y="11" width="2" height="2" fill="currentColor"/><rect x="10" y="10" width="4" height="4" rx="1" ry="1" fill="white"/><rect x="11" y="16" width="2" height="2" fill="currentColor"/><rect x="10" y="15" width="4" height="4" rx="1" ry="1" fill="white"/><rect x="16" y="11" width="2" height="2" fill="currentColor"/><rect x="15" y="10" width="4" height="4" rx="1" ry="1" fill="white"/><rect x="6" y="16" width="2" height="2" fill="currentColor"/><rect x="5" y="15" width="4" height="4" rx="1" ry="1" fill="white"/></svg>') !important;
}
.em-theme--dark .inclusive-dates-calendar__year-select,
.em-theme--dark .inclusive-dates-calendar__month-select,
.em-theme--dark .inclusive-dates-calendar__next-month-button,
.em-theme--dark .inclusive-dates-calendar__previous-month-button {
  background-color: #212131 !important;
  border: 1px solid #666666 !important;
}
.em-theme--dark .inclusive-dates-calendar__previous-month-button {
  content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><polygon points="14 21.121 4.879 12 14 2.879 16.121 5 9.121 12 16.121 19 14 21.121" fill="white"/></svg>') !important;
}
.em-theme--dark .inclusive-dates-calendar__next-month-button {
  content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><polygon points="10 21.121 7.879 19 14.879 12 7.879 5 10 2.879 19.121 12 10 21.121" fill="white"/></svg>') !important;
}
.em-theme--dark .inclusive-dates-calendar__month-select {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="14.0982" height="8.6883" viewBox="0 0 14.0982 8.6883"><polygon points="7.0491 8.6883 0 1.6392 1.6392 0 7.0491 5.4099 12.459 0 14.0982 1.6392 7.0491 8.6883" fill="white"/></svg>') !important;
}
.em-theme--dark .inclusive-dates-calendar__weekday-row .sc-inclusive-dates-calendar,
.em-theme--dark .inclusive-dates-calendar__calendar-row {
  color: #fff !important;
  border: 1px solid #212131 !important;
}
.em-theme--dark tbody > tr:hover {
  background: none !important;
}
.em-theme--dark .inclusive-dates-calendar__date:hover > * {
  color: #cccccd !important;
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.em-theme--dark .inclusive-dates-calendar__date--selected > * {
  background-color: #00a3e0 !important;
  color: #111122 !important;
}
.em-theme--dark .inclusive-dates-calendar__date--today > * {
  border: solid 2px #666666;
}
.em-theme--dark #datepicker-input {
  background: #212131 !important;
  color: #fff !important;
  border-color: #212131 !important;
}
.em-theme--dark #datepicker-input::placeholder {
  color: #fff !important;
}
.em-theme--dark .inclusive-dates__calendar-button {
  background-color: #fff;
}
.em-theme--dark .inclusive-dates-calendar__calendar-row,
.em-theme--dark .inclusive-dates-calendar__weekday-row {
  border: 1px solid #fff;
}
.em-theme--dark .inclusive-dates-calendar__date--in-range > * {
  background-color: #343434 !important;
  color: #cccccd !important;
}
.em-theme--dark td.inclusive-dates-calendar__date.sc-inclusive-dates-calendar.inclusive-dates-calendar__date--current.inclusive-dates-calendar__date--selected.inclusive-dates-calendar__date--in-range > *,
.em-theme--dark .em-date-picker-range > * {
  background-color: #00a3e0 !important;
  color: #111122 !important;
}
.em-theme--dark .fpo-block {
  background: #808080;
}
.em-theme--dark .fpo-block.fpo-block--dark {
  background: #343434;
}
@media (min-width: 47em) {
  .em-theme--dark .em-c-primary-nav__sublist {
    background: #212131;
  }
}
.em-theme--dark a {
  color: #f5f5f5;
}
.em-theme--dark a:hover:not(.em-c-card__body) {
  color: #e1efff;
}
.em-theme--dark .em-c-footer {
  border-top: 1px solid #343434;
  background: #212131;
}
.em-theme--dark .em-c-footer a {
  color: #f5f5f5;
}
.em-theme--dark .em-c-link-list__icon {
  fill: #343434;
  color: unset;
}
.em-theme--dark .em-c-pagination__link {
  background: #111122;
  color: #00a3e0;
  border-color: #343434;
}
.em-theme--dark .em-c-pagination__link.em-is-current {
  background: #343434;
  color: #fff;
}
.em-theme--dark .em-c-progress-tracker:before {
  border-bottom-color: #76767e;
}
.em-theme--dark .em-is-complete .em-c-progress-tracker__number {
  color: #76767e;
  background-color: #212131;
  border-color: #212131;
}
.em-theme--dark .em-is-current .em-c-progress-tracker__number {
  background-color: #e3e3e3;
  color: #111122;
}
.em-theme--dark .em-is-disabled .em-c-progress-tracker__number {
  border-color: #bbbbbd;
  background-color: #111122;
  color: #fff;
}
.em-theme--dark .em-c-text-passage a,
.em-theme--dark .em-c-text-link {
  color: #00a3e0;
}
.em-theme--dark section:not(.em-c-section--split) .em-c-section__header:not(.em-c-section__header--underline) {
  border-bottom: 1px solid #343434;
}
.em-theme--dark .em-c-section__header {
  color: #fff;
}
.em-theme--dark .em-c-table__row:hover,
.em-theme--dark .em-c-table__row:focus {
  background: rgba(255, 255, 255, 0.2);
}
.em-theme--dark .em-c-table__header {
  background: #000;
  color: #fff;
}
.em-theme--dark .em-c-table__header th {
  font-weight: 500;
}
.em-theme--dark .em-c-table--striped .em-c-table__row:nth-child(even) {
  background: #212131;
}
.em-theme--dark table:not(.em-c-table--striped) .em-c-table__row {
  border-top-color: #343434;
  border-bottom-color: #343434;
}
.em-theme--dark tr {
  border-top: 1px solid #343434;
}
.em-theme--dark tbody tr {
  border-bottom-color: #343434;
}
.em-theme--dark tbody > tr:hover {
  background: rgba(255, 255, 255, 0.2);
}
.em-theme--dark .filter-wrapper select,
.em-theme--dark .filter-wrapper select option {
  background-color: #111122;
  color: #005f7f;
}
.em-theme--dark .em-c-table__icon {
  color: #fff;
}
.em-theme--dark .em-c-table-object__body-inner {
  background: linear-gradient(to right, #111122 30%, rgba(255, 255, 255, 0)), linear-gradient(to right, rgba(255, 255, 255, 0), #111122 70%) right, linear-gradient(to right, rgba(0, 0, 0, 0.2) 0, transparent 70%), linear-gradient(to left, rgba(0, 0, 0, 0.2) 0, transparent 70%) right;
}
.em-theme--dark .em-dc-table-object {
  border: 1px solid #343434;
}
.em-theme--dark .em-c-status--negative .em-c-status__icon {
  color: #d82424;
}
.em-theme--dark .em-c-tooltip:not(.em-c-tooltip--light) .em-c-tooltip__content {
  background-color: #212131;
}
.em-theme--dark .em-c-tooltip:not(.em-c-tooltip--light) .em-c-tooltip__content:after {
  border-top-color: #212131;
  border-bottom-color: #212131;
}
.em-theme--dark .em-c-breadcrumbs__item:after,
.em-theme--dark .em-c-breadcrumbs__link {
  color: #00a3e0;
}
.em-theme--dark .em-c-breadcrumbs__link:hover {
  color: #fff;
}
.em-theme--dark .em-c-modal__window {
  background-color: #111122;
}
.em-theme--dark .em-c-modal__close-btn:hover {
  color: #fff;
}
.em-theme--dark .em-c-modal {
  background: rgba(255, 255, 255, 0.1);
}
.em-theme--dark .em-c-toolbar {
  background: rgba(50, 50, 50, 0.1);
  border-bottom-color: #343434;
  border-top-color: #343434;
}
.em-theme--dark .em-c-collapsible-toolbar,
.em-theme--dark .em-c-tag-search {
  background: #212131;
  border-top: 1px solid #343434;
  border-bottom: 1px solid #343434;
}
.em-theme--dark .em-c-collapsible-toolbar.em-is-active .em-c-toolbar {
  border-top: 1px solid #343434;
  border-bottom: 1px solid #343434;
}
.em-theme--dark .em-c-collapsible-toolbar.em-is-active .em-c-btn--toolbar {
  background: transparent;
}
.em-theme--dark .em-c-btn--toolbar {
  color: #00a3e0;
}
.em-theme--dark hr {
  background: #343434;
}
.em-theme--dark .em-c-pullquote__icon {
  fill: #4d4d4d;
  color: #4d4d4d;
}
.em-theme--dark .em-c-pullquote__citation:before {
  content: "";
  border-bottom: 1px solid #fbfbfa;
}
.em-theme--dark .em-c-text-passage blockquote {
  padding-left: 2rem;
  border-left: 3px solid #343434;
  color: #cccccd;
  font-size: 1rem;
}
.em-theme--dark figcaption {
  color: #cccccd;
}
.em-theme--dark footer svg {
  color: #fff;
}
.em-theme--dark .em-c-text-passage h2 {
  border-bottom: 1px solid #343434;
}
.em-theme--dark .em-c-well {
  border: 1px solid #4d4d4d;
}
.em-theme--dark .em-c-tag-search {
  background: #111122;
  border-top: 1px solid #343434;
  border-bottom: 1px solid #343434;
}
.em-theme--dark .em-c-progress-bar__bar {
  background-color: #343434;
}
.em-theme--dark .em-c-progress-bar__value,
.em-theme--dark .em-c-progress-bar--indeterminate__value {
  background-color: #00a3e0;
}
.em-theme--dark .em-c-range-slider input[type=range] {
  /*hide the outline behind the border*/
}
.em-theme--dark .em-c-range-slider input[type=range]::-webkit-slider-runnable-track {
  background: #343434;
}
.em-theme--dark .em-c-range-slider input[type=range]::-moz-range-track {
  background: #343434;
}
.em-theme--dark .em-c-range-slider input[type=range]:-moz-focusring {
  outline: 1px solid #111122;
}
.em-theme--dark .em-c-range-slider input[type=range]::-ms-fill-lower {
  background: #343434;
}
.em-theme--dark .em-c-range-slider input[type=range]::-moz-range-progress {
  background: #343434;
}
.em-theme--dark .em-c-range-slider input[type=range]::-ms-fill-upper {
  background: #343434;
}
.em-theme--dark .em-dc-table_striped .em-dc-table_row {
  background: #111122;
}
.em-theme--dark .em-dc-table .em-dc-table_row:hover .em-dc-table-column_input input,
.em-theme--dark .em-dc-table .em-dc-table_row:hover .em-c-table__cell {
  background-color: #021834 !important; /* $color-app-blue-light */
}
.em-theme--dark .icon-grid .icon-grid__item {
  border: 0;
  color: #e5e5e5;
}

/*------------------------------------*\
  #RED THEME ASSIGNMENTS
\*------------------------------------*/
/*------------------------------------*\
  #MIXINS
\*------------------------------------*/
/*
 * 1) Sass mixins make groups of CSS declarations for reuse throughout the system.
 * 2) Read more about Sass mixins http://sass-lang.com/guide#topic-6
 */
/*------------------------------------*\
  #SELECTION
\*------------------------------------*/
/*
  * No Select
  * 1) This prevents selection of components that shouldn't be selectable (e.g. toggles, etc.)
  */
/*------------------------------------*\
  #LINKS
\*------------------------------------*/
/*
  * Text Link
  * 1) This defines the styles for regular text links in text passages
  *    and other
  */
/*
  * Text Link Hover State
  */
/*
  * Text Link Hover State
  */
/*
  * Text Link Hover State
  */
/*------------------------------------*\
  #COLOR GRADIENTS
\*------------------------------------*/
/*
  * Lime gradient
  * 1) Starts with green in the bottom left and transitions to lime in the top right
  */
/*
  * Violet Gradient
  * 1) Starts with violet in the bottom left and transitions into blue in top right
  */
/*
  * Violet Gradient Darker
  * 1) Starts with darker violet in the bottom left and transitions into darker blue in top right
  */
/*
  * Violet Gradient Reversed
  * 1) Starts with violet in the top right instead of the bottom left like violet-gradient
  */
/*
  * Vermilion Gradient
  * 1) Starts with vermilion in the bottom left and transitions to yellow in the top right
  */
/*
  * Amber Gradient
  * 1) Starts with yellow in the bottom left and transitions to amber in the top right
  */
/*
  * Red Gradient
  * 1) Starts with yellow in the bottom left and transitions to amber in the top right
  */
/*
  * Fade Gradient
  * 1) Bottom semi-transparent to transparent overlay gradient.
  * 2) Meant to provide adequate contrast for text sitting over an image
  */
/*
  * Debug Gradient
  * 1) A garish color scheme just to be used for testing
  * 
  */
/*------------------------------------*\
  #FONTS
\*------------------------------------*/
/**
  * Font Families
  */
/*------------------------------------*\
  #TEXT
\*------------------------------------*/
/**
  * Uppercase, small text
  */
/*------------------------------------*\
  #TINTS
\*------------------------------------*/
/*------------------------------------*\
    #BASE
\*------------------------------------*/
/*------------------------------------*\
    #RESET
\*------------------------------------*/
/**
 * Border-Box http:/paulirish.com/2012/box-sizing-border-box-ftw/
 */
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/**
 * 1) Zero out margins and padding for elements
 */
html,
body,
div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

/**
 * 1) Set html5 elements to display: block
 */
header,
footer,
nav,
section,
article,
hgroup,
figure,
main {
  display: block;
}

html {
  min-height: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}
/*------------------------------------*\
    #BODY
\*------------------------------------*/
/**
 * 1) Base body styles
 */
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #fff;
  font: 100%/1.6;
  font-family: "EMprintRegular", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-style: normal;
  -webkit-text-size-adjust: 100%;
  color: #111122;
  /**
  * Body is disabled
  * 1) Added to body when modal is open so that the body underneath doesn't scroll while modal is open
  */
  /**
  * Body is Disabled on small screens
  * 1) Added to body when nav is open on small screens so body underneath doesn't scroll while nav is open
  */
}
body.em-is-disabled {
  overflow: hidden;
}
body.em-is-disabled-small {
  overflow: hidden;
}
@media all and (min-width: 62em) {
  body.em-is-disabled-small {
    overflow: auto;
  }
}

/*------------------------------------*\
  #FONTS
\*------------------------------------*/
/**
 * 1) EMprint Light - Only to be used for a big h1
 * 2) EMprint Regular - Body copy
 * 3) EMprint Regular Italic - Body copy italicized
 * 4) EMprint Semibold - Primary "bold" font. Used for h2,3,4,5,6 and strong
 * 5) EMprint Semibold Italic - Italicized bold
 * 6) EMprint Bold - Special use cases like small labels with dark backgrounds
 */
/**
 * 1) EMprint Light
 */
@font-face {
  font-family: "EMprintLight";
  src: url("../fonts/EMprintW05Light_web.eot");
  src: local("EMprint Light"), url("../fonts/EMprintW05Light_web.eot?#iefix") format("embedded-opentype"), url("../fonts/EMprintW05Light_web.woff") format("woff"), url("../fonts/EMprintW05Light_web.woff2") format("woff2"), url("../fonts/EMprintW05Light_web.ttf") format("truetype"), url("../fonts/EMprintW05Light_web.svg#EMprintLight") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
/**
 * 2) EMprint Regular
 */
@font-face {
  font-family: "EMprintRegular";
  src: url("../fonts/EMprintW05Regular_web.eot");
  src: local("EMprint Regular"), url("../fonts/EMprintW05Regular_web.eot?#iefix") format("embedded-opentype"), url("../fonts/EMprintW05Regular_web.woff") format("woff"), url("../fonts/EMprintW05Regular_web.woff2") format("woff2"), url("../fonts/EMprintW05Regular_web.ttf") format("truetype"), url("../fonts/EMprintW05Regular_web.svg#EMprintRegular") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
/**
 * 3) EMprint Regular Italic
 */
@font-face {
  font-family: "EMprintRegularItalic";
  src: url("../fonts/EMprintW05RegularItalic_web.eot");
  src: local("EMprint Regular Italic"), url("../fonts/EMprintW05RegularItalic_web.eot?#iefix") format("embedded-opentype"), url("../fonts/EMprintW05RegularItalic_web.woff") format("woff"), url("../fonts/EMprintW05RegularItalic_web.woff2") format("woff2"), url("../fonts/EMprintW05RegularItalic_web.ttf") format("truetype"), url("../fonts/EMprintW05RegularItalic_web.svg#EMprintRegularItalic") format("svg");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
/**
 * 4) EMprint Semibold
 */
@font-face {
  font-family: "EMprintSemibold";
  src: url("../fonts/EMprintW05Semibold_web.eot");
  src: local("EMprint Semibold"), url("../fonts/EMprintW05Semibold_web.eot?#iefix") format("embedded-opentype"), url("../fonts/EMprintW05Semibold_web.woff") format("woff"), url("../fonts/EMprintW05Semibold_web.woff2") format("woff2"), url("../fonts/EMprintW05Semibold_web.ttf") format("truetype"), url("../fonts/EMprintW05Semibold_web.svg#EMprintSemibold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
/**
 * 5) EMprint Semibold Italic
 */
@font-face {
  font-family: "EMprintSemiboldItalic";
  src: url("../fonts/EMprintW05SemiboldItalic_web.eot");
  src: local("EMprint Semibold Italic"), url("../fonts/EMprintW05SemiboldItalic_web.eot?#iefix") format("embedded-opentype"), url("../fonts/EMprintW05SemiboldItalic_web.woff") format("woff"), url("../fonts/EMprintW05SemiboldItalic_web.woff2") format("woff2"), url("../fonts/EMprintW05SemiboldItalic_web.ttf") format("truetype"), url("../fonts/EMprintW05SemiboldItalic_web.svg#EMprintSemiboldItalic") format("svg");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
/**
 * 6) EMprint Bold
 */
@font-face {
  font-family: "EMprintBold";
  src: url("../fonts/EMprintW05Bold_web.eot");
  src: local("EMprint Bold"), url("../fonts/EMprintW05Bold_web.eot?#iefix") format("embedded-opentype"), url("../fonts/EMprintW05Bold_web.woff") format("woff"), url("../fonts/EMprintW05Bold_web.woff2") format("woff2"), url("../fonts/EMprintW05Bold_web.ttf") format("truetype"), url("../fonts/EMprintW05Bold_web.svg#EMprintBold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
/*------------------------------------*\
	#FORMS
\*------------------------------------*/
/**
 * Input placeholders
 */
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #a5a5a5;
  font-family: "EMprintRegular", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-style: normal;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #a5a5a5;
  font-family: "EMprintRegular", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-style: normal;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #a5a5a5;
  font-family: "EMprintRegular", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-style: normal;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #a5a5a5;
  font-family: "EMprintRegular", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-style: normal;
}

/**
 * Fieldset
 */
fieldset {
  padding: 0;
  border: 0;
}

/**
 * Legend
 */
legend {
  margin-bottom: 1rem;
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #808080;
}

/**
 * Label
 */
label {
  display: block;
  margin: 0;
  color: #111122;
  font-size: 1rem;
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
}
label a {
  color: #0c69b0;
  text-decoration: underline;
  transition: color 0.15s ease;
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1rem;
  /*
  * Theming
  */
  /*body*/
  /*body*/
}
.em-theme--blue label a, label a.em-theme--blue {
  color: #0c69b0;
}
.em-theme--red label a, label a.em-theme--red {
  color: #0c69b0;
}

/**
 * Form element font size reset
 */
button,
input,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
}

/**
 * Input/Textarea base styles
 */
input,
textarea {
  width: 100%;
  padding: 0.7rem;
  border: 1px solid #cccccd;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.15s ease;
  color: #111122;
  font-family: "EMprintRegular", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-style: normal;
}
input:focus,
textarea:focus {
  outline: none;
  border-color: #3190d9;
}

.em-c-field__body:has(.em-c-field__icon) input,
.em-c-field__body:has(.em-c-field__icon) textarea {
  padding: 0.7rem 2.9rem 0.7rem 0.7rem;
}

/**
 * Textarea
 */
textarea {
  display: block;
  min-height: 4em;
}

/**
 * Reset -webkit-appearance
 */
input[type=text],
input[type=search],
input[type=url],
input[type=number],
textarea {
  -webkit-appearance: none;
}

/**
 * Get rid of x on input fields in IE
 */
input[type=text]::-ms-clear,
textarea::-ms-clear {
  display: none;
}

/**
 * Button and submit inputs reset
 */
button,
input[type=submit] {
  cursor: pointer;
  width: auto;
}

button:focus {
  outline: 0;
}

/**
 * Checkbox and radio
 */
input[type=checkbox],
input[type=radio] {
  width: auto;
  padding: 0;
  margin-right: 0.3rem;
  box-shadow: none;
}
input[type=checkbox]:focus,
input[type=radio]:focus {
  outline: 0;
}

/**
 * Datalist
 */
input[list] {
  position: relative;
}

/**
 * File input
 */
input[type=file] {
  line-height: 1;
}

/**
 * Select
 * 1) Remove default styling for Internet Explorer
 */
select {
  display: block;
  height: 2.75rem;
  border: 1px solid #cccccd;
  border-radius: 4px;
  padding: 0.7rem;
  background: #fbfbfa;
  color: #0c69b0;
  appearance: none;
  -webkit-appearance: none;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiAxNiI+PHBvbHlnb24gcG9pbnRzPSI4IDEyLjAxNCAyLjM4NiA2LjQgMy44IDQuOTg2IDggOS4xODYgMTIuMiA0Ljk4NiAxMy42MTQgNi40IDggMTIuMDE0IiBmaWxsPSIjMGM2OWIwIi8+PC9zdmc+);
  background-position: calc(100% - 0.7rem) 50%;
  background-size: 16px 16px;
  background-repeat: no-repeat;
}
select::-ms-expand {
  /* 1 */
  display: none;
}
select::-ms-value {
  /* 1 */
  background: transparent;
  color: #0c69b0;
}
select:focus {
  outline: none;
  border-color: #0c69b0;
}

/**
 * Multi-Select
 */
select[multiple] {
  height: auto;
  min-width: 12rem;
  padding: 1rem 0;
  color: #000;
  background: #fff;
}

optgroup {
  padding: 0 0.5rem;
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
}

option {
  font-family: "EMprintRegular", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-style: normal;
  padding: 0.25rem 0.5rem;
  margin: 0.25rem -0.5rem;
}

/**
 * Search reset
 */
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/*------------------------------------*\
    #HEADINGS
\*------------------------------------*/
/**
 * Set proper font families
 */
h1 {
  font-family: "EMprintLight", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-style: normal;
}

h2,
h3,
h4,
h5,
h6 {
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
}

/**
 * Heading 1
 */
h1 {
  font-size: 2.5rem;
  line-height: 1;
  margin-bottom: 1rem;
}
@media all and (min-width: 47em) {
  h1 {
    font-size: 3.5rem;
  }
}

/**
 * Heading 2
 */
h2 {
  font-size: 2.2rem;
  line-height: 1.2;
  margin-bottom: 0.3rem;
}

/**
 * Heading 3
 */
h3 {
  font-size: 1.5rem;
  line-height: 1.2;
  margin-bottom: 0.4rem;
}

/**
 * Heading 4
 */
h4 {
  font-size: 1.3rem;
  line-height: 1.2;
  margin-bottom: 0.4rem;
}

/**
 * Heading 5
 */
h5 {
  font-size: 1.15rem;
  line-height: 1.2;
  margin-bottom: 0.4rem;
}

/**
 * Heading 6
 */
h6 {
  font-size: 1rem;
  line-height: 1.2;
  margin-bottom: 0.4rem;
}

/*------------------------------------*\
    #LINKS
\*------------------------------------*/
/**
 * 1) General link styles
 */
a {
  color: #0c69b0;
  text-decoration: underline;
  outline: 0;
}

/*------------------------------------*\
    #LINKS
\*------------------------------------*/
/**
 * 1) List base styles
 */
ol,
ul {
  list-style: none;
}

/**
 * Definition list base styles
 */
dl {
  overflow: hidden;
  margin: 0;
}
dl dt:last-of-type {
  border: 0;
}
dl dd:last-of-type {
  border: 0;
}

/*------------------------------------*\
    #MAIN
\*------------------------------------*/
/**
 * 1) Base styles for main
 */
main {
  flex: 1 0 auto;
  position: relative;
}

/*------------------------------------*\
    #MEDIA
\*------------------------------------*/
/**
 * Images, Videos, and Objects
 * 1) Responsive images and objects
 * 2) Maintain aspect ratio for images
 */
img,
video,
object {
  max-width: 100%; /* 1 */
  height: auto; /* 2 */
}

/**
 * svg
 * 1) Match fill of icon to whatever text color is
 */
svg {
  fill: currentColor; /* 1 */
}

/**
 * Iframe
 */
iframe {
  margin-bottom: 1;
}

/**
 * Figure caption
 */
figcaption {
  font-size: 0.875rem;
  color: #545459;
  font-family: "EMprintRegularItalic", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-style: italic;
  padding: 0.5rem 0;
}

/**
 * Video
 */
video {
  background: #000;
  /**
  * Video that appears in a flush modal
     * 1) Ensure video doesn't overflow the viewport
     * 2) Occupy full width of modal
  */
}
.em-c-modal--flush video {
  max-height: 85vh; /* 1 */
  width: 100%; /* 2 */
}

/**
 * Styles for timeline
 */
video::-webkit-media-controls-timeline {
  background-color: #0c69b0;
  padding: 0 1em 0 1em;
  margin-bottom: 20px;
}

/**
 * Styles for volume slider
 */
video::-webkit-media-controls-volume-slider {
  background-color: #0c69b0;
  padding-top: 0;
  margin-top: 20px;
  padding-bottom: 0;
}

/**
 * Video container outline style when is focused
 */
video:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 4px;
}

/**
 * Video button outline style when is focused
 */
video::-webkit-media-controls-play-button:focus-visible,
video::-webkit-media-controls-mute-button:focus-visible,
video::-webkit-media-controls-fullscreen-button:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: -4px;
}

/**
 * Video button outline style when is focused
 */
video::-webkit-media-controls-volume-slider:focus-visible,
video::-webkit-media-controls-timeline:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 4px;
}

/*------------------------------------*\
    #TABLES
\*------------------------------------*/
/**
 * Table
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

/**
 * Table header cell
 * 1) Table column widths can be applied using utility classes
 */
th {
  text-align: left;
}

/**
 * Table row
 */
tr {
  vertical-align: top;
}

/*------------------------------------*\
    $TEXT
\*------------------------------------*/
/**
 * Paragraphs
 */
p {
  margin-bottom: 1rem;
  line-height: 1.6em;
}

/**
 * Blockquotes
 */
blockquote {
  font-size: 1.75rem;
  line-height: 1.2;
  margin: 3rem 0;
  font-family: "EMprintRegularItalic", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-style: italic;
}
blockquote cite {
  display: block;
  font-size: 0.875rem;
  margin-top: 1rem;
}
blockquote cite:before {
  content: "";
  display: inline-block;
  width: 2.5rem;
  margin-right: 0.3rem;
  border-bottom: 1px solid #000;
  position: relative;
  top: -3px;
}

/**
 * Horizontal Rules
 */
hr {
  margin: 3rem 0;
  height: 1px;
  border: 0;
  background: #e5e5e5;
}

/**
 * Abbreviations
 */
abbr {
  border-bottom: 1px dotted #808080;
  cursor: help;
}

code {
  display: inline-block;
  background: #f5f5f5;
  border: 1px solid #e5e5e5;
  padding: 0.2rem 0.5rem;
  line-height: 1.2;
  font-size: 0.9em;
}
.em-c-table code:not(.highlighter-rouge) {
  padding: 0;
  margin: 0;
  background: 0;
  border: 0;
}

pre {
  background: #f5f5f5;
  border: 1px solid #e5e5e5;
  padding: 1rem;
  overflow-x: auto;
}
pre code {
  border: 0;
}
.em-c-tabs__body pre {
  border: 0;
}
.em-c-table pre {
  display: inline;
  padding: 0;
  margin: 0;
  background: 0;
  border: 0;
  white-space: normal;
}

/**
 * Code with languages associated with them
 * 1) Override Prism sysles for code blocks with language
 */
code[class*=language-],
pre[class*=language-] {
  font-family: monospace !important;
}

/**
 * Bold
 */
strong,
b {
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
}
strong em,
b em {
  font-family: "EMprintSemiboldItalic", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: italic;
}

/**
 * Italic
 */
em {
  font-family: "EMprintRegularItalic", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-style: italic;
}
em strong {
  font-family: "EMprintSemiboldItalic", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: italic;
}

/*------------------------------------*\
    #COMPONENTS
\*------------------------------------*/
/**
 * Global
 */
/*------------------------------------*\
	#HEADER
\*------------------------------------*/
/**
 * 1) Site header styles
 * 2) Theme gradient is applied and can be switched out
 */
.em-c-header {
  position: relative;
  color: #fff;
  margin-bottom: 2rem;
  z-index: 20;
  background: #d82424;
  background-image: linear-gradient(45deg, #ad1723 0%, #d82424 100%);
  /* 2 */ /*IT WAS THEME-GRADIENT*/
  /**
  * Theming
     * 1) Apply theme gradient when theme class is added to the
     *    `body` (i.e. `<body class="em-theme--blue">`) or if a
     *    modifier is added to the header block itself (i.e.
     *    `<header class="em-c-header em-c-header--theme-blue">`)
  */
  /**
  * Sticky Header
  * 1) Header stays at the top of the page.
  * 2) IE11 dos not support this feature
  */
  /**
  * Vertical Header
  * 1) Header that appears vertical. Usually along the side of the page.
  * 2) Flex 1 is added to vertical header allowing it to take up the remaining space within its flexbox parent container
  */
  /**
  * Active Header
  * 1) When the menu button is clicked, the header becomes fixed so the navigation occupies the entire screen.
  */
}
.em-theme--blue .em-c-header, .em-c-header--theme-blue, .em-c-header--blue {
  background: #3a397b;
  background-image: linear-gradient(45deg, #3a397b 0%, #3190d9 100%);
  /* 1 */
}
.em-theme--red .em-c-header, .em-c-header--theme-red {
  background: #d82424;
  background-image: linear-gradient(45deg, #ad1723 0%, #d82424 100%);
  /* 1 */
}
@media all and (min-width: 62em) {
  .em-c-header.em-is-active + .em-c-header-overlay {
    position: fixed;
    background: rgba(0, 0, 0, 0.3);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
}
.em-c-header--sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 100;
}
.em-c-header--vertical {
  flex: 1; /* 2 */
  padding: 0;
  margin: 0;
  min-height: 100%;
  overflow: auto;
  background: #fff;
  position: relative;
  /**
  * Header in active setAttribute
  * 1) When menu button is clicked and primary nave opens
  * 2) Add position fixed to fix header and contents to page. Top and left anchor it to edges of page.
  * 3) Added width 100% to expand width of viewport
  * 4) Z-index added to put the header above all of the rest of the content outside of header.
  */
}
@media all and (min-width: 62em) {
  .em-c-header--vertical {
    width: 16.5625rem;
    /**
    * Right Border
    * 1) Overlays the full sidebar, only shown on larger screens
    */
  }
  .em-c-header--vertical:after {
    content: " ";
    display: block;
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    right: 0;
    background-color: rgba(50, 50, 50, 0.1);
    z-index: 5;
  }
}
.em-c-header--vertical.em-is-active {
  position: fixed; /* 2 */
  top: 0; /* 2 */
  left: 0; /* 2 */
  width: 100%; /* 3 */
  z-index: 4; /* 4 */
  /**
  * Active header at xl and larger widths
  * 1) Return header to original sidebar state
  */
}
@media all and (min-width: 62em) {
  .em-c-header--vertical.em-is-active {
    position: static;
    top: inherit;
    left: inherit;
    width: 16.5625rem;
    height: inherit;
  }
}
@media all and (max-width: 47em) {
  .em-c-header.em-is-active {
    position: fixed; /* 2 */
    top: 0;
    width: 100%;
    left: 0; /* 2 */
    z-index: 5;
  }
}

/**
 * Inner Wrapper
 * 1) Display flex is added to header inner to position items side by side by default
 */
.em-c-header__inner {
  /**
  * Inner Wrapper that appears within header--vertical
  1) Flex direction column is added to display items vertically within the header
  */
  /**
  * Header inner within condensed header
  */
}
.em-c-header--vertical .em-c-header__inner {
  display: flex; /* 1 */
  flex-direction: column; /* 1 */
}
.em-c-header--condensed .em-c-header__inner {
  padding-top: 0;
  padding-bottom: 0;
}

/**
 * Header Body
 * 1) Contains the header info such as title, primary navigation, or search in a side-by-side
 *    format, left and right.
 */
.em-c-header__body {
  padding: 1rem 0;
  display: flex;
  align-items: flex-end;
  width: 100%;
  /**
  * Header body within condensed header
  */
  /**
  * Header nav button within vertical header
  */
}
.em-c-header--condensed .em-c-header__body {
  align-items: center;
  margin: 0;
  padding: 0.5rem 0;
}
@media all and (min-width: 47em) and (max-width: 62em) {
  .em-c-header--vertical .em-c-header__body {
    display: flex;
  }
}
@media all and (min-width: 47em) and (max-width: 62em) {
  .em-c-header__body {
    display: block;
    /**
    * Condensed header variation
    * 1) don't drop nav below title
    */
  }
  .em-c-header--condensed .em-c-header__body {
    display: flex;
  }
}
@media all and (min-width: 47em) {
  .em-c-header__body {
    padding: 3rem 0 2.625rem;
    margin: 0;
    /**
    * Header Body within a large header
    * 1) Make room at the top for absolutely-positioned global nav
    */
    /**
    * Header Body that contains a large number of primary-nav items
    * 1) Stack primary nav on top of site title
    */
    /**
    * Header Body that appears in a vertical header
    * 1) Stack primary nav on top of site title
    */
  }
  .em-c-header--large .em-c-header__body {
    padding-top: 4.5rem; /* 1 */
  }
  .em-c-header--large-nav .em-c-header__body {
    display: block; /* 1 */
  }
  .em-c-header--vertical .em-c-header__body {
    padding: 1rem 0;
  }
}
@media all and (min-width: 62em) {
  .em-c-header__body {
    /**
    * Header body within condensed header
    */
  }
  .em-c-header--condensed .em-c-header__body {
    padding: 0.5em 0;
  }
}

/**
 * Band within header that can contain site title/logo
 */
.em-c-header__band {
  background: #d82424;
  background-image: linear-gradient(45deg, #ad1723 0%, #d82424 100%);
  padding: 0 1rem;
  background: #d82424;
  background-image: linear-gradient(45deg, #ad1723 0, #d82424 100%);
  /**
  * Theming
     * 1) Apply theme gradient when theme class is added to the
     *    `body` (i.e. `<body class="em-theme--blue">`) or if a
     *    modifier is added to the header block itself (i.e.
     *    `<div class="em-c-header__band em-c-header__band--theme-blue">`)
  */
}
.em-theme--blue .em-c-header__band, .em-c-header__band--theme--blue, .em-c-header--blue .em-c-header__band {
  background: #3a397b;
  background-image: linear-gradient(45deg, #3a397b 0%, #3190d9 100%);
  /* 1 */
}
.em-theme--red .em-c-header__band, .em-c-header__band--theme--red {
  background: #d82424;
  background-image: linear-gradient(45deg, #ad1723 0%, #d82424 100%);
  /* 1 */
}

/**
 * Header Title Container
 * 1) Contains the header title and optional kicker
 * 2) Aligns the title to the bottom to line up with primary navigation
 * 3) Spaces the title away from primary nav items
 */
.em-c-header__title-container {
  display: flex; /* 2 */
  flex-direction: column; /* 2 */
  justify-content: flex-end; /* 2 */
  padding-right: 1rem; /* 3 */
}

/**
 * Header kicker
 * 1) The kicker is an element that appears above the header title and contains tagline text
 */
.em-c-header__kicker {
  font-family: "EMprintLight", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 1;
  margin: 0 0 0.5rem;
  font-size: 0.5rem;
}
@media all and (min-width: 35em) {
  .em-c-header__kicker {
    font-size: 0.75rem;
  }
}

/**
 * Header Title
 */
.em-c-header__title {
  font-size: 1.6rem;
  letter-spacing: 1px;
  line-height: 1;
  margin: 0;
  font-family: "EMprintLight", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-style: normal;
  /**
  * Header title within condensed header
  */
}
.em-c-header--condensed .em-c-header__title {
  font-size: 1.125rem;
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
}
@media all and (min-width: 35em) {
  .em-c-header__title {
    font-size: 2.1rem;
  }
}
@media all and (min-width: 47em) {
  .em-c-header__title {
    font-size: 2.9rem;
    /**
    * Header nav container within header condensed
    */
  }
  .em-c-header--vertical .em-c-header__title {
    font-size: 2.1rem;
  }
}

/**
 * Header Title Logo
 * 1) Styled as a logotype more than a title
 * 2) This is a fix for the flickering weight on resize in Safari
 */
.em-c-header__title-logo {
  font-size: 1.2rem;
  color: #fff;
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
  position: relative;
  top: -0.25rem;
  -webkit-font-smoothing: antialiased; /* 2 */
  letter-spacing: 0;
  text-decoration: none;
}

/**
 * Header Title Link
 */
.em-c-header__title-link {
  color: #fff;
  outline: 3px solid transparent;
  transition: all 0.15s ease;
  text-decoration: none;
}
.em-c-header__title-link:focus {
  background: rgba(0, 0, 0, 0.2);
  outline: 3px solid rgba(0, 0, 0, 0.2);
}

/**
 * Header Nav Container
 * 1) Contains the search form, primary nav, and global nav
 * 2) Position it absolute so it can cover entire viewport height on small screens
 */
.em-c-header__nav-container {
  position: absolute; /* 2 */
  top: 100%; /* 2 */
  left: 0; /* 2 */
  z-index: 5;
  width: 100%;
  padding: 0 1rem;
  background: #111122;
  overflow: hidden;
  height: 0;
  transition: height 0.15s ease;
  /**
  * Active Header Nav Container
  */
  /**
  * Header nav container within condensed header
  */
  /**
  * Header nav container within vertical header
  */
}
.em-c-header__nav-container.em-is-active {
  padding: 1rem;
  height: 100vh;
}
.em-c-header--nobutton .em-c-header__nav-container {
  display: none;
}
.em-c-header--vertical .em-c-header__nav-container {
  /**
  * Active header nav container within vertical header
  */
}
.em-c-header--vertical .em-c-header__nav-container.em-is-active {
  position: static;
  display: block;
  padding: 1rem;
  height: 100vh;
  overflow-y: auto;
}
@media all and (min-width: 47em) {
  .em-c-header--vertical .em-c-header__nav-container {
    /**
    * Fixes the vertical header not displaying on IE if screen is widened while nav is active
    */
  }
  .em-c-header--vertical .em-c-header__nav-container.em-is-active {
    overflow-y: inherit;
  }
}
@media all and (min-width: 47em) {
  .em-c-header__nav-container {
    display: block;
    overflow: visible;
    height: auto;
    position: static;
    background: none;
    padding: 0;
    margin-top: 0;
    /**
    * Active header nav container (large screens)
    */
    /**
    * Header nav container within header condensed (large screens)
    */
    /**
    * Header nav container within vertical (large screens)
    */
  }
  .em-c-header__nav-container.em-is-active {
    padding: 0;
    height: auto;
  }
  .em-c-header--condensed .em-c-header__nav-container {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    margin-left: auto;
  }
  .em-c-header--vertical .em-c-header__nav-container {
    display: none;
    padding: 0 1rem;
    background: #111122;
    overflow: hidden;
    height: 0;
    /**
    * Active header nav container within vertical header (large screens)
    */
  }
  .em-c-header--vertical .em-c-header__nav-container.em-is-active {
    padding: 1rem;
  }
}
@media all and (min-width: 62em) {
  .em-c-header__nav-container {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    margin-left: auto;
    /**
    * Header nav container that contains a large number of primary-nav items (xl screens)
    * 1) Left align primary nav items
    */
    /**
    * Header nav container within vertical (xl screens)
    */
  }
  .em-c-header--large-nav .em-c-header__nav-container {
    justify-content: flex-start; /* 1 */
  }
  .em-c-header--vertical .em-c-header__nav-container {
    display: block;
    background: #fff;
    overflow: visible;
    height: auto;
    padding: 0;
    transition: none;
    /**
    * Active Header nav container within vertical header (xl screens)
    */
  }
  .em-c-header--vertical .em-c-header__nav-container.em-is-active {
    padding: 0;
  }
}

/**
 * Header Nav Button
 * 1) Hamburger button that triggers primary nav
 * 2) Class name is doubled up to avoid clashes
 * 3) Right-align button
 * 4) Hide button on large screens
 */
.em-c-btn.em-c-header__nav-btn {
  margin-left: auto; /* 3 */
  /**
  * Header nav button within header condensed
  */
  /**
  * Header nav button within vertical header
  */
}
.em-c-header--nobutton .em-c-btn.em-c-header__nav-btn {
  display: none;
}
.em-c-header--vertical .em-c-btn.em-c-header__nav-btn {
  display: inline-block;
}
@media all and (min-width: 62em) {
  .em-c-header--vertical .em-c-btn.em-c-header__nav-btn {
    display: none;
  }
}
@media all and (min-width: 47em) {
  .em-c-btn.em-c-header__nav-btn {
    display: none; /* 4 */
  }
}

/**
  * Skip to content & Skip to footer
  */
.skip-to-content,
.skip-to-footer {
  color: #fff;
  height: 30px;
  padding: 8px;
  position: absolute;
  left: -9999px;
  transform: translateY(-100%);
  transition: transform 0.3s;
  z-index: 9999;
}

.skip-to-content:focus-visible,
.skip-to-footer:focus-visible {
  left: 45%;
  transform: translateY(0%);
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #fff;
  outline-offset: 2px;
  text-decoration: none;
}

@media all and (min-width: 62em) {
  .em-c-header--vertical .skip-to-content:focus-visible {
    left: 20%;
    outline-offset: -2px;
    top: -2%;
  }
  .em-c-header--vertical .skip-to-footer:focus-visible {
    left: 30%;
    outline-offset: -2px;
    top: -2%;
  }
}
/**
* Focus on search and close buttons
*/
.em-c-header__body .em-c-btn--bare:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #fff;
  outline-offset: 4px;
  transition: none;
}

/**
* Focus on global nav buttons
*/
.em-c-global-nav__link:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #fff;
  outline-offset: 2px;
  transition: none;
}

/*------------------------------------*\
    #FOOTER
\*------------------------------------*/
/**
 * 1) This is the global footer
 */
.em-c-footer {
  padding: 1rem 0;
  margin-top: auto;
  flex-shrink: 0;
  background: #f5f5f5;
  color: #343434;
  border-top: 1px solid #e5e5e5;
}
@media all and (min-width: 44em) {
  .em-c-footer {
    padding: 3rem 0;
  }
}

/**
 * dark footer
 */
.em-c-footer--dark {
  background: #212131;
  color: #f5f5f5;
}
.em-c-footer--dark a.em-c-multicolumn-nav__link {
  color: #f5f5f5;
}

/**
 * condensed footer
 */
.em-c-footer--condensed {
  padding: 1rem 0;
}
.em-c-footer--condensed .em-c-multicolumn-nav--horizontal {
  margin-top: 0.25rem;
}

/**
 * Banded footer
 */
.em-c-footer--banded {
  background: #4d4d4d;
  padding-bottom: 0;
  padding-top: 2rem;
}

/**
 * Footer Top Section
 * 1) Section that is near the top of the footer. Spreads out on wider screens.
 */
.em-c-footer__inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.em-c-footer--banded .em-c-footer__inner {
  margin-bottom: 3rem;
}
@media all and (min-width: 44em) {
  .em-c-footer--banded .em-c-footer__inner {
    margin-bottom: 4rem;
  }
}
@media all and (min-width: 44em) {
  .em-c-footer__inner {
    flex-direction: row;
  }
}

/**
 * 1) Copyright section of the footer
 */
.em-c-footer__copyright {
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 0.75rem;
  /**
    * Copyright inside banded footer
    */
}
.em-c-footer--banded .em-c-footer__copyright {
  color: #e5e5e5;
  font-family: "EMprintRegular", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-style: normal;
}
.em-c-footer--banded .em-c-footer__copyright a {
  color: #e5e5e5;
  text-decoration: underline;
}
.em-c-footer--banded .em-c-footer__copyright p {
  margin-bottom: 0;
}
@media all and (min-width: 30em) {
  .em-c-footer--banded .em-c-footer__copyright {
    margin-left: auto;
    text-align: right;
  }
}

/**
 * Footer band
 * 1) Band placed at the bottom of the footer that can contain additional information
 */
.em-c-footer__band {
  background: #343434;
}

/**
 * Footer band inner
 * 1) Inner container of footer band
 */
.em-c-footer__band-inner {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
}
@media all and (min-width: 30em) {
  .em-c-footer__band-inner {
    flex-direction: row;
    align-items: center;
  }
}

/**
* Footer links focus
*/
.em-c-multicolumn-nav__item .em-c-multicolumn-nav__link:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 4px;
  color: #111122;
}

.em-c-footer--dark .em-c-multicolumn-nav__item .em-c-multicolumn-nav__link:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #fff;
  outline-offset: 4px;
  color: #fff;
}

/*------------------------------------*\
    #LOGO
\*------------------------------------*/
/**
 * 1) Represents the logo of ExxonMobil
 */
.em-c-logo {
  /**
  * 1) Logo within footer
  */
}
.em-c-footer .em-c-logo {
  width: 125px;
  height: 32px;
  overflow: hidden;
}
@media all and (min-width: 44em) {
  .em-c-footer .em-c-logo {
    margin-left: auto;
  }
}

/*------------------------------------*\
    #ICONS
\*------------------------------------*/
/**
 * Size variations
 */
.em-c-icon--small {
  height: 1rem;
  width: 1rem;
}

.em-c-icon--medium {
  height: 1.5rem;
  width: 1.5rem;
}

.em-c-icon--large {
  height: 3rem;
  width: 3rem;
}

/*------------------------------------*\
    #AVATAR IMAGE
\*------------------------------------*/
/**
 * 1) Avatar image is a profile image and has a certain
 */
.em-c-avatar {
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
  aspect-ratio: 1;
  /**
  * Avatar within a header
  */
  /**
  * Avatar within blue header variation
  * 1) Change background color to match
  */
  /**
  * Avatar within a typeahead list
  */
  /**
  * Avatar within tags
  * 1) Aligns avatar image with tag border-radius
  */
}
.em-c-header .em-c-avatar {
  height: 14px;
  overflow: hidden;
  border-radius: 50%;
  background: #ad1723;
  margin-right: 0.5em;
}
.em-c-header--blue .em-c-avatar {
  background: #3a397b;
}
.em-c-typeahead-list .em-c-avatar {
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
}
.em-c-tags .em-c-avatar {
  width: 1rem;
  height: 1rem;
  margin-right: 0.4rem;
  margin-left: -0.4rem; /* 1 */
}

/**
 * Blocks
 */
/*------------------------------------*\
    #HERO
\*------------------------------------*/
/**
 * 1) Block containing headline, description text, that serves as a primary content area
 */
.em-c-hero {
  display: block;
  background-color: #111122;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% 100%;
  color: #fff;
  position: relative;
}
.em-l-container .em-c-hero {
  margin: 0 -1rem;
}
@media all and (min-width: 35em) {
  .em-l-container .em-c-hero {
    margin: 0 -2rem;
  }
}

/**
 * Hero Image
 */
.em-c-hero__img {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
}
@media all and (min-width: 30em) {
  .em-c-hero__img {
    margin: 0;
  }
}

/**
 * Hero Body
 */
.em-c-hero__body {
  padding: 2rem 1rem;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 17%, rgba(0, 0, 0, 0.18) 36%, rgba(0, 0, 0, 0.78) 100%);
  /**
  * Tinted Hero Body
  */
  /**
  * Bare Hero Body
  */
}
@media all and (min-width: 35em) {
  .em-c-hero__body {
    padding: 4rem 2rem;
  }
}
@media all and (min-width: 62em) {
  .em-c-hero__body {
    padding: 5rem;
  }
}
.em-c-hero--tinted .em-c-hero__body {
  background: rgba(17, 17, 34, 0.5);
  /* 1 */
}
.em-c-hero--bare .em-c-hero__body {
  background: none;
}

/**
 * Hero Title
 */
.em-c-hero__title {
  max-width: 48rem;
  margin: 0 0 2rem;
  font-size: 2.6rem;
  line-height: 1em;
}
@media all and (min-width: 35em) {
  .em-c-hero__title {
    margin: 0 0 3rem;
    font-size: 4rem;
  }
}

/**
 * Hero Subtitle
 */
.em-c-hero__subtitle {
  max-width: 48rem;
  margin: 0 0 1rem;
  font-size: 1.5rem;
}
@media all and (min-width: 35em) {
  .em-c-hero__subtitle {
    font-size: 2.2rem;
  }
}

/**
 * Hero Description
 */
.em-c-hero__desc {
  font-size: 1rem;
  margin-bottom: 3rem;
  font-family: "EMprintLight", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-style: normal;
  max-width: 34rem;
}
@media all and (min-width: 35em) {
  .em-c-hero__desc {
    font-size: 1.25rem;
  }
}

.em-c-hero__body:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 2px;
}

/*------------------------------------*\
	#STACKED BLOCK
\*------------------------------------*/
/**
 * 1) Block that contains header, body, and footer, all stacked on top of each other
 */
.em-c-stacked-block {
  max-width: 40rem;
}

/**
  * Stacked block title
  */
.em-c-stacked-block__header {
  margin-bottom: 0.5rem;
  /**
   * Stacked block with no image
   */
}
.em-c-stacked-block--large .em-c-stacked-block__header {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 1em;
  margin-bottom: 1em;
}

/**
 * Stacked block title
 */
.em-c-stacked-block__title {
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #0c69b0;
  text-decoration: underline;
  transition: color 0.15s ease;
  transition: all 0.15s ease;
  /**
  * Theming
  * 1) Apply theme color when theme class is added to the
  *    `body` (i.e. `<body class="em-theme--blue">`) or if a
  *    modifier is added to the parent block
  */
  /**
    * Stacked block title within stacked block med
    */
  /**
    * Stacked block title within stacked block with no image
    */
}
.em-theme--blue .em-c-stacked-block__title, .em-c-stacked-block--theme-blue .em-c-stacked-block__title {
  color: #0c69b0; /* 1 */
}
.em-theme--blue .em-c-stacked-block__title:hover, .em-theme--blue .em-c-stacked-block__title:focus, .em-c-stacked-block--theme-blue .em-c-stacked-block__title:hover, .em-c-stacked-block--theme-blue .em-c-stacked-block__title:focus {
  color: #3a397b;
}
.em-theme--red .em-c-stacked-block__title, .em-c-stacked-block--theme-red .em-c-stacked-block__title {
  color: #0c69b0; /* 1 */
}
.em-theme--red .em-c-stacked-block__title:hover, .em-theme--red .em-c-stacked-block__title:focus, .em-c-stacked-block--theme-red .em-c-stacked-block__title:hover, .em-c-stacked-block--theme-red .em-c-stacked-block__title:focus {
  color: #111122;
}
.em-c-stacked-block__title:hover {
  color: #104e8f;
  color: #111122;
}
.em-c-stacked-block__title:focus {
  outline: 1px dotted #0c69b0;
}
.em-c-stacked-block--med .em-c-stacked-block__title {
  font-size: 1.5rem;
  font-family: "EMprintRegular", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-style: normal;
  text-decoration: none;
}
.em-c-stacked-block--large .em-c-stacked-block__title {
  font-size: 1.75rem;
  text-decoration: none;
}
@media all and (min-width: 50em) {
  .em-c-stacked-block--large .em-c-stacked-block__title {
    font-size: 2.2rem;
  }
}

/**
 * Stacked block image
 */
.em-c-stacked-block__img {
  border-radius: 4px;
  /**
  * Stacked block image within medium stacked block
  * 1) Adds border to the image
  */
}
.em-c-stacked-block--med .em-c-stacked-block__img {
  border: 1px solid #cccccd;
}

/**
 * Stacked block description
 */
.em-c-stacked-block__desc {
  margin-bottom: 0.5em;
  line-height: 1.5;
  /**
  * Stacked block description within stacked block med
  */
}
.em-c-stacked-block--med .em-c-stacked-block__desc {
  font-size: 0.875rem;
}

/**
 * Stacked block footer
 */
.em-c-stacked-block__footer {
  color: #666666;
  font-style: italic;
  font-size: 0.875rem;
}
.em-c-stacked-block__footer a {
  color: #0c69b0;
  text-decoration: underline;
  transition: color 0.15s ease;
  cursor: pointer;
  /**
  * Theming
  * 1) Apply theme color when theme class is added to the
  *    `body` (i.e. `<body class="em-theme--blue">`) or if a
  *    modifier is added to the parent block
  */
}
.em-theme--blue .em-c-stacked-block__footer a, .em-c-stacked-block--theme-blue .em-c-stacked-block__footer a {
  color: #0c69b0; /* 1 */
}
.em-theme--blue .em-c-stacked-block__footer a:hover, .em-theme--blue .em-c-stacked-block__footer a:focus, .em-c-stacked-block--theme-blue .em-c-stacked-block__footer a:hover, .em-c-stacked-block--theme-blue .em-c-stacked-block__footer a:focus {
  color: #3a397b;
}
.em-theme--red .em-c-stacked-block__footer a, .em-c-stacked-block--theme-red .em-c-stacked-block__footer a {
  color: #0c69b0; /* 1 */
}
.em-theme--red .em-c-stacked-block__footer a:hover, .em-theme--red .em-c-stacked-block__footer a:focus, .em-c-stacked-block--theme-red .em-c-stacked-block__footer a:hover, .em-c-stacked-block--theme-red .em-c-stacked-block__footer a:focus {
  color: #111122;
}
.em-c-stacked-block__footer a:hover {
  color: #104e8f;
}
.em-c-stacked-block__footer a:focus {
  outline: 1px dotted #0c69b0;
}

/**
 * Stacked block footer
 */
.em-c-stacked-block__link {
  display: block;
  margin-bottom: 0.5rem;
}

.em-c-stacked-block:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 2px;
}

/*------------------------------------*\
    #WELL
\*------------------------------------*/
/**
 * 1) A well is an excerpt surrounded by a border.
 */
.em-c-well {
  padding: 1em;
  border: 1px solid #cccccd;
  margin-bottom: 2rem;
  max-width: 84em;
  border-radius: 8px;
}

.em-c-well:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 2px;
}

/*------------------------------------*\
    #MEDIA BLOCK
\*------------------------------------*/
/**
 * 1) A media block features a block of media(i.e. image) and text
 * 2) Display flex is added to put contents within the media block side by side
 */
.em-c-media-block {
  display: flex; /* 2 */
  max-width: 65rem;
}

/**
 * Media Container
 * 1) This is the container that holds the image (or media)
 */
.em-c-media-block__media {
  width: 35%;
  max-width: 11rem;
  margin-right: 1rem;
  /**
  * Media Container in a small variation
  */
  /**
  * Media Container in a tiny variation
  */
  /**
  * Media block within banded card
  */
}
.em-c-media-block--small .em-c-media-block__media {
  max-width: 7rem;
}
.em-c-media-block--tiny .em-c-media-block__media {
  max-width: 1rem;
  width: 1rem;
}
.em-c-card--banded .em-c-media-block__media {
  position: relative;
}

/**
 * Media block image
 * 1) Max height added to work in IE 10 and Edge
 */
.em-c-media-block__img {
  max-height: 11rem;
  border-radius: 4px;
  /**
  * Media block image within dark card
  */
  /**
  * Media Image in a small variation
  */
  /**
  * Media Image in a tiny variation
  */
}
.em-c-card--banded .em-c-media-block__img {
  display: block;
  border-radius: 0;
  margin-top: 1em;
  position: absolute;
  margin-top: -0.5em;
}
.em-c-media-block--small .em-c-media-block__img {
  max-height: 7rem;
}
.em-c-media-block--tiny .em-c-media-block__img {
  max-height: 1rem;
}

/**
 * Media Block Body
 * 1) This is the body of the media block
 * 2) Display flex allows the body of the media block to either display vertically or horizontally
 *    Flex direction column stacks content vertically. Justify content center centers content.
 * 3) Flex 1 is added to take up remaining space that isn't taken up by em-c-media-block__media
 */
.em-c-media-block__body {
  display: flex; /* 2 */
  flex-direction: column; /* 2 */
  justify-content: center; /* 2 */
  flex: 1; /* 3 */
}

/**
 * Media block kicker
 */
.em-c-media-block__kicker {
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 0 0 0.5rem;
  font-size: 0.75rem;
  color: #808080;
}

/**
 * Media Block Headline
 * 1) This is the headline of the media block
 */
.em-c-media-block__headline {
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
  margin-bottom: 0.2em;
  /**
  * Headline in a small variation
  */
  /**
  * Headline in a tiny variation
  */
  /**
  * Headline in a linked media block
  */
}
.em-c-media-block--small .em-c-media-block__headline {
  font-size: 1.25rem;
}
.em-c-media-block--tiny .em-c-media-block__headline {
  font-family: "EMprintRegular", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 1rem;
}
.em-c-table .em-c-media-block--tiny .em-c-media-block__headline {
  white-space: nowrap;
}
.em-c-media-block--link .em-c-media-block__headline {
  font-size: 1.25rem;
}

/**
 * Media Block Excerpt
 * 1) Appears below the
 */
.em-c-media-block__desc {
  font-size: 0.875rem;
}

/**
 * Media Block Link
 */
.em-c-media-block__link {
  color: #0c69b0;
  text-decoration: underline;
  transition: color 0.15s ease;
}
.em-c-media-block__link:hover {
  color: #104e8f;
}
.em-c-media-block__link:focus {
  outline: 1px dotted #0c69b0;
}
.em-c-media-block__link:active {
  background: none;
}

/**
  * Media Block Url
  * 1) URL path to the link
  */
.em-c-media-block__url {
  color: #bbbbbd;
  font-size: 0.75rem;
  font-family: "EMprintRegularItalic", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.em-c-media-block:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 2px;
}

/*------------------------------------*\
    #INSET BLOCK
\*------------------------------------*/
/**
 * 1) An inset block contains an image with text content that overlays the image when the viewport is large enough
 */
.em-c-inset-block {
  position: relative;
  max-width: 84rem;
}

/**
 * Inset Block Image
 */
.em-c-inset-block__image {
  display: block;
  width: 100%;
}

/**
 * Inset Block Body
 * 1) Consists of the block's text/html content
 * 2) Overlays the body content over carousel block
 * 3) Sets the height of body to height of carousel block, and bottom aligns text
 * 4) Sets width of the body to the block width
 * 5) Adds semi-transparent background over image to overlay it
 */
.em-c-inset-block__body {
  display: block;
  margin: 1em 0;
  /**
  * Medium/Large screens
  * 1) When enough screen real estate becomes available, the body sits on top of the image
  */
  /**
     * Larger screens
     */
}
@media all and (min-width: 40em) {
  .em-c-inset-block__body {
    display: flex; /* 2 */
    flex-direction: column; /* 2 */
    justify-content: flex-end; /* 4 */
    position: absolute; /* 2 */
    bottom: 0; /* 2 */
    height: 100%; /* 2 */
    width: 100%; /* 3 */
    padding: 1em;
    margin: 0;
    color: #fff;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 17%, rgba(0, 0, 0, 0.18) 36%, rgba(0, 0, 0, 0.78) 100%);
    /**
    * Tinted Inset Card Body
    */
    /**
    * Bare Inset Card Body
    */
  }
  .em-c-inset-block--tinted .em-c-inset-block__body {
    background: rgba(17, 17, 34, 0.5);
    /* 1 */
  }
  .em-c-inset-block--bare .em-c-inset-block__body {
    background: none;
  }
}
@media all and (min-width: 50em) {
  .em-c-inset-block__body {
    padding: 2rem;
  }
}

/**
 * Inset block description
 * 1) Caps text content to a certain width
 */
.em-c-inset-block__desc {
  max-width: 40rem; /* 1 */
}
@media all and (min-width: 40em) {
  .em-c-inset-block__desc {
    color: #fff;
  }
}

.em-c-inset-block:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 2px;
}

/*------------------------------------*\
    #CARD
\*------------------------------------*/
/**
 * 1) A card is a block of media followed by a body of text in a vertical format
 */
.em-c-card {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  position: relative;
  display: flex;
  padding: 1.5rem 1rem 1rem 1rem;
  max-width: 26rem;
  min-height: fit-content;
  border: 1px solid #cccccd;
  border-radius: 8px;
  background-color: #fff;
  /**
  * Card within tooltip
  * 1) Left align text, remove the border, and add negative margin to card to be flush against tooltip
  */
  /* Standard card types */
}
.em-c-card:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 4px;
}
.em-c-tooltip .em-c-card {
  text-align: left;
  border: 0;
  margin: -0.75rem -1rem;
}
.em-c-card--bordered {
  border: 1px solid #808080;
}
.em-c-card--raised {
  box-shadow: 0px 1px 4px #808080;
}
.em-c-card--flat-lightgray {
  background-color: #eeeeee;
  border-color: #eeeeee;
}
.em-c-card--flat-lightblue {
  background-color: #e1efff;
  border-color: #e1efff;
}
.em-c-card--flat-lavender {
  background-color: #ebe1f2;
  border-color: #ebe1f2;
}

/*------------------------------------*\
    #MEDIA CARD
\*------------------------------------*/
.em-c-card--img-fill .em-c-stacked-block__img {
  margin: -1.5em -1em 0.5em;
  max-width: -webkit-fill-available;
  border-radius: 8px 8px 0px 0px;
}

/* compact card */
.em-c-card--compact {
  padding: 0;
}
.em-c-card--compact .em-c-media-block__img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 8px 0 0 8px;
  max-height: fit-content;
}
.em-c-card--compact .em-c-media-block__body {
  padding: 1.5rem 0.75rem 0.5rem;
}
.em-c-card--compact .em-c-card__action-icon {
  top: 1rem;
  right: 1rem;
}

/**
 * Card Image
 */
.em-c-card__img {
  display: block;
  width: 100%;
}

/**
 * Card Body
 */
.em-c-card__body {
  flex: 1 0 auto;
}

a.em-c-card__body {
  text-decoration: none;
  color: #111122;
}

/**
 * Card flag
 */
.em-c-card__flag {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  margin: -1em -0.5em;
  padding: 0.5rem;
  font-size: 0.75rem;
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
  line-height: 1;
  color: #343434;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: #b12a0b;
  color: #fff;
  background: #d82424;
  background-image: linear-gradient(45deg, #ad1723 0%, #d82424 100%);
  border-radius: 4px;
  /**
     * Theming
     * 1) Apply theme color when theme class is added to the
     *    `body` (i.e. `<body class="em-theme--blue">`) or if a
     *    modifier is added to the parent block
     */
}
.em-c-card__flag:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 4px;
}
.em-theme--blue .em-c-card__flag, .em-c-card--theme-blue .em-c-card__flag {
  background: #3a397b;
  background-image: linear-gradient(45deg, #3a397b 0%, #3190d9 100%);
}
.em-theme--red .em-c-card__flag, .em-c-card--theme-red .em-c-card__flag {
  background: #d82424;
  background-image: linear-gradient(45deg, #ad1723 0%, #d82424 100%);
}

/**
 * Card Title
 */
.em-c-card__title {
  font-size: 1.5rem;
  margin: 0;
}

/**
 * Card Kicker
 */
.em-c-card__kicker {
  font-family: "EMprintLight", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 1;
  font-size: 0.75rem;
  margin: 0 0 0.5rem;
}

/**
 * Card Footer
 */
.em-c-card__footer {
  margin: 0;
  padding: 0;
  font-size: 0.875rem;
  max-height: 50vh;
  transition: all 0.25s ease-out;
  /**
  * Card Footer within banded card
  */
  /* Card footer with bottom actions */
}
.em-c-card--banded .em-c-card__footer {
  border-top: 0;
  padding: 0;
  margin: 0;
  background: none;
}
.em-c-card--bottom-actions .em-c-card__footer {
  display: flex;
  align-self: flex-end;
  transition: none;
}

/**
* Card Actions
* 1) Card actions is a container for links and buttons within a card.
*/
.em-c-card__actions {
  margin-top: 1rem;
}

/**
 * Card icon
 */
.em-c-card__icon {
  margin: 0 0.5rem 0.4rem auto;
  position: relative;
  top: 5px;
}

.em-c-card__action-icon, .em-c-picture-card .em-c-card__action-icon {
  position: absolute;
  right: 1.5rem;
  cursor: pointer;
  transition: all 0.15s ease-out;
  display: flex;
}

/* Card action item */
.em-c-card__action-item {
  position: absolute;
  right: 1.5rem;
  cursor: pointer;
}

/* Card icon and action focus */
.em-c-card__action-icon:focus-visible,
.em-c-card__action-item a:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 4px;
}

/*------------------------------------*\
    #SOLID CARD
\*------------------------------------*/
/**
 * 1) Solid card pattern
 */
.em-c-solid-card {
  flex: 1 0 auto;
  max-width: 34rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #111122;
  background: #f5f5f5;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  /**
     * Theming
     * 1) Apply theme color when theme class is added to the
     *    `body` (i.e. `<body class="em-theme--blue">`) or if a
     *    modifier is added to the parent block
     */
}
.em-c-solid-card--blue {
  background: #0c69b0;
  color: #fff;
}
.em-c-solid-card--red {
  background: #ad1723;
  color: #fff;
}
.em-c-solid-card--gray {
  background: #f5f5f5;
}

/**
 * 1) Solid card body
 * 2) Can be a link
 */
.em-c-solid-card__body {
  display: block;
  padding: 2rem;
  color: #111122;
  background: #f5f5f5;
  border-radius: 8px 8px 0 0;
  /**
  * Solid card color variations
  * 1) Can be red, blue, or gray
  */
  /**
  * Solid card body within compact solid card
  */
}
.em-c-solid-card--red .em-c-solid-card__body, .em-c-solid-card--theme-red .em-c-solid-card__body {
  background: #d82424;
  color: #fff;
}
.em-c-solid-card--blue .em-c-solid-card__body, .em-c-solid-card--theme-blue .em-c-solid-card__body {
  background: #0c69b0;
  color: #fff;
}
.em-c-solid-card--gray .em-c-solid-card__body, .em-c-solid-card--theme-gray .em-c-solid-card__body {
  background: #f5f5f5;
  color: #111122;
}
.em-c-solid-card--compact .em-c-solid-card__body {
  display: flex;
  padding: 1rem;
}

/**
 * Solid card body can be a link
 * 1) Hover/focus states for color variations
 */
a.em-c-solid-card__body {
  text-decoration: none;
}
a.em-c-solid-card__body:hover, a.em-c-solid-card__body:focus {
  color: #fff;
  background: #111122;
}
.em-c-solid-card--red a.em-c-solid-card__body:hover, .em-c-solid-card--red a.em-c-solid-card__body:focus {
  background: #111122;
}
.em-c-solid-card--blue a.em-c-solid-card__body:hover, .em-c-solid-card--blue a.em-c-solid-card__body:focus {
  background: #111122;
}

/**
 * Solid card icon
 * 1) Larger icon within the solid card to represent the content within the card
 */
.em-c-solid-card__icon {
  margin-bottom: 1rem;
  fill: #111122;
  color: #111122;
  /**
  * Small solid card icon
  * 1) Usually a chevron icon to show that the card is a link
  */
  /**
  * Compact solid card icon
  */
}
.em-c-solid-card--red .em-c-solid-card__icon, .em-c-solid-card--blue .em-c-solid-card__icon {
  fill: #fff;
  color: #fff;
}
.em-c-solid-card--gray .em-c-solid-card__icon {
  fill: #111122;
  color: #111122;
}
a.em-c-solid-card__body:hover .em-c-solid-card__icon, a.em-c-solid-card__body:focus .em-c-solid-card__icon {
  fill: #fff;
  color: #fff;
}
.em-c-solid-card__icon--small {
  position: relative;
  top: 8px;
  left: 2px;
  margin-left: auto;
  -webkit-transition: left 0.15s ease-out;
  transition: left 0.15s ease-out;
}
.em-c-solid-card__body:hover .em-c-solid-card__icon--small, .em-c-solid-card__body:focus .em-c-solid-card__icon--small {
  left: 0.5em;
}
.em-c-solid-card--compact .em-c-solid-card__icon {
  margin-bottom: 0;
}

/**
 * Solid card kicker
 */
.em-c-solid-card__kicker {
  font-family: "EMprintLight", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 1;
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
}

.em-c-solid-card__kicker:focus-visible {
  outline: none;
}

.em-c-solid-card__kicker:focus-visible span {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 4px;
}

/**
 * Solid card title
 */
.em-c-solid-card__title {
  display: flex;
  position: relative;
  font-size: 1.5rem;
  line-height: 1.2;
  margin-bottom: 0.4rem;
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
  /**
  * Compact solid card title
  */
  /**
  * Compact solid card title with no icon
  */
}
.em-c-solid-card--compact .em-c-solid-card__title {
  margin-bottom: 0;
}
.em-c-solid-card--compact.em-c-solid-card--with-icon .em-c-solid-card__title {
  margin-left: 1rem;
}

/**
 * 1) Solid card pattern
 */
.em-c-solid-card__footer {
  padding: 2rem;
  flex: 1 0 auto;
  background: #fff;
  border-radius: 0 0 8px 8px;
  /**
  * Compact solid card footer
  */
  /**
  * Compact solid card footer with no icon
  */
}
.em-c-solid-card--blue .em-c-solid-card__footer, .em-c-solid-card--red .em-c-solid-card__footer {
  background: rgba(0, 0, 0, 0.2);
}
.em-c-solid-card--compact .em-c-solid-card__footer {
  padding: 1rem;
}
.em-c-solid-card--compact.em-c-solid-card--with-icon .em-c-solid-card__footer {
  padding: 1rem 3.5rem;
}

/*------------------------------------*\
    #BADGE CARD
\*------------------------------------*/
.em-c-badge-card {
  color: #111122;
  background: #f5f5f5;
  max-width: 34rem;
}

.em-c-badge-card__body {
  padding: 2rem 0.5rem;
}

/*------------------------------------*\
    #ICON CARD
\*------------------------------------*/
.em-c-icon--card {
  padding: 2.5rem 1rem;
  min-width: 18rem;
  width: 100%;
}
@media all and (min-width: 40em) {
  .em-c-icon--card {
    max-width: 22rem;
    min-width: auto;
  }
}
.em-c-icon--card .em-c-icon--card__body {
  color: #545459;
  text-align: center;
}
.em-c-icon--card .em-c-icon--card__body .em-c-icon--card__icon {
  height: 6rem;
  width: 6em;
}
.em-c-icon--card .em-c-icon--card__body .em-c-icon--card__label {
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
  margin-top: 1rem;
  font-size: 1.125rem;
}
.em-c-icon--card .em-c-icon--card__body .em-c-icon--card__desc {
  margin-top: 0.5rem;
  font-size: 0.875rem;
}

/**
 * Action card icon animation
 */
.em-c-card--action .em-c-card__icon {
  margin: 0 0.5rem 0.4rem auto;
  transition: margin 0.15s ease-out;
}
a.em-c-card.em-c-card--action:hover .em-c-card--action .em-c-card__icon, a.em-c-card.em-c-card--action:focus .em-c-card--action .em-c-card__icon {
  margin: 0 0.25rem 0.4rem auto;
}

.em-c-card.em-c-card--action .em-c-card__action-icon svg {
  transition: all 0.15s ease-out;
}

.em-c-card.em-c-card--action:hover .em-c-card__action-icon svg {
  transform: translateX(5px);
}

/*------------------------------------*\
    #EXPANDABLE CARD
\*------------------------------------*/
.em-c-card--expandable hr {
  margin: 1em 0 1em 0;
  padding: 0;
  background-color: #a5a5a5;
}
.em-c-card--expandable input[type=checkbox]:not(:checked) ~ .em-c-card__footer {
  max-height: 0;
  opacity: 0;
}
.em-c-card--expandable input[type=checkbox]:not(:checked) ~ .em-c-card__body .em-c-card__action-icon {
  transform: rotate(0);
}
.em-c-card--expandable input[type=checkbox]:checked ~ .em-c-card__body .em-c-card__action-icon {
  transform: rotate(180deg);
}
.em-c-card--expandable input[type=checkbox]:not(:checked) ~ .em-c-card__body .em-c-card__action-icon:hover {
  padding-top: 2px;
}
.em-c-card--expandable input[type=checkbox]:checked ~ .em-c-card__body .em-c-card__action-icon:hover {
  margin-top: -2px;
}

/*------------------------------------*\
    #CONTACT CARD
\*------------------------------------*/
.em-c-card__status-dot {
  height: 20px;
  width: 20px;
  border: 2px #fff solid;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 1.5rem;
}
.em-c-card__status-dot.em-c-card__status-dot--online {
  background-color: #00af53;
}
.em-c-card__status-dot.em-c-card__status-dot--busy {
  background-color: #d82424;
}
.em-c-card__status-dot.em-c-card__status-dot--away {
  background-color: #ffd700;
}
.em-c-card__status-dot.em-c-card__status-dot--offline {
  background-color: #cccccd;
}

.em-c-card .em-c-link-list__item a:focus-visible {
  outline: none;
}

.em-c-card .em-c-link-list__item a:focus-visible,
.em-c-stacked-block a:focus-visible,
.em-c-card__action-icon:focus-visible,
.em-c-solid-card:focus-visible,
.em-c-solid-card a:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 4px;
  z-index: 1;
}

.em-c-card.em-c-card--expandable .em-c-link-list__text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.em-c-card__body .em-c-media-block__headline {
  padding-right: 1.5rem;
  word-break: break-word;
  -ms-word-break: break-word;
}

.em-c-card--contact .em-c-card__body .em-c-media-block__body {
  max-width: 60%;
}

/*------------------------------------*\
    #PICTURE CARD
\*------------------------------------*/
.em-c-picture-card-a {
  text-decoration: none;
}
.em-c-picture-card-a:focus > .em-c-picture-card {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 2px;
}

/**
 * 1) A card is a block of media followed by a body of text in a vertical format
 * 2) Fixes IE bug that creates too much bottom spacing when in a grid
 */
.em-c-picture-card {
  display: block;
  position: relative;
  max-width: 34rem;
  flex-shrink: 0; /* 2 */
  border-radius: 8px;
  /**
     * Picture cards that appear in picture card list
     * 1) Fixes IE bug that creates too much bottom spacing when in a grid
     */
}
.em-c-picture-card-list .em-c-picture-card {
  flex: 1 0 auto;
  flex-shrink: 0; /* 1 */
}
.em-c-picture-card .em-c-card__action-icon {
  bottom: 0.6rem;
}

.em-c-card.em-c-picture-card {
  padding: 0;
}
.em-c-card.em-c-picture-card .em-c-picture-card__desc {
  font-size: 0.875rem;
  margin-top: 1rem;
}

/**
 * Picture card modifier that uses background image instead of
 * 1) This allows various sized images
 */
.em-c-picture-card--background {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

/**
 * Picture Card Header
 * Default state before image loads
 */
.em-c-picture-card__header {
  background-color: #f5f5f5;
  box-shadow: inset 0 0 0 1px #e5e5e5;
  height: 100%;
  width: 100%;
  border-radius: 8px;
}

/**
 * Card Image
 */
.em-c-picture-card__img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 8px;
}

/**
 * Picture Card Body
 * 1) Background set to ghosted black background as overlay. Height set to 100% so overlay covers entire container
 * 2) Display flex, flex-direction column, justify content flex-end added to set contents to bottom of card
 * 3) Position absolute used to overlay the card
 */
.em-c-picture-card__body {
  display: flex; /* 2 */
  flex-direction: column; /* 2 */
  justify-content: flex-end; /* 2 */
  position: absolute; /* 3 */
  bottom: 0;
  padding: 2rem 1rem;
  height: 100%; /* 1 */
  width: 100%;
  color: #fff;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 17%, rgba(0, 0, 0, 0.18) 36%, rgba(0, 0, 0, 0.78) 100%);
  border-radius: 8px;
  /**
  * Card body inside a background image picture card
  */
  /**
  * Tinted Picture Card Body
  */
  /**
  * Bare Picture Card Body
  */
  /**
  * Bare Picture Card Reversed
  * 1) Dark text on a light background
  */
}
.em-c-picture-card--background .em-c-picture-card__body {
  position: static; /* 1 */
  display: block;
  padding-top: 10rem;
  border-radius: 8px;
}
.em-c-picture-card--tinted .em-c-picture-card__body {
  background: rgba(17, 17, 34, 0.5);
  /* 1 */
}
.em-c-picture-card--bare .em-c-picture-card__body {
  background: none;
  transition: background 0.15s ease-out;
}
.em-c-picture-card--bare .em-c-picture-card__body:hover {
  background: rgba(17, 17, 34, 0.5);
  transition: background 0.15s ease-out;
}
.em-c-picture-card--reversed .em-c-picture-card__body {
  color: #111122;
  background-color: #e5e5e5;
  background: none;
  transition: background 0.15s ease-out;
}

/**
 * Picture Card Title
 */
.em-c-picture-card__title {
  font-size: 1.25rem;
  font-weight: 600;
  font-family: EMprintSemibold, HelveticaNeue, Helvetica, Arial, sans-serif;
  margin: 0;
  display: flex;
  align-items: flex-end;
}

/**
 * Picture Card Kicker
 */
.em-c-picture-card__kicker {
  font-family: "EMprintLight", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 1;
  font-size: 0.75rem;
  margin: 0 0 0.5rem;
}

/**
 * Picture Card Footer
 */
.em-c-picture-card__footer {
  background: #f5f5f5;
  margin: 1rem -1rem -1rem -1rem;
  padding: 1rem 1.5rem 1rem 1.5rem;
  border-top: 1px solid #e5e5e5;
}

/**
* Picture Card Actions
* 1) Card actions is a container for links and buttons within a card.
*/
.em-c-picture-card__actions {
  margin-top: 1rem;
}

/**
 * Picture Card icon
 */
.em-c-picture-card__icon {
  margin: 0 0.5rem 0.4rem auto;
  transition: margin 0.15s ease-out;
}
a.em-c-picture-card:hover .em-c-picture-card__icon, a.em-c-picture-card:focus .em-c-picture-card__icon {
  margin: 0 0.25rem 0.4rem auto;
}

.em-c-card.em-c-picture-card .em-c-card__action-icon svg {
  transition: all 0.15s ease-out;
}

.em-c-card.em-c-picture-card:hover .em-c-card__action-icon svg {
  transform: translateX(5px);
}

/*------------------------------------*\
    #TILE
\*------------------------------------*/
/**
 * 1) A block of stylized content containing a key and a value
 * 2) The root of a tile is typically <a> tag
 */
.em-c-tile {
  display: flex;
  align-items: center;
  padding: 1.5rem;
  flex: 1 0 auto;
  max-width: 40rem;
  transition: all 0.15s ease;
  background: #f5f5f5;
  color: #111122;
  border-radius: 8px;
  text-decoration: none;
}
.em-c-tile:hover, .em-c-tile:focus {
  background: #111122;
  color: #fff;
}
.em-c-tile--blue {
  background: #0c69b0;
  color: #fff;
}
.em-c-tile--blue:hover, .em-c-tile--blue:focus {
  background: #111122;
}
.em-c-tile--red {
  background: #d82424;
  color: #fff;
}
.em-c-tile--red:hover, .em-c-tile--red:focus {
  background: #ad1723;
}
.em-c-tile--gray {
  color: #111122;
  background: #f5f5f5;
}
.em-c-tile--gray:hover, .em-c-tile--gray:focus {
  color: #fff;
  background: #111122;
}
.em-c-tile--black {
  color: #fff;
  background: #111122;
}
.em-c-tile--black:hover, .em-c-tile--black:focus {
  color: #111122;
  background: #f5f5f5;
}

/**
 * Tile headline
 * 1) Headline of the tile
 */
.em-c-tile__headline {
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1.5rem;
  width: 3rem;
}

.em-c-tile__icon {
  fill: #111122;
  color: #111122;
}
.em-c-tile:hover .em-c-tile__icon, .em-c-tile:focus .em-c-tile__icon {
  color: #fff;
  fill: #fff;
}
.em-c-tile--blue .em-c-tile__icon {
  color: #fff;
  fill: #fff;
}
.em-c-tile--red .em-c-tile__icon {
  color: #fff;
  fill: #fff;
}
.em-c-tile--gray .em-c-tile__icon {
  color: #111122;
  fill: #111122;
}
.em-c-tile--gray:hover .em-c-tile__icon, .em-c-tile--gray:active .em-c-tile__icon {
  color: #fff;
  fill: #fff;
}

.em-c-tile:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 2px;
}

/*------------------------------------*\
    #PROMO BLOCK
\*------------------------------------*/
/**
 * 1) A promo block is a visually bold container component that contains a headline, description, and action(s)
 */
.em-c-promo-block {
  background: #111122;
  color: #fff;
  padding: 3rem 2rem;
  margin-bottom: 2rem;
}

/**
 * Promo block actions section
 * 1) Adds more flexibility to what components to include within this (i.e. add single button or button group)
 * 2) Adds margin top to section instead of component within this section
 */
.em-c-promo-block__actions {
  margin-top: 3rem; /* 2 */
}

.em-c-promo-block:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 2px;
}

/**
 * Navigation
 */
/*------------------------------------*\
    #PRIMARY NAV
\*------------------------------------*/
/**
 * 1) Primary navigation of the site, usually included within the header
 * 2) Display flex allows primary navigation to go from column to row. Align items center centers items.
 */
.em-c-primary-nav {
  margin: 1rem -1rem;
  /**
    * 1) On large screens the nav is right-aligned and positioned 1rem to the right to align the last nav item
    *    with the rest of the content.
    */
}
@media all and (min-width: 47em) {
  .em-c-primary-nav {
    display: flex; /* 2 */
    align-items: center; /* 2 */
    margin: 0;
  }
}
@media all and (min-width: 62em) {
  .em-c-primary-nav {
    position: relative;
    right: -1rem; /* 1 */
    /**
      * Header Body that contains a large number of primary-nav items
      * 1) Stack primary nav on top of site title
      */
  }
  .em-c-header--large-nav .em-c-primary-nav {
    right: 0;
  }
}

/**
 * Primary navigation list
 * 1) Negative margin is to offset the link padding so text alignment is maintained
 * 2) Offset to the top so header title and nav links align
 */
.em-c-primary-nav__list {
  position: relative;
  /**
  * Primary navigation sublist when activated
  */
}
.em-c-primary-nav__list.em-is-active {
  display: block;
  /**
  * Primary navigation sublist within both the activated nav item and header
  */
}
.em-c-header .em-c-primary-nav__list.em-is-active {
  background: #ad1723;
}
@media all and (min-width: 47em) {
  .em-c-primary-nav__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 1rem;
    margin-left: -1rem; /* 1 */
    position: relative;
    top: 0.9rem;
    /**
      * Primary nav list within condensed header
      */
  }
  .em-c-header--condensed .em-c-primary-nav__list {
    margin-top: 0;
    top: 0;
  }
}

/**
 * Primary navigation list item
 * 1) Position relative added to put pseudo element :after beside link
 */
.em-c-primary-nav__item {
  position: relative;
  margin-right: 1rem;
  /**
  * Primary navigation item that is aligned right
  * 1) Margin left auto added to shift this item and items after to right of parent flexbox container
   * 2) Zero out margin right so item sits flush to right edge
  */
  /**
  * Primary navigation item that is aligned right
  * 1) Margin left auto added to shift this item and items after to right of parent container
   * 2) Zero out margin right so item sits flush to right edge
  */
}
.em-c-primary-nav__item:last-child {
  margin-right: 0;
}
.em-c-primary-nav__item.em-is-aligned-right {
  margin-left: auto; /* 1 */
  margin-right: 0; /* 2 */
}
.em-c-primary-nav__item--search-trigger {
  display: none;
}
@media all and (min-width: 47em) {
  .em-c-primary-nav__item--search-trigger {
    display: block;
    margin-left: -1rem;
  }
}
.em-c-primary-nav__item--search-trigger svg:last-of-type {
  height: 1.2rem;
  width: 1.2rem;
}

/**
 * Primary navigation Link
 * 1) These styles apply to the top-level links in the primary nav
 * 2) A faux border-bottom is accomplished with a box-shadow
 */
.em-c-primary-nav__link {
  display: flex;
  align-items: center;
  padding: 0.8rem 1rem;
  font-family: "EMprintRegular", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-style: normal;
  transition: all 0.15s ease;
  text-decoration: none;
  /**
  * Primary navigation link if it is the current page
  */
  /**
     * 1) Adjusts nav link bottom padding to align with header title
     */
  /**
    * Primary navigation links inside of the header
    */
}
.em-c-primary-nav__link:hover, .em-c-primary-nav__link:focus {
  opacity: 0.6;
}
.em-c-primary-nav__link.em-is-current {
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
}
@media all and (min-width: 47em) {
  .em-c-primary-nav__link {
    padding: 1rem; /* 1 */
    /**
    	 * Primary navigation link that is actively displaying a dropdown
    	 */
    /**
    	 * Primary nav link within condensed header
    	 */
  }
  .em-c-primary-nav__link.em-is-active {
    background: rgba(0, 0, 0, 0.2);
  }
  .em-c-primary-nav__link.em-is-active:hover, .em-c-primary-nav__link.em-is-active:focus {
    opacity: 1;
  }
  .em-c-header--condensed .em-c-primary-nav__link {
    padding: 0.5rem 1rem 0.4rem;
    font-size: 0.875rem;
  }
}
.em-c-header .em-c-primary-nav__link {
  color: #fff;
}

/**
 * Dropdown Icon
 * 1) Primary nav elements that have
 * 2) A faux border-bottom is accomplished with a box-shadow
 */
.em-c-primary-nav__icon {
  display: inline-block;
  margin-left: 0.5rem;
  fill: #fff;
  height: 0.7rem;
  width: 0.7rem;
  text-decoration: none;
  /**
  * Primary nav icon when primary nav link is active
   * 1) Rotates icon 180 deg
  */
}
.em-c-primary-nav__link.em-is-active .em-c-primary-nav__icon {
  transform: rotate(180deg); /* 1 */
}

/**
 * Sublist
 * 1) The sublist is a dropdown menu
 */
.em-c-primary-nav__sublist {
  border-left: 1px solid #292938;
  padding-left: 1rem;
  margin-left: 1rem;
  display: none;
  min-width: 130%;
  /**
  * Active primary nav sublist
  */
  /**
    * Multicolumn Sublist
    */
}
@media all and (min-width: 47em) {
  .em-c-primary-nav__sublist {
    background: #fff;
    padding: 2rem 2rem 1.8rem;
    border-left: 0;
    margin: 0;
    position: absolute;
    z-index: 6;
    /**
      * Nav sublist that appears within the last primary nav item
      */
    /**
      * Nav sublist that appears in a condensed header
      */
  }
  .em-c-primary-nav__item:last-child .em-c-primary-nav__sublist {
    right: 0;
  }
  .em-c-header--condensed .em-c-primary-nav__sublist {
    top: 100%;
  }
}
.em-c-primary-nav__sublist.em-is-active {
  display: block;
  white-space: nowrap;
}
@media all and (min-width: 47em) {
  .em-c-primary-nav__sublist.em-c-primary-nav__sublist--multicolumn ul {
    columns: 2;
  }
  .em-c-primary-nav__sublist.em-c-primary-nav__sublist--multicolumn ul .em-c-primary-nav__subitem {
    margin-right: 2rem;
    text-decoration: none;
  }
  .em-c-primary-nav__sublist.em-c-primary-nav__sublist--multicolumn .em-c-primary-nav__subitem--footer {
    margin-right: 2rem;
    text-decoration: none;
  }
}

/**
 * Mega Menu Item
 * 1) The mega menu is a dropdown that contains potentially many sub-links. It has
 */
.em-c-primary-nav__subitem-footer {
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #545459;
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
}
@media all and (min-width: 47em) {
  .em-c-primary-nav__subitem-footer {
    border-top: 1px solid #cccccd;
    padding-top: 1rem;
    margin-bottom: 0;
  }
}
.em-c-primary-nav__subitem-footer .em-c-primary-nav__sublink {
  display: inline;
}
.em-c-primary-nav__subitem-footer .em-c-primary-nav__sublink:hover, .em-c-primary-nav__subitem-footer .em-c-primary-nav__sublink:focus {
  text-decoration: none;
  border-bottom: 1px solid #545459;
  padding-bottom: 0.3rem;
  transition: none;
}
.em-c-primary-nav__subitem-footer .em-c-primary-nav__sublink .em-c-primary-nav__icon {
  display: none;
}
@media all and (min-width: 47em) {
  .em-c-primary-nav__subitem-footer .em-c-primary-nav__sublink .em-c-primary-nav__icon {
    margin-left: 0.2rem;
    display: inline-block;
  }
}

/**
 * Sublist link
 * 1) Links in a dropdown menu
 */
.em-c-primary-nav__sublink {
  display: block;
  padding: 0.5rem 0;
  color: #fff;
  text-decoration: none;
  font-size: 0.875rem;
  transition: all 0.15s ease;
  /**
  * Active sublist link
  */
}
@media all and (min-width: 47em) {
  .em-c-primary-nav__sublink {
    color: #545459;
  }
}
.em-c-primary-nav__sublink.em-is-active {
  display: block;
}
.em-c-primary-nav__sublink:hover, .em-c-primary-nav__sublink:focus {
  text-decoration: underline;
}

/*------------------------------------*\
    #MULTICOLUMN NAV
\*------------------------------------*/
/**
 * 1) Represents navigation that consists of multiple columns on larger screens and one column at smaller screens
 * 2) Display flex added to give ability to flex from a column layout on small screens to row layout on larger screens
 *    Flex wrap allows navigation items to wrap to next line to create multicolumn layout.
 */
.em-c-multicolumn-nav {
  display: flex; /* 2 */
  flex-direction: column; /* 2 */
  margin-bottom: 1em;
  /**
  * Multicolumn navigation within banded footer
  * 1) Adds right margin on larger screens
  */
}
.em-c-multicolumn-nav--horizontal {
  flex-direction: row;
  flex-wrap: wrap;
}
@media all and (min-width: 44em) {
  .em-c-footer--banded .em-c-multicolumn-nav {
    margin-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .em-c-multicolumn-nav {
    flex-direction: row; /* 2 */
    flex-wrap: wrap; /* 2 */
    min-width: 25%;
  }
}
@media all and (min-width: 44em) {
  .em-c-multicolumn-nav {
    margin-bottom: 0;
  }
}

/**
 * Multicolumn navigation item
 * 1) Sets items to 2 columns
 */
.em-c-multicolumn-nav__item {
  height: auto;
  margin-bottom: 1rem;
  /**
  * Multicolumn nav item within 3 column multicolumn nav
  */
  /**
  * Multicolumn nav item within 3 column multicolumn nav
  */
}
@media all and (min-width: 30em) {
  .em-c-multicolumn-nav__item {
    width: 50%; /* 1 */
  }
}
@media all and (min-width: 44em) {
  .em-c-multicolumn-nav--three .em-c-multicolumn-nav__item {
    width: 33%;
  }
}
.em-c-multicolumn-nav--horizontal .em-c-multicolumn-nav__item {
  width: inherit;
  margin-right: 1rem;
  /**
  * Multicolumn nav item within banded footer
  */
}
.em-c-multicolumn-nav--horizontal .em-c-multicolumn-nav__item:last-child {
  margin-right: 0;
}
.em-c-footer--banded .em-c-multicolumn-nav--horizontal .em-c-multicolumn-nav__item {
  margin-right: 1.5rem;
}
.em-c-footer--banded .em-c-multicolumn-nav--horizontal .em-c-multicolumn-nav__item:last-child {
  margin-right: 0;
}

/**
 * Multicolumn navigation link
 */
.em-c-multicolumn-nav__link {
  font-size: 0.875rem;
  color: #343434;
  display: inline-block;
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
  transition: color 0.15s ease;
  text-decoration: none;
  /**
  * Multicolumn navigation link within banded footer
  */
}
.em-c-multicolumn-nav__item:last-child .em-c-multicolumn-nav__link:after {
  display: none;
}
.em-c-multicolumn-nav__link:hover {
  color: #104e8f;
}
.em-c-multicolumn-nav__link:hover, .em-c-multicolumn-nav__link:focus {
  color: #0c69b0;
  color: #111122;
}
.em-c-multicolumn-nav__link:focus {
  outline: 1px dotted #0c69b0;
}
.em-c-footer--banded .em-c-multicolumn-nav__link {
  position: relative;
  color: #cccccd;
  text-decoration: underline;
  font-family: "EMprintRegular", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-style: normal;
}
.em-c-footer--banded .em-c-multicolumn-nav__link:after {
  content: "";
  display: block;
  position: absolute; /* 1 */
  height: 3px;
  width: 3px;
  top: 7px;
  right: -13px;
  background: #cccccd;
}

/*------------------------------------*\
	#PAGINATION
\*------------------------------------*/
/**
 * 1) An ordered list to navigate through the site
 * 2) Display flex added to display pagination items in a row
 */
.em-c-pagination {
  display: flex;
}

/**
 * Pagination list item
 */
.em-c-pagination__item {
  margin: 0 2px;
}

/**
 * Pagination link
 */
.em-c-pagination__link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.625rem;
  height: 1.625rem;
  padding: 0.3rem;
  border: 1px solid #e5e5e5;
  font-size: 0.75rem;
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
  line-height: 1;
  background: #fff;
  color: #0c69b0;
  transition: border-color 0.15s ease;
  padding: 0.3335em;
}
.em-c-pagination__link:hover, .em-c-pagination__link:focus {
  border-color: #0c69b0;
}
.em-c-pagination__link.em-is-current {
  pointer-events: none;
  background: #111122;
  color: #fff;
  border-color: #111122;
}
.em-c-pagination__link.em-is-disabled {
  color: #e5e5e5;
  fill: #e5e5e5;
  border: inherit;
  pointer-events: none;
}

/*------------------------------------*\
	#PAGINATION FILTER
\*------------------------------------*/
/**
 * 1) A way to filter data using a select menu
 * 2) Display flex used to give option of listing contents within pagination filter in a row or column
 * 3) TODO: Are these styles still necessary? Should they be handled by the basic inline-dropdown styles?
 */
.em-c-pagination-filter {
  display: flex; /* 2 */
  align-items: center;
}

/**
 * Pagination filter description
 * 1) Margin left auto added to push description all the way to the right of parent container
 */
.em-c-pagination-filter__desc {
  margin-left: auto; /* 1 */
}

/*------------------------------------*\
	#PAGINATION CONTROLS
\*------------------------------------*/
/**
 * 1) Displays children in horizontal configuration and aligns them to the center
 */
.em-c-pagination-controls {
  display: flex; /* 1 */
  align-items: center; /* 1 */
  margin: 1em 0;
}
.em-c-carousel .em-c-pagination-controls {
  justify-content: flex-end;
}

/**
 * Pagination Controls Position
 * 1)
 */
.em-c-pagination-controls__position {
  padding: 0 1.5em;
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.em-c-pagination__item:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 2px;
}

/*------------------------------------*\
    #TOOLBAR
\*------------------------------------*/
/**
 * 1) A component that contains form controls, buttons, links, etc
 * 2) Display flex is used to place items within toolbar beside each other
 *    Align items center vertically centers items within container
 */
.em-c-toolbar {
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  padding: 0.8rem 0.4rem 0.4rem;
  /**
  * Toolbar within active collapsible toolbar
  */
  /**
  * Toolbar within table object header
  * 1) Remove border bottom when inside table object header
  */
  /**
  * Toolbar within table object footer
  * 1) Remove border top when inside table object footer
  */
  /**
  * Toolbar within solid card
  */
}
.em-c-collapsible-toolbar.em-is-active .em-c-toolbar {
  border: 0;
  padding-bottom: 0;
}
@media all and (min-width: 47em) {
  .em-c-collapsible-toolbar.em-is-active .em-c-toolbar {
    padding-bottom: 0.8rem;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
  }
}
.em-c-table-object__header .em-c-toolbar {
  border-bottom: 0;
}
.em-c-table-object__footer .em-c-toolbar {
  border-top: 0;
}
@media all and (min-width: 47em) {
  .em-c-toolbar {
    flex-direction: row;
    align-items: center; /* 2 */
    padding: 0.8rem 0.4rem;
  }
}
.em-c-solid-card .em-c-toolbar {
  flex-direction: column;
  padding: 0.8rem 2rem;
}
@media all and (min-width: 47em) {
  .em-c-solid-card .em-c-toolbar {
    flex-direction: column;
    align-items: flex-start;
  }
}

/**
 * Toolbar item
 */
.em-c-toolbar__item {
  margin: 0 0.8rem 0.4rem; /* 1 */
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
  /**
  * Toolbar item stretch
  * 1) Allows toolbar item to fill remaining space of toolbar
  */
  /**
  * Toolbar items within collapsible toolbar
  * 1) Aligns stacked items
  * 2) Gets rid of the bottom left margin once items move side by side
  */
  /**
  * Toolbar items within toolbar order variation
  * 1) Toolbar variation that allows the last item to become the first item on smaller screens
  * 2) Currently used with the Add/Edit buttons
  */
  /**
  * Toolbar item within solid card
  */
}
.em-c-toolbar__item--stretch {
  flex: 1 0 auto;
}
@media all and (min-width: 47em) {
  .em-c-toolbar__item {
    margin: 0 0.8rem; /* 1 */
    /**
     * Right-aligned toolbar item
    * 1) The toolbar uses `display: flex` to
     */
  }
  .em-c-toolbar__item.em-is-aligned-right {
    margin-left: auto;
  }
}
.em-c-collapsible-toolbar .em-c-toolbar__item {
  margin: 0 0.4rem 0.4rem 0.4rem; /* 1 */
  /**
  * Right aligned Toolbar items within collapsible toolbar
  * Add top margin when item is inside a collapsible toolbar
  */
  /**
  * Last toolbar item in collapsible toolbar
  * 1) Add margin bottom 0 on small screens so padding is the same on toolbar top and bottom
  */
}
.em-c-collapsible-toolbar .em-c-toolbar__item.em-is-aligned-right {
  margin-top: 1rem;
}
.em-c-collapsible-toolbar .em-c-toolbar__item:last-child {
  margin-bottom: 0;
}
@media all and (min-width: 47em) {
  .em-c-collapsible-toolbar .em-c-toolbar__item {
    margin: 0 0.8rem; /* 2 */
  }
}
@media all and (min-width: 47em) {
  .em-c-toolbar .em-c-toolbar__item {
    margin: 0 0.8rem; /* 2 */
  }
  .em-c-toolbar .em-c-toolbar__item.em-is-aligned-right {
    margin-top: 0;
    margin-left: auto;
  }
}
.em-c-toolbar--order .em-c-toolbar__item {
  /**
  * Toolbar items 1, 2, 3, 4, 5 within toolbar order
  * 1) Places these toolbar items after the item that has order: 0 (usually the last item on wide screens)
  */
  order: 1; /* 1 */
  /**
  * Last toolbar item
  * 1) Places the last toolbar item in the DOM first on smaller screens
  */
  /**
  * First toolbar-item within toolbar-order
  */
}
.em-c-toolbar--order .em-c-toolbar__item:last-child {
  order: 0; /* 1 */
  padding: 0 1.2rem 0;
  margin: 0 -0.4rem 0;
}
.em-c-toolbar--order .em-c-toolbar__item:first-child {
  margin: 0.8rem -0.2rem 0.4rem;
  padding: 0.8rem 0.8rem 0;
  border-top: 1px solid #e5e5e5;
  /**
  * When the first child is the aligned right item
  * 1) Used in the tags toolbar
  */
}
.em-c-toolbar--order .em-c-toolbar__item:first-child.em-is-aligned-right {
  border-top: 0;
  margin-top: -0.2rem;
  padding: 0 1rem;
}
@media all and (min-width: 47em) {
  .em-c-toolbar--order .em-c-toolbar__item {
    /**
    * Returns order to original DOM order
    */
    order: 0;
    /**
    * First toolbar item in DOM within toolbar order
    */
    /**
    * Places last child after all other elements (in order of DOM)
    */
  }
  .em-c-toolbar--order .em-c-toolbar__item:first-child {
    border-top: 0;
    padding: 0 0.8rem;
    margin: 0;
  }
  .em-c-toolbar--order .em-c-toolbar__item:last-child {
    order: 1;
    margin: 0 0.4rem;
    padding: 0;
    /**
    * Last aligned right toolbar item
    */
  }
  .em-c-toolbar--order .em-c-toolbar__item:last-child.em-is-aligned-right {
    margin-left: auto;
  }
}
.em-c-solid-card .em-c-toolbar__item {
  margin: 0;
  margin: 0 0 0.4rem;
}
.em-c-solid-card .em-c-toolbar__item:last-child {
  margin-bottom: 0;
}
@media all and (min-width: 47em) {
  .em-c-solid-card .em-c-toolbar__item.em-is-aligned-right {
    margin-left: 0;
  }
}

/* Data grid collapsible toolbar header */
.em-dc-toolbar-header {
  background-color: #f5f5f5;
  border: #cccccd 1px solid;
}

.em-dc-toolbar-buttons {
  margin-left: -2px !important;
}

.em-dc-header-title {
  font-family: EMprintSemibold, HelveticaNeue, Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: normal;
  padding-left: 6px;
  font-weight: 500;
  margin-right: 5px;
  margin-left: 6px;
  color: #545459;
  float: left;
  padding-top: 5px;
}

.em-dc-tbh-splitter {
  border-right: 1px #bbbbbd solid;
  height: 30px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 3px;
  float: left;
}

.em-dc-items-per-page {
  font-family: EMprintRegular, HelveticaNeue, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 16px;
  margin-right: 5px;
  color: #545459;
}

.em-dc-items-per-page-container {
  background: #fff !important;
}

.em-dc-items-per-page-select {
  color: #545459;
  height: 35px;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiAxNiI+DQogICAgPHRpdGxlPmljb24tYmx1ZTwvdGl0bGU+PHBhdGggZD0iTTMuNjIxLDVoOC43NThhLjY2LjY2LDAsMCwxLC41NDUsMS4wMzNsLTQuMzc5LDYuNGEuNjYuNjYsMCwwLDEtMS4wODksMGwtNC4zNzktNi40QS42Ni42NiwwLDAsMSwzLjYyMSw1WiIgZmlsbD0iIzVhNWE1YSIvPg0KPC9zdmc+), #fff;
  background-position: calc(100% - 0.7rem) 50%;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  margin-right: -10px;
}

/* Data grid toolbar footer */
.em-dc-footer {
  border: #cccccd 1px solid !important;
  border-top: none !important;
  margin-bottom: 6rem;
  display: flex;
}

.em-dc-footer_3_cols #itemsSelected,
.em-dc-footer_3_cols #commitButtons {
  flex-grow: 1;
}

.em-dc-footer_3_cols #pagination {
  flex-grow: 2;
}

.em-dc-items-per-page_allign {
  margin-left: 10px;
}

.em-dc-footer .em-c-btn-group {
  float: right;
}

/*------------------------------------*\
    #BREADCRUMBS
\*------------------------------------*/
/**
 * 1) This is an ordered list showing what level you are on in the site
 * 2) Set to display flex to list breadcrumbs items horizontally
 * 3) Set to flex-wrap wrap so breadcrumbs items wrap to next line when space is unavailable.
 * 4)
 */
.em-c-breadcrumbs {
  display: flex; /* 2 */
  flex-wrap: wrap; /* 3 */
  margin: 0 0 1rem -1rem; /* 4 */
  /**
  * Breadcrumbs in banded footer
  */
}
.em-c-footer--banded .em-c-breadcrumbs {
  margin-bottom: 1rem;
}
@media all and (min-width: 30em) {
  .em-c-footer--banded .em-c-breadcrumbs {
    margin-bottom: 0;
  }
}

/**
 * Breadcrumbs list item
 * 1) Set to position relative so :after is positioned relative to the breadcrumbs item
 */
.em-c-breadcrumbs__item {
  position: relative; /* 1 */
  margin-right: 0.5em;
  /**
  * Icon in between list item
  * 1) Set to position absolute so it is positioned relative to its parent container
  */
  /**
  * Last breadcrumbs list item
  */
}
.em-c-breadcrumbs__item span {
  text-decoration: none;
}
.em-c-breadcrumbs__item a:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 2px;
}
.em-c-breadcrumbs__item:after {
  content: "›";
  position: absolute; /* 1 */
  top: 0;
  right: -6px;
  color: #0c69b0;
}
.em-c-footer--banded .em-c-breadcrumbs__item:after {
  content: "»";
  position: absolute; /* 1 */
  top: 0;
  right: -6px;
  color: #cccccd;
}
.em-c-breadcrumbs__item:last-child {
  margin-right: 0;
}
.em-c-breadcrumbs__item:last-child:after {
  display: none;
}

/**
 * Breadcrumbs link
 */
.em-c-breadcrumbs__link {
  margin: 0 1rem;
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 0.875rem;
  color: #0c69b0;
  text-decoration: underline;
  transition: color 0.15s ease;
  /**
  * Theming
     * 1) Change theme color when theme class is added to the
     *    `body` (i.e. `<body class="em-theme--blue">`) or if a
     *    modifier is added to the block itself (i.e.
     *    `class="em-c-breadcrumbs em-c-breadcrumbs--theme-blue"`)
  */
  /**
  * Breadcrumbs link within banded footer
  */
}
.em-theme--blue .em-c-breadcrumbs__link, .em-c-breadcrumbs--theme-blue .em-c-breadcrumbs__link {
  color: #0c69b0; /* 1 */
}
.em-theme--blue .em-c-breadcrumbs__link:hover, .em-theme--blue .em-c-breadcrumbs__link:focus, .em-c-breadcrumbs--theme-blue .em-c-breadcrumbs__link:hover, .em-c-breadcrumbs--theme-blue .em-c-breadcrumbs__link:focus {
  color: #3a397b;
}
.em-theme--red .em-c-breadcrumbs__link, .em-c-breadcrumbs--theme-red .em-c-breadcrumbs__link {
  color: #0c69b0; /* 1 */
}
.em-theme--red .em-c-breadcrumbs__link:hover, .em-theme--red .em-c-breadcrumbs__link:focus, .em-c-breadcrumbs--theme-red .em-c-breadcrumbs__link:hover, .em-c-breadcrumbs--theme-red .em-c-breadcrumbs__link:focus {
  color: #111122;
}
.em-c-breadcrumbs__link:hover {
  color: #104e8f;
}
.em-c-breadcrumbs__link:focus {
  outline: 1px dotted #0c69b0;
}
.em-c-breadcrumbs__link:active {
  background: none;
}
.em-c-footer--banded .em-c-breadcrumbs__link {
  color: #cccccd;
  font-family: "EMprintRegular", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-style: normal;
}
.em-c-footer--banded .em-c-breadcrumbs__link.em-is-current {
  text-decoration: none;
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
}

/*------------------------------------*\
    #TREE
\*------------------------------------*/
/**
 * 1) A tree is a type of navigation that contains nested lists of subnavigation.
 * 2) Child lists are hidden by default and shown when parent link is clicked
 */
.em-c-tree {
  margin: 1rem;
  max-width: 30rem;
  /**
  * Sticky Behavior
  * 1) Sticky behavior is for
  */
  /**
  * Tree that appears within vertical header
  */
}
.em-c-tree.em-is-sticky {
  position: fixed;
  top: 0;
}
.em-c-header--vertical .em-c-tree {
  margin: 1rem 0 4rem;
}
@media all and (min-width: 62em) {
  .em-c-tree {
    display: block;
    height: 90vh;
    max-width: 30rem;
    /**
      * Tree that appears within vertical header
      */
  }
  .em-c-header--vertical .em-c-tree {
    overflow-y: visible;
    height: auto;
  }
}

/**
 * Tree list
 */
.em-c-tree__list {
  border-left: 1px solid #e5e5e5;
  margin-left: 1.5em;
  display: none;
  /**
  * 1) Apply solid background to sticky element in case it overlaps with other elements
  */
  /**
  * First tree list is always displayed
  */
  /**
  * Style modifier gets rid of margin on list to be flush against container
  */
  /**
  * Tree list is active
  * 1) Display the tree list when activated
  */
  /**
  * Tree nav within vertical header
  */
}
.em-is-sticky .em-c-tree__list {
  background: #fff;
}
.em-c-tree__list:first-child {
  padding: 0;
  border: 0;
  display: block;
}
.em-c-tree__list--flush {
  margin: 0;
}
.em-c-tree__list.em-is-active {
  display: block;
}
@media all and (max-width: 62em) {
  .em-c-header--vertical .em-c-tree__list {
    border-color: #292938;
  }
}

/**
 * Tree list item
 */
.em-c-tree__item {
  /**
  * Tree item that is separated from the tree items below it in the list
  */
}
.em-c-tree__item.is-separator {
  margin: 1rem 0;
  border-bottom: 1px solid #e5e5e5;
}

/**
 * Tree list link
 */
.em-c-tree__link {
  display: flex;
  position: relative;
  padding: 1em 1.5em;
  color: #111122;
  font-size: 0.875rem;
  transition: all 0.15s ease;
  text-decoration: none;
  /**
  * Tree link that is emphasized
  * 1) Font weight is bolded to emphasize these links
  */
  /**
  * Activated tree link
  */
  /**
  * Tree subgroup link is current page
  */
  /**
  * Tree link that is current and is a dropdown
  */
  /**
  * Tree nav within a vertical header
  */
}
.em-c-tree__link:hover {
  color: #111122;
  background-color: #f5f5f5;
}
.em-c-tree__link--emphasized, .em-c-tree__link.em-is-active {
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
}
.em-c-tree__link.em-is-active {
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #111122;
}
.em-c-tree__link.em-is-current {
  background-color: #f5f5f5;
}
@media all and (max-width: 62em) {
  .em-c-tree__link.em-is-current {
    background-color: rgba(0, 0, 0, 0.5);
  }
}
.em-c-tree__link.em-is-current.em-c-tree__link--has-children {
  background: none;
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
  /**
    * Tree nav within a vertical header
    */
}
@media all and (min-width: 62em) {
  .em-c-header--vertical .em-c-tree__link.em-is-current.em-c-tree__link--has-children {
    color: #111122;
  }
}
@media all and (max-width: 62em) {
  .em-c-header--vertical .em-c-tree__link {
    color: #fff;
  }
  .em-c-header--vertical .em-c-tree__link:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

/**
 * Tree dropdown icon
 */
.em-c-tree__icon {
  position: relative;
  top: 0.05rem;
  left: 1rem;
  color: #76767e;
  height: 0.8rem;
  width: 0.8rem;
  /**
  * Tree icon within active tree link
  */
  /**
  * Tree icon within active tree link within header
  * 1) Icons turn white in active state on small screens in hamburger menu
  * 2) Icons turn blue deep once vertical header becomes vertical
  */
  /**
  * Tree icon within header
   * 1) Icon on the right side on larger screens
  */
}
.em-c-tree__link.em-is-active .em-c-tree__icon {
  transform: rotate(90deg);
  color: #111122;
}
.em-c-header .em-c-tree__link.em-is-active .em-c-tree__icon {
  transform: rotate(90deg);
  color: #fff;
}
@media all and (min-width: 62em) {
  .em-c-header .em-c-tree__link.em-is-active .em-c-tree__icon {
    color: #111122;
  }
}
.em-c-header .em-c-tree__icon {
  color: rgba(255, 255, 255, 0.2);
}
@media all and (min-width: 62em) {
  .em-c-header .em-c-tree__icon {
    color: #bbbbbd;
    position: absolute;
    top: 1rem;
    right: 1rem;
    left: initial;
    margin-left: auto; /* 1 */
  }
}

/*------------------------------------*\
    #GLOBAL NAV
\*------------------------------------*/
/**
 * 1) Global navigation of the site, included within the header
 */
.em-c-global-nav {
  margin: 1rem 0;
  border-top: 1px solid #292938;
  padding-top: 1rem;
}
@media all and (min-width: 47em) {
  .em-c-global-nav {
    position: absolute;
    background: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
    right: 0;
    z-index: 7;
    margin: 0;
    padding: 0;
    border: 0;
  }
}

/*
 * Global Nav List
 * 1) Display flex displays items as a horizontal list
 */
@media all and (min-width: 47em) {
  .em-c-global-nav__list {
    display: flex; /* 1 */
    max-width: 84em;
    margin: 0 auto;
    padding: 0 1.5rem;
  }
}

/*
* Global Nav List Item
* 1) Position relative added in case item has children
*/
.em-c-global-nav__item {
  margin-right: 1.5rem;
  position: relative;
  /*
    * Global Nav List Item when Aligned Right
    * 1) Margin left auto shifts global nav item right
    * 2) Zero out the right margin
    */
}
.em-c-global-nav__item.em-is-aligned-right {
  border-top: 1px solid #292938;
  margin-top: 1rem;
  padding-top: 1rem;
  margin-left: auto; /* 1 */
  margin-right: 0; /* 2 */
}
@media all and (min-width: 47em) {
  .em-c-global-nav__item.em-is-aligned-right {
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
  }
}

/*
* Global Nav Link
* 1) Display flex added so link and icon sit side by side if item has children
* 2) Align items center vertically aligns link text and icon
*/
.em-c-global-nav__link {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 0.75rem;
  padding: 1rem 0;
  transition: background 0.15s ease;
}
.em-c-global-nav__link:hover, .em-c-global-nav__link:focus {
  background: rgba(0, 0, 0, 0.2);
}
@media all and (min-width: 47em) {
  .em-c-global-nav__link {
    padding: 0.5rem;
    /**
      * Global navigation link that is actively displaying a dropdown
      */
  }
  .em-c-global-nav__link.em-is-active {
    background: #ad1723;
    /**
      * Active nav link within a blue header variation
      */
  }
  .em-c-header--blue .em-c-global-nav__link.em-is-active {
    background: #3a397b;
  }
}

/**
 * Global navigation dropdown icon
 */
.em-c-global-nav__icon {
  display: inline-block;
  margin-left: 0.5rem;
  position: relative;
  fill: #fff;
  /**
    * Global navigation dropdown icon inside an active global nav link
    */
}
.em-c-global-nav__link.em-is-active .em-c-global-nav__icon {
  transform: rotate(180deg);
}

/**
 * Global Navigation Sublist
 * 1) The sublist is a dropdown menu
 * 2) The sublist is positioned from the top, but stylistically appears below the subnav
 *    trigger link. So calc is used to tuck the sublist up into the subnav trigger link.
 */
.em-c-global-nav__sublist {
  border-left: 1px solid #292938;
  padding-left: 1rem;
  margin-left: 1rem;
  display: none;
  width: 100%;
  min-width: 7.8rem;
  /*
    * Global navigation sublist that is active
    */
}
.em-c-global-nav__sublist.em-is-active {
  display: block;
}
@media all and (min-width: 47em) {
  .em-c-global-nav__sublist {
    background: #ad1723;
    padding: 0 0.5rem 1rem;
    border-left: 0;
    margin-left: 0;
    position: absolute;
    top: calc(100% - 1px); /* 2 */
    left: 0;
    right: 0;
    z-index: 6;
    /**
    * Active nav link within a blue header variation
    */
  }
  .em-c-header--blue .em-c-global-nav__sublist {
    background: #3a397b;
  }
}

/**
 * Sublist link
 * 1) Links in a dropdown menu
 */
.em-c-global-nav__sublink {
  display: block;
  padding: 0.5rem 0;
  color: #fff;
  font-size: 0.75rem;
  /**
     * Active sublist link
     */
}
.em-c-global-nav__sublink.em-is-active {
  display: block;
}
.em-c-global-nav__sublink:hover, .em-c-global-nav__sublink:focus {
  text-decoration: underline;
}

/*------------------------------------*\
    #SOLID NAV
\*------------------------------------*/
/**
 * 1) Solid nav is a visually-heavy, stacked navigation list
 * 2) Can contain icons
 */
.em-c-solid-nav {
  background: #111122;
  padding: 2rem 1rem;
  /**
  * Solid nav within a vertical header
  */
}
.em-c-header--vertical .em-c-solid-nav {
  padding: 2rem 0;
}
@media all and (min-width: 62em) {
  .em-c-header--vertical .em-c-solid-nav {
    padding: 2rem 1rem;
  }
}

/**
 * Solid navigation item
 */
.em-c-solid-nav__item {
  margin-bottom: 1rem;
  /**
  * Last solid navigation item within solid nav
  */
}
.em-c-solid-nav__item:last-child {
  margin: 0;
}

/**
 * Solid navigation link
 * 1) Display flex to get link text and icon to sit side by side
 */
.em-c-solid-nav__link {
  display: flex;
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 0.75rem;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 3px;
  color: #fff;
  padding: 0.25rem 0;
  transition: color 0.15s ease;
}
.em-c-solid-nav__link:hover {
  text-decoration: none;
}
.em-c-solid-nav__link:focus {
  text-decoration: underline;
}

/**
 * Sold navigation icon
 */
.em-c-solid-nav__icon {
  color: #a5a5a5;
  margin-right: 0.8rem;
  transition: color 0.15s ease;
  /**
  * Solid navigation icon within hove and focus state of solid nav link
  */
}
.em-c-solid-nav__link:hover .em-c-solid-nav__icon, .em-c-solid-nav__link:focus .em-c-solid-nav__icon {
  color: #fff;
}

/*------------------------------------*\
    #BACK TO TOP
\*------------------------------------*/
/**
 * 1) A pure css back to top button
 */
.em-c-back-to-top__wrapper {
  position: absolute;
  top: 100vh;
  right: 3rem;
  bottom: -5em;
  width: 2em;
  pointer-events: none;
}

.em-c-back-to-top__link {
  position: fixed;
  position: sticky;
  pointer-events: all;
  top: calc(100vh - 5rem);
  display: inline-block;
  text-decoration: none;
  font-size: 2rem;
  line-height: 3rem;
  text-align: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  padding: 0.25rem;
  background-color: #cccccd;
  transition: all 0.2s ease-in;
  margin-bottom: 0;
}

.em-c-back-to-top__link:hover {
  transform: scale(1.05);
  background-color: #0c69b0;
}

.em-c-back-to-top--red .em-c-back-to-top__link:hover {
  transform: scale(1.05);
  background-color: #d82424;
}

.em-c-back-to-top__link svg {
  margin-bottom: 7px;
  color: #fff;
}

.em-c-back-to-top__link:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 4px;
}

/*------------------------------------*\
    #PROGRESS TRACKER
\*------------------------------------*/
/**
 * 1) Ordered list showing progress (i.e. step by step)
 * 2) Display flex used to put items in row. Flex wrap wraps items underneat when space is
 *    unavailable.
 */
.em-c-progress-tracker {
  display: flex; /* 2 */
  flex-wrap: wrap; /* 2 */
  justify-content: space-between;
  position: relative;
  /* Progress tracker iterm */
  /* Don't display the label on small screens */
}
.em-c-progress-tracker:before {
  content: " ";
  position: absolute;
  right: 0.5rem;
  left: 0.5rem;
  height: 1.55rem;
  border-bottom: solid 1px #e5e5e5;
}
@media all and (max-width: 24em) {
  .em-c-progress-tracker:before {
    height: 1.2rem;
  }
}
.em-c-progress-tracker__item {
  padding: 0.5em;
  text-align: center;
  font-size: 0.875rem;
  width: 14%;
  z-index: 0;
  /* Don't display the horizontal line on the last item number */
}
@media all and (min-width: 30em) {
  .em-c-progress-tracker__item {
    width: 12%;
  }
}
.em-c-progress-tracker__item:last-child .em-c-progress-tracker__number:before {
  display: none;
}
.em-c-progress-tracker__number {
  padding: 0.125rem;
  margin-bottom: 0.5rem;
  border: 1px solid #111122;
  background-color: #fff;
  position: relative;
}
@media all and (min-width: 24em) {
  .em-c-progress-tracker__number {
    padding: 0.5rem;
  }
}
.em-is-complete .em-c-progress-tracker__number {
  color: #545459;
  background-color: #e5e5e5;
  border-color: #e5e5e5;
}
.em-is-current .em-c-progress-tracker__number {
  color: #fff;
  background-color: #111122;
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
}
.em-is-disabled .em-c-progress-tracker__number {
  color: #111122;
  border-color: #111122;
}
.em-c-progress-tracker__label {
  display: none;
  /* Do show it on larger screens */
}
@media all and (min-width: 30em) {
  .em-c-progress-tracker__label {
    display: inline;
  }
}
.em-is-current .em-c-progress-tracker__label {
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
}

/**
 * Forms
 */
/*------------------------------------*\
    #DROPDOWN CHECK
\*------------------------------------*/
/**
 * 1) Dropdown that contains checkbox fields
 */
.em-c-dropdown-check {
  position: relative;
  /**
  * Dropdown check that appears in a search form
  * 1) Adjusts dropdown position to avoid doubling up borders
  */
}
.em-c-search-form .em-c-dropdown-check {
  margin-left: -1px; /* 2 */
  left: 1px;
}

/**
 * Dropdown check panel
 */
.em-c-dropdown-check__panel {
  display: none;
  /**
  * Dropdown panel within active dropdown check
  */
  /**
  * Dropdown panel within toolbar
  */
}
.em-c-dropdown-check.em-is-active .em-c-dropdown-check__panel {
  display: block;
  position: absolute;
  top: calc(100% - 1px);
  background: #fbfbfa;
  border: 1px solid #0c69b0;
  padding: 1rem;
  z-index: 2;
  width: 10rem;
  border-radius: 0 4px 4px 4px;
}
.em-c-toolbar .em-c-dropdown-check.em-is-active .em-c-dropdown-check__panel {
  width: 100%;
}
@media all and (min-width: 47em) {
  .em-c-toolbar .em-c-dropdown-check.em-is-active .em-c-dropdown-check__panel {
    width: 10em;
  }
}

/**
 * Dropdown check list item
 */
.em-c-dropdown-check__item {
  margin-bottom: 1em;
  /**
  * Last dropdown check list item within dropdown list
  */
}
.em-c-dropdown-check__item:last-child {
  margin-bottom: 0;
}

.em-c-dropdown-check.em-is-active .em-c-btn--is-dropdown:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 4px;
}

/*------------------------------------*\
    #FIELDS
\*------------------------------------*/
/**
 * 1) Consists of a label, form control, and an optional note about the field.
 */
.em-c-field {
  margin-bottom: 2rem;
  /**
     * Inline field
     * 1) Display flex added to place items side by side
     * 2) Align items center vertically centers these items within field
     */
  /**
  * Fields that are contained within grid items
  */
  /**
  * Field within a search form that is within a footer
  */
}
.em-c-field--inline {
  display: flex; /* 1 */
  align-items: center; /* 2 */
  margin-bottom: 0;
  /**
  * Inline field within toolbar
  */
}
.em-c-toolbar .em-c-field--inline {
  display: block;
}
@media all and (min-width: 47em) {
  .em-c-toolbar .em-c-field--inline {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
.em-c-field--file-upload {
  position: relative;
}
.em-l-grid__item .em-c-field {
  margin-bottom: 0;
}
.em-c-footer .em-c-search-form .em-c-field {
  margin-bottom: 0;
}

/**
 * Field label
 */
.em-c-field__label {
  margin-bottom: 0.5rem;
  color: #111122;
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1rem;
  position: relative;
  /**
  * Field label within inline field
  */
  /**
  * Field label within toolbar
  */
  /**
  * Field body within a search form that is within a footer
  */
}
.em-c-field--inline .em-c-field__label {
  margin: 0 0.5rem 0 0;
}
.em-c-toolbar .em-c-field__label {
  font-size: 0.875rem;
}
.em-c-footer .em-c-search-form .em-c-field__label {
  margin: 0;
}

/**
	 * Field required
	 */
.em-c-field__required {
  position: absolute;
  font-weight: 600;
  color: #d82424;
  margin-left: -10px;
  margin-right: 3px;
}

/**
 * Field body
 */
.em-c-field__body {
  position: relative;
  margin-bottom: 0.5rem;
  /**
  * Field body within inline field
  */
  /**
  * Field body within file upload
  */
  /**
  * Field body within disabled file upload
  */
  /**
  * Field body within erroneous file upload
  */
  /**
  * Field body within toolbar
  */
  /**
   * Field body within a search form that is within a footer
   */
}
.em-c-field__body--stretch {
  flex: 1;
}
.em-c-field--inline .em-c-field__body {
  margin: 0;
}
.em-c-field--file-upload .em-c-field__body {
  position: static;
  text-align: center;
  padding: 1.5rem 1rem;
  border: 2px dashed #cccccd;
  border-radius: 4px;
  transition: all 0.15s ease;
}
.em-c-field--file-upload .em-c-field__body:hover, .em-c-field--file-upload .em-c-field__body:focus {
  border-color: #343434;
}
.em-c-field--file-upload.em-is-disabled .em-c-field__body {
  background: #f5f5f5;
  color: #cccccd;
}
.em-c-field--file-upload.em-is-disabled .em-c-field__body:hover, .em-c-field--file-upload.em-is-disabled .em-c-field__body:focus {
  border-color: #cccccd;
}
.em-c-field--file-upload.em-has-error .em-c-field__body {
  border-color: #b12a0b;
}
.em-c-field--file-upload.em-has-error .em-c-field__body:hover, .em-c-field--file-upload.em-has-error .em-c-field__body:focus {
  border-color: #b12a0b;
}
.em-c-toolbar .em-c-field__body {
  width: 100%;
}
@media all and (min-width: 47rem) {
  .em-c-toolbar .em-c-field__body {
    width: inherit;
  }
}
.em-c-footer .em-c-search-form .em-c-field__body {
  margin: 0;
}

/**
 * Field item
 */
.em-c-field__item--small {
  font-size: 0.75rem;
}

/**
 * Field note
 */
.em-c-field__note {
  display: inline-block;
  max-width: 25rem;
  font-size: 0.75rem;
  color: #111122;
  /**
  * Field note within field with valid
  */
  /**
  * Field note within field with error
  */
}
.em-c-field.em-is-valid .em-c-field__note {
  color: #007c38;
  font-family: "EMprintRegular", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-style: normal;
}
.em-c-field.em-has-error .em-c-field__note {
  color: #b12a0b;
  font-family: "EMprintRegular", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-style: normal;
}

/**
 * Field icon within field with error
 */
.em-c-field__icon {
  position: absolute;
  bottom: 10px;
  right: 14px;
  cursor: default;
  /**
  * Field Icon within valid field
  */
  /**
  * Field Icon within field with error
  */
  /**
  * Field Icon within disabled field
  */
  /**
  * Field Icon within read only field
  */
}
.em-c-field.em-is-valid .em-c-field__icon {
  color: #007c38;
  fill: #007c38;
}
.em-c-field.em-has-error .em-c-field__icon {
  color: #b12a0b;
  fill: #b12a0b;
}
.em-c-field.em-is-disabled .em-c-field__icon {
  color: #808080;
  fill: #808080;
}
.em-c-field.em-is-readonly .em-c-field__icon {
  color: #808080;
  fill: #808080;
}

/**
 * Field Block Icon
 * 1) Larger than regular field icon. Used for fields like file upload
 */
.em-c-field__block-icon {
  color: #007c38;
  fill: #007c38;
  /**
  * Block Icon within Disabled File Upload Field
  */
  /**
  * Block Icon within erroneous file upload field
  */
}
.em-c-field.em-is-disabled .em-c-field__block-icon {
  color: #cccccd;
  fill: #cccccd;
}
.em-c-field.em-has-error .em-c-field__block-icon {
  color: #b12a0b;
  fill: #b12a0b;
}

.em-c-field__count {
  position: absolute;
  bottom: 10px;
  right: 22px;
}
.em-c-field--count.em-has-error .em-c-field__count {
  color: #b12a0b;
}

/**
 * Field Menu
 * 1) Displays a dropdown-like list for typeahead
 */
.em-c-field__menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2;
  width: 100%;
  border: 1px solid #cccccd;
  border-top: 0;
  background: #fff;
  display: none; /* 2 */
}
.em-c-field__menu.em-is-active {
  display: block;
}

/**
 * Field Input
 * 1) Actual field input
 */
.em-c-field__input {
  /**
     * Field input within a date picker
     * 1) Stack input on top of icon so clicking the icon
     *    focuses the field
     */
}
.em-c-field--date-picker .em-c-field__input {
  position: relative;
  z-index: 2; /* 1 */
  background: transparent;
}

/*------------------------------------*\
    #FIELDSET
\*------------------------------------*/
/**
 * 1) Wrapper containing multiple fields
 */
.em-c-fieldset {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #e5e5e5;
}

/**
 * Fieldset legend
 * 1) Basically the title of the fieldset
 */
.em-c-fieldset__legend {
  margin: 0 0 2rem;
  font-weight: normal;
  color: #343434;
  font-size: 1.5rem;
}

/**
 * Fieldset focus visible
 */
.em-c-fieldset:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 5px;
  box-shadow: none;
}

/*------------------------------------*\
    #TOGGLE
\*------------------------------------*/
/**
 * 1) The toggle is essentially a stlyized set of radio buttons, in which the
 *    radio inputs are hidden and the user interacts directly with the labels.
 *    The input is visually hidden using a utility class in order to maintain
 *    keyboard access.
 * 2) Display flex is used to place items beside each other
 */
.em-c-toggle {
  display: flex; /* 2 */
}

/**
 * Toggle Label
 */
.em-c-toggle__label {
  display: block;
  padding: 0.5rem 1.5rem;
  border: 1px solid #0c69b0;
  border-right: 0;
  color: #0c69b0;
  text-align: center;
  cursor: pointer;
  font-family: "EMprintRegular", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.em-c-toggle__container:first-of-type .em-c-toggle__label {
  border-radius: 4px 0 0 4px;
}
.em-c-toggle__container:last-child .em-c-toggle__label {
  border-right: 1px solid #0c69b0;
  border-radius: 0 4px 4px 0;
}

/**
 * Active Toggle State
 * 1) The toggle label comes immediately after the input in the source order,
 *    which means the :checked pseudo-selector can be used to target the adjascent
 *    label.
 */
.em-c-toggle__container:has(input[type=radio]:checked) .em-c-toggle__label {
  background: #0c69b0;
  color: #fff;
  height: 100%;
}

/* Toggle accesibility focus styles */
.em-c-toggle .em-c-toggle__container input[type=radio]:focus + label {
  outline: none;
  box-shadow: none;
  border: none;
}

.em-c-field--toggle:focus-visible,
.em-c-toggle .em-c-toggle__container input[type=radio]:focus-visible + label,
.em-c-toggle input[type=radio]:focus + label {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 2px;
  box-shadow: none;
  border: none;
}

/* Toggle with error */
.em-has-error .em-c-toggle__container:has(input[type=radio]:checked) .em-c-toggle__label {
  background: #b12a0b;
  color: #fff;
}

.em-has-error .em-c-toggle__container .em-c-toggle__label {
  border: 1px solid #b12a0b;
  color: #b12a0b;
}

/* Disabled toggle */
.em-is-disabled .em-c-toggle__container:has(input[type=radio]:checked) .em-c-toggle__label {
  background: #a5a5a5;
  color: #fff;
}

.em-is-disabled .em-c-toggle__container .em-c-toggle__label {
  border: 1px solid #a5a5a5;
  color: #a5a5a5;
}

.em-is-disabled:focus-visible {
  outline: none;
  box-shadow: none;
  border: none;
}

/*------------------------------------*\
    #SEARCH FORM
\*------------------------------------*/
/**
 * 1) Form that contains an input field and a button to search
 */
.em-c-search-form {
  /**
  * 1) Search form that appears in header
  * 2) Positions it on click
  */
  /**
  * Search form in a vertical header
  * 1. Hide by default at wide widths.
  */
  /**
  * Search form in vertical header
  * 2. Display if button is activated at wide widths
  */
  /**
  * Search form within vertical header
  * 3. But hide if button activated at wide widths but closed in sidebar
  * (This is gross, but have to stack up the selectors to make the specificity
  * high enough to stick and override the bp-large values).
  */
  /**
  * Search form within a tag search
  */
  /**
  * Search form within a banded footer
  */
}
@media all and (min-width: 47em) {
  .em-c-header .em-c-search-form {
    position: absolute;
    top: 100%; /* 2 */
    left: 0;
    width: 100%;
    display: none; /* 1 */
    background: #111122;
    padding: 2rem;
    z-index: 2;
    /**
    * Active search form within header
    */
  }
  .em-c-header .em-c-search-form.em-is-active {
    display: block;
  }
}
@media all and (min-width: 47em) {
  .em-c-header--vertical .em-c-search-form {
    position: static;
    display: none; /* 1 */
    background: none;
    padding: 1rem;
    background-color: #111122;
  }
}
@media all and (min-width: 47em) {
  .em-c-header--vertical .em-c-header__nav-container.em-is-active .em-c-search-form {
    display: block;
  }
}
@media all and (min-width: 62em) {
  .em-c-header.em-c-header--vertical .em-c-header__nav-container .em-c-search-form {
    display: none;
  }
  .em-c-header.em-c-header--vertical .em-c-header__nav-container .em-c-search-form.em-is-active {
    display: block;
  }
}
.em-c-tag-search .em-c-search-form {
  margin-bottom: 1em;
}
@media all and (min-width: 44rem) {
  .em-c-footer--banded .em-c-search-form {
    min-width: 25rem;
    margin-left: auto;
  }
}

/**
 * Search form label
 * 1) Flex 1 used to take up the rest of the space within search form with the input
 */
.em-c-search__label {
  margin-bottom: 1rem;
  /**
  * Search form label
  * 1) Flex 1 used to take up the rest of the space within search form with the input
  */
  /**
  * Large search label
  */
}
.em-c-band--blue-deep .em-c-search__label {
  color: #fff;
  font-family: "EMprintLight", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.em-c-search__label--large {
  font-size: 1.5rem;
}

/**
  * Search form body
  * 1) Container for the inputs. Display flex used to put content within search form in a row
  */
.em-c-search__body {
  display: flex; /* 1 */
  /**
  * Search body within header
  */
  /**
  * input styles for combo search
  */
  /**
  * button styles for combo search
  */
}
.em-c-header .em-c-search__body {
  max-width: calc(84em - 4rem);
  margin: 0 auto;
}
.em-c-search__body .em-c-search__input,
.em-c-search__body .em-c-btn--is-dropdown,
.em-c-search__body .em-c-select {
  border-radius: 4px 0 0 4px;
}
.em-c-search__body .em-c-search__input.em-is-active,
.em-c-search__body .em-c-btn--is-dropdown.em-is-active,
.em-c-search__body .em-c-select.em-is-active {
  border-radius: 4px 0 0 0;
}
.em-c-search__body .em-c-btn:not(.em-c-btn--is-dropdown) {
  border-radius: 0 4px 4px 0;
  margin-left: -1px;
}

/**
 * Search form input
 * 1) Flex 1 used to take up the rest of the space within search form with the input
 */
.em-c-search__input {
  flex: 1; /* 1 */
  box-shadow: none;
  z-index: 0;
  padding: 0px 0.7rem;
  /**
  * Search form input placeholder text
  */
  /**
  * Search form within tag search
  */
  /**
  * Search form within small search form
  */
  /**
  * Overide default focus form style
  */
  /**
  * Search input within header and band
  * 1) Remove border since search is already in a band of color
  */
  /**
  * Search form with large text
  */
}
.em-c-search__input::placeholder {
  font-style: normal;
}
.em-c-tag-search .em-c-search__input {
  border-right: 0;
}
.em-c-search-form--small .em-c-search__input {
  padding: 0px 0.7rem;
}
.em-c-search__input:focus {
  z-index: 1;
}
.em-c-header .em-c-search__input:focus, .em-c-band .em-c-search__input:focus {
  outline: none;
}
.em-c-search-form--large-text .em-c-search__input {
  font-size: 1.5rem;
  font-family: "EMprintRegular", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-style: normal;
}

/**
 * Select button/dropdown check before Search form input
 * 1) Shifts input left to get rid of the double border caused by button/select and input
 */
.em-c-select + .em-c-search__input,
.em-c-dropdown-check + .em-c-search__input {
  margin-left: -1px;
  border-radius: 0;
}

/*------------------------------------*\
    #OPTION LIST
\*------------------------------------*/
/**
 * 1) Option lists are used for checkboxes and radio groups.
 */
/**
 * Option List Item
 * 1) Position relative and margin bottom of -1px added to overlap active border over inactive border
 */
.em-c-option-list__item {
  position: relative; /* 1 */
  margin-bottom: -1px; /* 1 */
  border-top: 1px solid #cccccd;
  border-bottom: 1px solid #cccccd;
  transition: all 0.15s ease;
  /**
  * Activated Option List Item
  * 1) Option list item when JS applies active class
  * 2) Z-index of 1 added to overlap active border over inactive border instead of making thicker border
  */
}
.em-c-option-list__item:hover, .em-c-option-list__item:focus {
  background: #f5f5f5;
}
.em-c-option-list__item:has(input[type=checkbox]:checked), .em-c-option-list__item:has(input[type=radio]:checked) {
  background: #e1efff;
  border-color: #0c69b0;
  z-index: 1; /* 2 */
  /**
  * Field note within field with error
  */
  /**
  * Option list item within disabled field with error
  */
}
.em-c-option-list__item:has(input[type=checkbox]:checked):hover, .em-c-option-list__item:has(input[type=checkbox]:checked):focus, .em-c-option-list__item:has(input[type=radio]:checked):hover, .em-c-option-list__item:has(input[type=radio]:checked):focus {
  background: #e1efff;
}
.em-c-field.em-has-error .em-c-option-list__item:has(input[type=checkbox]:checked), .em-c-field.em-has-error .em-c-option-list__item:has(input[type=radio]:checked) {
  background: #fdded8;
  border-color: #b12a0b;
}
.em-c-field.em-is-disabled .em-c-option-list__item:has(input[type=checkbox]:checked), .em-c-field.em-is-disabled .em-c-option-list__item:has(input[type=radio]:checked) {
  border-color: #cccccd;
  background: #f5f5f5;
}
.em-c-field.em-is-disabled .em-c-option-list__item:has(input[type=checkbox]:checked):hover, .em-c-field.em-is-disabled .em-c-option-list__item:has(input[type=radio]:checked):hover {
  background: #f5f5f5;
}

/*------------------------------------*\
    #INPUTS
\*------------------------------------*/
/**
 * Input
 */
.em-c-input {
  /**
  * Input within small field
  * 1) Applying small to em-c-field instead of em-c-input allows more flexibility with element
  */
  /**
  * Input within field that is valid
  */
  /**
  * Input that is active
  */
  /**
  * Input within field with error
  */
  /**
  * Input within disabled field
  */
  /**
  * Input within read-only field
  */
  /**
  * Get rid of x on input fields in IE
  */
}
.em-c-input--small {
  padding: 0.3rem 0.23rem 0.31rem;
}
.em-c-input:hover {
  outline: none;
  border-color: #0c69b0;
}
.em-c-field--small .em-c-input {
  padding: 0.3rem 0.23rem 0.31rem;
}
.em-c-field.em-is-valid .em-c-input:focus {
  outline: none;
  border-color: #0c69b0;
}
.em-c-field.em-is-active .em-c-input:focus {
  outline: none;
  border-color: #0c69b0;
}
.em-c-field.em-has-error .em-c-input {
  border: 1px solid #b12a0b;
}
.em-c-field.em-has-error .em-c-input:focus {
  outline: none;
}
.em-c-field.em-has-error .em-c-input:focus::placeholder {
  color: #a5a5a5;
}
.em-c-field.em-has-error .em-c-input::placeholder {
  color: #b12a0b;
}
.em-c-field.em-is-disabled .em-c-input {
  /**
  * Input with disabled attribute within disabled field
  */
}
.em-c-field.em-is-disabled .em-c-input:disabled {
  background: #f5f5f5;
}
.em-c-field.em-is-disabled .em-c-input:hover {
  outline: none;
  border-color: #cccccd;
}
.em-c-field.em-is-readonly .em-c-input:hover, .em-c-field.em-is-readonly .em-c-input:focus {
  outline: none;
  border-color: #cccccd;
}
.em-c-input::-ms-clear {
  display: none;
}

/**
 * Text area
 */
.em-c-textarea {
  /**
  * Text area that is active
  */
  /**
  * Text area within field with error
  */
  /**
  * Select with disabled attribute
  */
  /**
  * Text area within read-only field
  */
  /**
  * Text area within read-only field
  */
}
.em-c-field.em-is-active .em-c-textarea:focus, .em-c-field.em-is-active .em-c-textarea:hover {
  border-color: #cccccd;
}
.em-c-field.em-has-error .em-c-textarea {
  border: 1px solid #b12a0b;
}
.em-c-field.em-has-error .em-c-textarea:focus {
  outline: none;
}
.em-c-field.em-has-error .em-c-textarea::placeholder {
  color: #b12a0b;
}
.em-c-field.em-is-disabled .em-c-textarea {
  /**
  * Text area with disabled attribute within disabled field
  */
}
.em-c-field.em-is-disabled .em-c-textarea:disabled {
  background: #f5f5f5;
}
.em-c-field.em-is-disabled .em-c-textarea:hover {
  outline: none;
  border-color: #cccccd;
}
.em-c-field.em-is-readonly .em-c-textarea:hover, .em-c-field.em-is-readonly .em-c-textarea:focus {
  outline: none;
  border-color: #cccccd;
}
.em-c-field--count .em-c-textarea {
  height: 10rem;
  border: 1px solid #cccccd;
}
.em-c-field--count .em-c-textarea:focus {
  outline: none;
  border-color: #0c69b0;
}

/**
 * Select menu
 */
.em-c-select {
  padding: 0.7rem 2rem 0.7rem 0.7rem;
  /**
  * Select menu within small field
  */
  /**
  * Select within field with error
  */
  /**
  * Select within disabled field with error
  */
  /**
  * Select within toolbar
  */
  /**
  * Select within search form
  * 1) Shows border of the select when focused
  */
  /**
  * Select within small search form
  */
  /**
  * Select within search form within footer
  */
}
.em-c-field--small .em-c-select {
  font-size: 0.875rem;
  height: 1.95rem;
  padding: 0 2rem 0 0.5rem;
}
.em-c-field.em-has-error .em-c-select {
  border: 1px solid #b12a0b;
}
.em-c-field.em-has-error .em-c-select:focus {
  outline: none;
}
.em-c-field.em-has-error .em-c-select::placeholder {
  color: #b12a0b;
}
.em-c-field.em-is-disabled .em-c-select {
  /**
  * Select with disabled attribute within disabled field
  */
}
.em-c-field.em-is-disabled .em-c-select:disabled {
  background: #f5f5f5;
  color: #808080;
}
.em-c-field.em-is-disabled .em-c-select:hover {
  outline: none;
  border-color: #cccccd;
}
.em-c-toolbar .em-c-select {
  width: 100%;
  margin-top: 0.2rem;
}
@media all and (min-width: 47em) {
  .em-c-toolbar .em-c-select {
    width: inherit;
    margin-top: 0;
  }
}
.em-c-search-form .em-c-select:focus {
  z-index: 1;
}
.em-c-search-form--small .em-c-select {
  height: 1.95rem;
  padding: 0 2rem 0 0.5rem;
  font-size: 0.875rem;
}
.em-c-footer .em-c-search-form .em-c-select {
  padding-right: 2rem;
  background-color: #fbfbfa;
}

.em-c-field .em-c-select:focus-visible,
.em-c-field.em-has-error .em-c-select:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 3px;
}

/**
* Focus styles text input
*/
.em-c-field .em-c-field__body .em-c-input:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 3px;
}

.em-c-field.em-has-error .em-c-input:focus-visible {
  border: 1px solid #b12a0b;
}

.em-c-field.em-has-error .em-c-input:focus-visible::placeholder {
  color: #b12a0b;
}

.em-c-field__icon:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 3px;
}

/**
* Focus styles text area
*/
.em-c-textarea:focus-visible,
.em-has-error textarea:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 3px;
}

.em-c-field.em-has-error .em-c-textarea:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 3px;
  border: 1px solid #b12a0b;
}

.em-c-field.em-is-readonly .em-c-textarea:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 3px;
}

.em-c-field__icon:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 3px;
}

/*------------------------------------*\
    #INPUT GROUP
\*------------------------------------*/
/**
 * 1) Input groups are checkbox and radio
 */
.em-c-input-group {
  display: flex;
  cursor: pointer;
  transition: all 0.15s ease-out;
  /**
   * 1) Input group within a field
   */
  /**
  * Input group within disabled field with error
  */
}
.em-c-field .em-c-input-group {
  padding: 0.5rem;
}
.em-c-field.em-is-disabled .em-c-input-group {
  cursor: default;
}

/**
 * Input group text
 */
.em-c-input-group__text {
  font-family: "EMprintRegular", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-style: normal;
  flex: 1;
}

/**
 * Input group control
 */
.em-c-input-group__control {
  position: relative;
  top: 1px;
  /**
  * Input group control within disabled field with error
  */
}
.em-c-field.em-is-disabled .em-c-input-group__control {
  /**
  * Input group control with disabled attribute within disabled field
  */
}
.em-c-field.em-is-disabled .em-c-input-group__control:disabled {
  color: #808080;
  background: #f5f5f5;
}
.em-c-field.em-is-disabled .em-c-input-group__control:hover {
  outline: none;
  border-color: #cccccd;
}

/*------------------------------------*\
    #TAG SEARCH
\*------------------------------------*/
/**
 * 1) Block containing search form and tags
 */
.em-c-tag-search {
  background: #f5f5f5;
  padding: 2em 2em 1em;
  margin-bottom: 2em;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

/**
 * Tags section of the tag search block
 */
.em-c-tag-search__tags {
  display: flex;
  flex-direction: column;
}
@media all and (min-width: 40em) {
  .em-c-tag-search__tags {
    flex-direction: row;
  }
}

/**
 * Title of the tags section
 */
.em-c-tag-search__title {
  margin-right: 0.5em;
  font-size: 0.75rem;
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  line-height: 1.5;
}

/*------------------------------------*\
    #COLLAPSIBLE TOOLBAR
\*------------------------------------*/
/**
 * A toolbar that can be collapsible on small screens
 */
.em-c-collapsible-toolbar {
  position: relative;
  background: #f5f5f5;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  padding: 0.8em 0.4em;
}
@media all and (min-width: 47em) {
  .em-c-collapsible-toolbar {
    background: none;
    border: 0;
    padding: 0;
  }
}

/**
 * Collapsible toolbar panel
 */
.em-c-collapsible-toolbar__panel {
  display: none;
  position: relative;
  top: -1px;
  /**
    * Collapsible toolbar panel within active collapsible toolbar
    */
}
.em-c-collapsible-toolbar.em-is-active .em-c-collapsible-toolbar__panel {
  display: block;
  z-index: 1;
}
@media all and (min-width: 47em) {
  .em-c-collapsible-toolbar.em-is-active .em-c-collapsible-toolbar__panel {
    border: 0;
  }
}
@media all and (min-width: 47em) {
  .em-c-collapsible-toolbar__panel {
    display: block;
    border: 0;
    z-index: 1;
  }
}

/*------------------------------------*\
    #FILE UPLOAD
\*------------------------------------*/
/**
 * 1) Used to browse and upload a file
 * 2) Position the actual input over the stylized border
 * 3) Set opacity to 0 to hide the native control and show
 *    the stylized area beneath it
 */
.em-c-file-upload {
  display: block;
  position: absolute; /* 2 */
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  border: 0;
  box-shadow: none;
  opacity: 0; /* 3 */
  /**
  * Field note within field with error
  */
  /**
  * Field note within field with error
  */
}
.em-c-file-upload:hover, .em-c-file-upload:focus {
  cursor: pointer;
}
.em-c-field.em-has-error .em-c-file-upload {
  border: 2px solid #b12a0b;
}
.em-c-field.em-is-disabled .em-c-file-upload {
  background: #f5f5f5;
}
.em-c-field.em-is-disabled .em-c-file-upload:hover, .em-c-field.em-is-disabled .em-c-file-upload:focus {
  cursor: default;
}

.em-c-field--file-upload:focus-within {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 2px;
}

.em-c-field__list li {
  list-style: none;
}

/*------------------------------------*\
    #TYPEAHEAD
\*------------------------------------*/
/**
 * Typeahead list
 * 1) List of typeahead results
 */
.em-c-typeahead-list {
  border-bottom: 1px solid #cccccd;
  max-height: 24rem;
  overflow: auto;
}
.em-c-typeahead-list:last-child {
  border-bottom: 0;
}

/**
 * List item
 * 1) individual item
 */
.em-c-typeahead-list__item {
  padding: 1rem 0.5rem;
  display: flex;
  align-items: center;
  cursor: default;
}
.em-c-typeahead-list__item:hover, .em-c-typeahead-list__item:focus {
  color: #fff;
  background: #0c69b0;
}

/*------------------------------------*\
    #Switch
\*------------------------------------*/
.em-c-switch {
  user-select: none;
  margin-top: 0.5rem;
  /* focus */
}
.em-c-switch__label {
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
  display: flex;
  align-items: center;
  padding-right: 20px;
  justify-content: flex-start;
}
.em-c-switch__toggle {
  background: #eeeeee;
  left: 2px;
  height: 26px;
  width: 40px;
  border-radius: 20px;
  border: 1px solid #0c69b0;
  position: relative;
  top: calc(50% - 12px);
}
.em-c-switch__toggle:before {
  content: "";
  height: 14px;
  width: 14px;
  border-radius: 14px;
  position: absolute;
  left: 2px;
  top: 4px;
  background: #fff;
  z-index: 1;
  transition: left 0.15s ease-out;
  border: 1px solid #0c69b0;
  cursor: pointer;
}
.em-c-switch__input:checked + .em-c-switch__label .em-c-switch__toggle {
  left: 2px;
  background: #0c69b0;
  border: 1px #0c69b0 solid;
}
.em-c-switch__input:checked + .em-c-switch__label .em-c-switch__toggle:before {
  left: 18px;
  height: 18px;
  width: 18px;
  border-radius: 18px;
  top: 2px;
  transition: all 0.15s ease-in;
  background: #fff;
}
.em-c-switch__state::after {
  content: "Off";
  display: inline-flex;
  margin-left: 3rem;
  font-size: 0.875rem;
  padding-top: 0.25rem;
  font-family: "EMprintRegular", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-style: normal;
}
.em-c-switch__input:checked + .em-c-switch__label .em-c-switch__state::after {
  content: "On";
}
.em-c-switch__input:disabled + .em-c-switch__label {
  cursor: not-allowed;
  filter: grayscale(100%);
  opacity: 0.35;
}
.em-c-switch__input:disabled + .em-c-switch__label .em-c-switch__toggle::before {
  cursor: not-allowed;
}
.em-c-switch [type=checkbox]:focus-visible + .em-c-switch__label .em-c-switch__toggle {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 2px;
  border-radius: 1em;
}
.em-c-switch [type=checkbox] {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

/*------------------------------------*\
    #Range slider
\*------------------------------------*/
.em-c-range-slider {
  user-select: none;
  margin-top: 0.5rem;
}
.em-c-range-slider__label {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.5rem;
}
.em-c-range-slider input[type=range] {
  /*removes default webkit styles*/
  -webkit-appearance: none;
  appearance: none;
  /*fix for FF unable to apply focus style bug */
  border: 1px solid #fff;
  /*required for proper track sizing in FF*/
  padding: 0;
  /*hide the outline behind the border*/
}
.em-c-range-slider input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  background: #e5e5e5;
  border: none;
  border-radius: 3px;
}
.em-c-range-slider input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #0c69b0;
  margin-top: -6px;
}
.em-c-range-slider input[type=range]:focus {
  outline: none;
}
.em-c-range-slider input[type=range]::-moz-range-track {
  width: 100%;
  height: 5px;
  background: #e5e5e5;
  border: none;
  border-radius: 3px;
}
.em-c-range-slider input[type=range]::-moz-range-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #0c69b0;
}
.em-c-range-slider input[type=range]:-moz-focusring {
  outline: 1px solid #fff;
  outline-offset: -1px;
}
.em-c-range-slider input[type=range]::-ms-track {
  width: 100%;
  height: 5px;
  /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
  background: transparent;
  /*leave room for the larger thumb to overflow with a transparent border */
  border-color: transparent;
  border-width: 6px 0;
  /*remove default tick marks*/
  color: transparent;
}
.em-c-range-slider input[type=range]::-ms-fill-lower {
  background: #e5e5e5;
  border-radius: 10px;
}
.em-c-range-slider input[type=range]::-moz-range-progress {
  background: #e5e5e5;
  border-radius: 10px;
}
.em-c-range-slider input[type=range]::-ms-fill-upper {
  background: #e5e5e5;
  border-radius: 10px;
}
.em-c-range-slider input[type=range]::-ms-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #0c69b0;
}
.em-c-range-slider input[type=range]::-ms-tooltip {
  display: none;
}

.em-c-field input[type=range]:focus {
  outline: none;
  box-shadow: none;
}

.em-c-field input[type=range]:focus-visible {
  outline: none;
  box-shadow: none;
}

.em-c-range-slider input[type=range]:focus-visible::-webkit-slider-thumb {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 5px;
  box-shadow: none;
  border: none;
}

.em-c-range-slider-list__item {
  list-style: none;
}

/*------------------------------------*\
    #CHECKBOX
\*------------------------------------*/
/**
 * 1) A checkbox allows a user to make one or more selections from a set of options
 */
/* Customize the label (the container) */
.em-c-checkbox--container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Hide the browser's default checkbox */
}
.em-c-checkbox--container input {
  position: absolute;
  opacity: 1;
  cursor: pointer;
  height: 18px;
  width: 18px;
  top: 1px;
  left: 8px;
}
.em-c-checkbox--container.em-is-disabled {
  cursor: not-allowed;
}

.em-c-option-list__item .em-c-checkbox--container input {
  position: absolute;
  opacity: 1;
  cursor: pointer;
  margin: 7px 0px;
  width: 18px;
  height: 18px;
}

/* Add focus for accessibility */
.em-c-checkbox--container input[type=checkbox]:focus {
  outline: none;
  box-shadow: none;
  border: none;
}

.em-c-checkbox--container input[type=checkbox]:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 2px;
  box-shadow: none;
  border: none;
}

/* Create a custom checkbox */
.em-c-checkbox--checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px #76767e solid;
  background-color: #fff;
  border-radius: 4px;
  left: 8px;
}

/* Spacing for option list */
.em-c-option-list__item .em-c-checkbox--checkmark {
  top: 8px;
}

.em-c-option-list__item .em-c-checkbox--container .em-c-input-group__text {
  margin-left: 1.5em;
}

/* On mouse-over, add a blue background color */
.em-c-checkbox--container:hover input ~ .em-c-checkbox--checkmark {
  border: 1px #3190d9 solid;
}

.em-has-error .em-c-checkbox--container:hover input ~ .em-c-checkbox--checkmark {
  border: 1px #ad1723 solid;
}

/* When the checkbox is checked, add a blue background */
.em-c-checkbox--container input:checked ~ .em-c-checkbox--checkmark {
  background-color: #0c69b0;
  border: 1px #0c69b0 solid;
}

.em-c-checkbox--container input:checked:disabled ~ .em-c-checkbox--checkmark {
  background-color: #cccccd;
  border: 1px #cccccd solid;
}

.em-c-checkbox--container input:disabled ~ .em-c-checkbox--checkmark {
  border: 1px #cccccd solid;
}

.em-has-error .em-c-checkbox--container input:checked ~ .em-c-checkbox--checkmark {
  background-color: #ad1723;
  border: 1px #ad1723 solid;
}

/* Create the checkmark/indicator (hidden when not checked) */
.em-c-checkbox--checkmark:after {
  content: "";
  position: absolute;
}

/* Show the checkmark when checked */
.em-c-checkbox--container input:checked ~ .em-c-checkbox--checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.em-c-checkbox--container .em-c-checkbox--checkmark:after {
  left: 5px;
  top: 1px;
  width: 4px;
  height: 9px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.em-c-field--checkbox:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 4px;
}

.em-c-option-list__item .em-c-checkbox--container input {
  /* Reduce native checkbox size compared with the customized, for don't view the 'blue' on back */
  width: 16px;
  height: 16px;
  margin: 8px 1px;
}

/*------------------------------------*\
    #RADIO BUTTON
\*------------------------------------*/
/**
 * 1) A radio button limits a user to choosing just one selection among a set of options
 */
/* Customize the label (the container) */
.em-c-radio--container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Hide the browser's default radio button */
}
.em-c-radio--container input {
  position: absolute;
  opacity: 1;
  cursor: pointer;
  width: 18px;
  height: 18px;
  top: 1px;
  left: 8px;
}

.em-c-option-list__item .em-c-radio--container input {
  position: absolute;
  opacity: 1;
  cursor: pointer;
  margin: 7px 0px;
  width: 18px;
  height: 18px;
}

/* Add focus for accessibility */
.em-c-radio--container input[type=radio]:focus {
  box-shadow: 0 0 0 1px #3190d9;
}

/* Create a custom radio button */
.em-c-radio--checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px #76767e solid;
  left: 8px;
}

/* Spacing for option list */
.em-c-option-list__item .em-c-radio--checkmark {
  top: 8px;
}

.em-c-option-list__item .em-c-radio--container .em-c-input-group__text {
  margin-left: 1.5em;
}

/* On mouse-over, add a grey background color */
.em-c-radio--container:hover input ~ .em-c-radio--checkmark {
  border: 1px #3190d9 solid;
}

.em-has-error .em-c-radio--container:hover input ~ .em-c-radio--checkmark {
  border: 1px #ad1723 solid;
}

/* When the radio button is checked, add a blue background */
.em-c-radio--container input:checked ~ .em-c-radio--checkmark {
  background-color: #fff;
  border: 1px #0c69b0 solid;
}

.em-c-radio--container input:checked:disabled ~ .em-c-radio--checkmark {
  background-color: #fff;
  border: 1px #cccccd solid;
}

.em-c-radio--container input:disabled ~ .em-c-radio--checkmark {
  border: 1px #cccccd solid;
}

.em-has-error .em-c-radio--container input:checked ~ .em-c-radio--checkmark {
  background-color: #fff;
  border: 1px #ad1723 solid;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.em-c-radio--checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.em-c-radio--container input:checked ~ .em-c-radio--checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.em-is-disabled .em-c-radio--container:has(input[type=radio]:checked) .em-c-radio--checkmark:after {
  top: 3px;
  left: 3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #76767e;
}

.em-has-error .em-c-radio--container:has(input[type=radio]:checked) .em-c-radio--checkmark:after {
  top: 3px;
  left: 3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ad1723;
}

.em-c-radio--container input[type=radio]:checked + .em-c-radio--checkmark:after {
  top: 3px;
  left: 3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #0c69b0;
}

.em-c-field:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 4px;
}

.em-c-option-list__item .em-c-radio--container input:focus,
.em-c-radio--container input[type=radio]:focus {
  outline: none;
  box-shadow: none;
  border: none;
}

.em-c-option-list__item .em-c-radio--container input:focus-visible,
.em-c-radio--container input[type=radio]:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 2px;
  box-shadow: none;
  border: none;
}

.em-has-error > .em-c-option-list__item:has(input[type=radio]:checked) {
  background: #fdded8;
  border-color: #ad1723;
}

.inclusive-dates__quick-group {
  display: none !important;
}

.inclusive-dates__label {
  display: none !important;
  margin-bottom: 0 !important;
}

.inclusive-dates__calendar-button {
  margin-left: 0 !important;
  text-indent: -99999px;
  content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><rect x="9" y="4" width="6" height="2" fill="currentColor"/><path d="M21,23H3a2,2,0,0,1-2-2V6A2,2,0,0,1,3,4H5V6H3V21H21V6H19V4h2a2,2,0,0,1,2,2V21A2,2,0,0,1,21,23Z" fill="currentColor"/><rect x="6" y="2" width="2" height="6" fill="currentColor"/><rect x="16" y="2" width="2" height="6" fill="currentColor"/><rect x="6" y="11" width="2" height="2" fill="currentColor"/><rect x="5" y="10" width="4" height="4" rx="1" ry="1" fill="currentColor"/><rect x="11" y="11" width="2" height="2" fill="currentColor"/><rect x="10" y="10" width="4" height="4" rx="1" ry="1" fill="currentColor"/><rect x="11" y="16" width="2" height="2" fill="currentColor"/><rect x="10" y="15" width="4" height="4" rx="1" ry="1" fill="currentColor"/><rect x="16" y="11" width="2" height="2" fill="currentColor"/><rect x="15" y="10" width="4" height="4" rx="1" ry="1" fill="currentColor"/><rect x="6" y="16" width="2" height="2" fill="currentColor"/><rect x="5" y="15" width="4" height="4" rx="1" ry="1" fill="currentColor"/></svg>') !important;
  border: transparent !important;
  border-radius: 4px !important;
  background-color: #fff !important;
  position: absolute !important;
  margin-left: 196px !important;
  width: 40px !important;
  height: calc(100% - 2px) !important;
  top: 1px !important;
}

.inclusive-dates__calendar-button:focus-visible {
  outline-width: 2px !important;
  outline-style: dashed !important;
  outline-color: #00a3e0 !important;
  outline-offset: 3px !important;
  box-shadow: none !important;
  z-index: 99px;
}

.inclusive-dates-calendar__date > * {
  color: #fff !important;
  border-radius: 0 !important;
}

.inclusive-dates__input {
  border-radius: 4px !important;
  border: 1px solid #cccccd !important;
  padding: 0.7rem !important;
  width: 240px !important;
  flex-grow: 0 !important;
  background-color: #fff !important;
  box-sizing: border-box !important;
  outline: none !important;
}

.inclusive-dates__input:focus-visible,
.inclusive-dates-calendar__previous-month-button:focus-visible,
.inclusive-dates-calendar__next-month-button:focus-visible,
.inclusive-dates-calendar__month-select:focus-visible,
.inclusive-dates-calendar__year-select:focus-visible,
.inclusive-dates-calendar__today-button:focus-visible,
.inclusive-dates-calendar__clear-button:focus-visible {
  outline-width: 2px !important;
  outline-style: dashed !important;
  outline-color: #00a3e0 !important;
  outline-offset: 3px !important;
  border: 1px solid #3190d9 !important;
  box-shadow: none !important;
  z-index: 99px;
}

.inclusive-dates-calendar__year-select,
.inclusive-dates-calendar__today-button,
.inclusive-dates-calendar__clear-button {
  background-color: #fff !important;
  border: 1px solid #cccccd !important;
  border-radius: 0 !important;
}

.inclusive-dates-calendar__month-select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="14.0982" height="8.6883" viewBox="0 0 14.0982 8.6883"><polygon points="7.0491 8.6883 0 1.6392 1.6392 0 7.0491 5.4099 12.459 0 14.0982 1.6392 7.0491 8.6883" fill="currentColor"/></svg>') !important;
  background-repeat: no-repeat !important;
  background-position-x: 95% !important;
  background-position-y: 0.72em !important;
  background-color: #fff !important;
  border: 1px solid #cccccd !important;
  border-radius: 0 !important;
}

.inclusive-dates__input-container {
  position: relative;
  display: inline-block;
}

.inclusive-dates-calendar,
.inclusive-dates {
  font-family: "EMprintRegular" !important;
  margin-top: -0.6rem;
}

.inclusive-dates-calendar__keyboard-hint {
  display: none !important;
}

.inclusive-dates-calendar__date--today > * {
  border-radius: 0 !important;
  border: solid 2px #cccccd;
  color: #0c69b0 !important;
  font-weight: 400 !important;
  box-shadow: none !important;
  font-style: unset !important;
}

.sc-inclusive-dates-calendar {
  color: #4d4d4d !important;
}

.inclusive-dates-calendar__date--selected > * {
  border-radius: 0 !important;
  background-color: #0c69b0 !important;
  color: #fff !important;
  opacity: 1 !important;
  box-shadow: none !important;
  outline: 0 !important;
  font-weight: 400 !important;
}

.inclusive-dates-calendar {
  border: 1px solid #cccccd !important;
  border-radius: 0 !important;
}

.inclusive-dates-calendar__date:hover > *,
.inclusive-dates-calendar__date--selected:hover > *,
.inclusive-dates-calendar__date--today:hover > * {
  color: #0c69b0 !important;
  border-radius: 0 !important;
  background-color: #e1efff !important;
}

.inclusive-dates-calendar__next-month-button {
  text-indent: -99999px !important;
  content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><polygon points="10 21.121 7.879 19 14.879 12 7.879 5 10 2.879 19.121 12 10 21.121" fill="currentColor"/></svg>') !important;
  background-color: #fff !important;
  border: 1px solid #cccccd !important;
  border-radius: 0 !important;
}

.inclusive-dates-calendar__previous-month-button {
  text-indent: -99999px !important;
  content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><polygon points="14 21.121 4.879 12 14 2.879 16.121 5 9.121 12 16.121 19 14 21.121" fill="currentColor"/></svg>') !important;
  background-color: #fff !important;
  border: 1px solid #cccccd !important;
  border-radius: 0 !important;
}

.inclusive-dates-calendar__weekday > span {
  font-weight: 400 !important;
}

.sc-inclusive-dates-calendar::before {
  font-weight: normal !important;
}

.inclusive-dates-calendar__footer {
  display: none !important;
}

.inclusive-dates-calendar__footer-buttons {
  display: none !important;
}

.inclusive-dates-calendar__date--in-range > * {
  font-weight: normal !important;
}

.inclusive-dates-calendar__date:focus-visible > * {
  border-radius: 0 !important;
  background-color: #e3e3e3 !important;
  color: #0c69b0 !important;
  opacity: 1 !important;
  box-shadow: none !important;
  outline-width: 2px !important;
  outline-style: dashed !important;
  outline-color: #00a3e0 !important;
  outline-offset: 2px !important;
  font-weight: 400 !important;
  z-index: 9999 !important;
}

.inclusive-dates-calendar__weekday-row .sc-inclusive-dates-calendar {
  color: #808080 !important;
}

.inclusive-dates-calendar__date--overflowing .sc-inclusive-dates-calendar {
  color: #a5a5a5 !important;
}

.inclusive-dates-calendar__date--overflowing .sc-inclusive-dates-calendar:hover {
  color: #0c69b0 !important;
}

em.sc-inclusive-dates-calendar {
  font-family: "EMprintRegular", "HelveticaNeue", "Helvetica", "Arial", sans-serif !important;
}

.em-input-range-width {
  width: 280px !important;
}

.em-calendar-button {
  margin-left: 238px !important;
}

.inclusive-dates-calendar__date--in-range > * {
  background-color: #f5f5f5 !important;
  color: #0c69b0 !important;
  border-radius: 0 !important;
}

.inclusive-dates-calendar__date--in-range:focus-visible > * {
  border-radius: 0 !important;
}

.inclusive-dates-calendar__date--in-range {
  padding: 0 !important;
}

td.inclusive-dates-calendar__date.sc-inclusive-dates-calendar.inclusive-dates-calendar__date--current.inclusive-dates-calendar__date--selected.inclusive-dates-calendar__date--in-range > *,
.em-date-picker-range > * {
  background-color: #0c69b0 !important;
  color: #fff !important;
}

/**
 * Buttons
 */
/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/
/**
 * 1) A button is an element that contains a call to action or directs you to another element or page
 * 2) Position set to relative for absolutely positioned gradient hover state to be positioned relative to button
 */
.em-c-btn {
  display: inline-flex; /* inline-block */
  position: relative; /* 2 */
  border: 1px solid #cccccd;
  border-radius: 4px;
  padding: 0 1.5rem;
  background: #fff;
  color: #0c69b0;
  z-index: 1;
  cursor: pointer;
  text-align: center;
  transition: all 0.15s ease;
  height: 44px;
  align-items: center;
  justify-content: center;
  /**
    * Default Hover/Focus styles
    */
  /**
    * Button within a button bar
    */
  /**
    * Button within a responsive button bar
    */
  /**
    * Cancel button
    * 1) Display none by default.
    */
}
.em-c-btn.em-is-active {
  background: #0c69b0;
  color: #fff;
}
.em-c-btn.em-is-active:hover, .em-c-btn.em-is-active:focus {
  color: #fff;
}
.em-c-btn:hover, .em-c-btn:focus {
  border-color: #0c69b0;
  color: #0c69b0;
  z-index: 2;
  outline: none;
}
.em-c-btn-group .em-c-btn {
  margin-right: 0.5rem;
  /**
    * Last button within button bar
    */
}
.em-c-btn-group .em-c-btn:last-child {
  margin-right: 0;
}
@media all and (max-width: 35em) {
  .em-c-btn-group--responsive .em-c-btn {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}
.em-c-btn.em-js-cancel {
  display: none;
  /**
    * Display block when editing table object
    */
}
.em-c-table-object.em-is-editing .em-c-btn.em-js-cancel {
  display: block;
}

/**
* Remove underline when btn is a link
*/
a.em-c-btn {
  text-decoration: none;
}

/**
 * Button Inner Wrapper
 * 1) For buttons containing icons, an inner wrapper div needs inserted in order
 *    for the icon and button text to align properly. Unfortunately applying display: flex
 *    to the button element itself is unsupported/buggy in multiple browsers, so an additional
 *    element is required.
 */
.em-c-btn__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  /**
    * Button inner within toolbar
    */
}
.em-c-toolbar .em-c-btn__inner {
  justify-content: flex-start;
}
@media all and (min-width: 47em) {
  .em-c-toolbar .em-c-btn__inner {
    justify-content: center;
  }
}

/**
 * Icon within button before button text
 * 1) Position set to relative to set icon beside the button text
 */
.em-c-btn__icon {
  fill: currentColor;
  color: inherit;
  position: relative;
  /**
    * Icon that appears by itself inside a button
    */
  /**
    * Icon that appears within a primary button
    */
  /**
    * Icon that appears within a primary button
    */
  /**
    * Icon that appears within a primary button
    */
  /**
    * Icon that appears within active button that expands content
    */
  /**
    * Icon that appears within active button that hides content
    */
}
.em-c-btn__icon.em-c-btn__icon-only {
  margin-right: 0;
  margin-top: 0;
}
.em-c-btn--primary .em-c-btn__icon, .em-c-text-passage a.em-c-btn--primary .em-c-btn__icon {
  fill: currentColor;
}
.em-c-btn--bar .em-c-btn__icon {
  fill: currentColor;
}
.em-c-btn--primary .em-c-btn__icon, .em-c-text-passage a.em-c-btn--primary .em-c-btn__icon {
  fill: currentColor;
}
.em-c-btn--is-expandable.em-is-active .em-c-btn__icon {
  transform: rotate(90deg);
}
.em-c-btn--is-expandable .em-c-btn__icon {
  transform: rotate(0deg);
}

/**
 * Icon within button before button text
 * 1) Position set to relative to set icon beside the button text
 */
.em-c-btn__icon + .em-c-btn__text {
  display: inline-block;
  margin-left: 0.5rem;
}

/**
 * Icon within button after button text
 * 1) Position set to relative to set icon beside the button text
 */
.em-c-btn__text + .em-c-btn__icon {
  display: inline-block;
  margin-left: 0.4rem;
  /**
    * Icon within toolbar
    */
}
.em-c-toolbar .em-c-btn__text + .em-c-btn__icon {
  margin-left: auto;
}
@media all and (min-width: 47em) {
  .em-c-toolbar .em-c-btn__text + .em-c-btn__icon {
    margin-left: 0.4rem;
  }
}
.em-c-toolbar .em-js-toolbar-trigger .em-c-btn__text + .em-c-btn__icon {
  margin-left: 0.4rem;
}

/*------------------------------------*\
    #BUTTON VARIANTS
\*------------------------------------*/
/**
 * Primary button
 * 1) Primary buttons are visually bold treatments that should be used for the
 *    primary action of a page (such as "Submit", "Save Changes", etc)
 * 2) Pseudo element added to allow for gradient hover effect
 * 3) This is a fix for the flickering weight in Safari
 */
.em-c-btn--primary, .em-c-text-passage a.em-c-btn--primary {
  background: #0c69b0;
  color: #fff;
  font-family: "EMprintRegular", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.01rem;
  border: 0;
  -webkit-font-smoothing: antialiased; /* 3 */
  transition: 0.5s ease;
  padding: 0.782em 1.5em;
  /**
  * 1) This pseudo element adds gradient hover effect
  * 2) Position set to absolute to blend the hover state into the relatively positioned button
  * 3) Opacity set to 0 until hovered over to animate gradient hover state
  */
}
.em-c-btn--primary:before, .em-c-text-passage a.em-c-btn--primary:before {
  content: "";
  display: block;
  height: 100%;
  position: absolute; /* 2 */
  top: 0;
  left: 0;
  opacity: 0; /* 3 */
  width: 100%;
  z-index: -1;
  border-radius: 4px;
}
.em-c-btn--primary:hover, .em-c-text-passage a.em-c-btn--primary:hover, .em-c-btn--primary:focus, .em-c-text-passage a.em-c-btn--primary:focus {
  color: #fff;
  /**
  * 1) Opacity set to 1 on hover to show gradient hover effect
  */
}
.em-c-btn--primary:hover:before, .em-c-btn--primary:focus:before {
  opacity: 1; /* 1 */
  transition: all 0.5s ease;
  background: #3190d9;
}
.em-c-btn--primary.em-c-btn--disabled, .em-c-text-passage a.em-c-btn--disabled.em-c-btn--primary {
  background: #e3e3e3;
  color: #a5a5a5;
}
.em-c-btn--primary.em-c-btn--disabled:hover, .em-c-btn--primary.em-c-btn--disabled:focus {
  background: #e3e3e3;
  color: #a5a5a5;
}
.em-c-btn--primary.em-c-btn--disabled:before {
  content: none;
}

/**
 * Secondary button
 */
.em-c-btn--secondary {
  border: 1px solid #cccccd;
  background: none;
  color: #0c69b0;
}
.em-c-btn--secondary:hover, .em-c-btn--secondary:focus {
  border-color: #0c69b0;
  color: #0c69b0;
}

/**
 * tertiary button
 */
.em-c-btn--tertiary {
  border: 0;
  background: none;
  color: #0c69b0;
}
.em-c-btn--tertiary:hover, .em-c-btn--tertiary:focus {
  border: 0;
  color: #021834;
}

/**
 * Reversed button
 * 1) To be used with btn--secondary buttons on dark backgrounds
 */
.em-c-btn--reversed {
  background: rgba(50, 50, 50, 0.4);
  border: 0;
}
.em-c-hero .em-c-btn--reversed {
  color: #fff;
  background: rgba(50, 50, 50, 0.4);
  border-color: rgba(50, 50, 50, 0.4);
}
.em-c-hero .em-c-btn--reversed:hover, .em-c-hero .em-c-btn--reversed:focus {
  background: #f5f5f5;
  color: #3a397b;
  border-color: #3a397b;
}
.em-c-promo-block .em-c-btn--reversed {
  color: #fff;
}
.em-c-promo-block .em-c-btn--reversed:hover, .em-c-promo-block .em-c-btn--reversed:focus {
  background: #f5f5f5;
  color: #343434;
  border-color: #3a397b;
}

/**
 * Button dropdown
 */
.em-c-btn--is-dropdown {
  /**
    * Button dropdown within toolbar
    */
}
.em-c-toolbar .em-c-btn--is-dropdown {
  width: 100%;
  text-align: left;
}
@media all and (min-width: 47em) {
  .em-c-toolbar .em-c-btn--is-dropdown {
    width: inherit;
    text-align: center;
  }
}

/**
 * Tiny button
 */
.em-c-btn--tiny {
  padding: 0 0.6rem;
}

/**
 * Small button
 */
.em-c-btn--small {
  height: 34px;
  font-size: 0.875rem;
  padding: 0.375rem 0.75rem;
}

/**
 * Large Button
 */
.em-c-btn--large {
  height: 54px;
}

/**
 * Inverted Button
 * 1) Inverted button has white background with knocked out text
 * 2) Meant to be used over solid saturated colors, such as a blue or red header
 */
.em-c-btn--inverted {
  border: 1px solid #fff;
  background: #fff;
  color: #0c69b0;
  /**
  * Inverted Button within default red theme
  */
  /**
  * Inverted Button within blue theme
  */
}
.em-c-btn--inverted:hover, .em-c-btn--inverted:focus {
  border-color: #111122;
}
.em-c-btn--inverted:hover, .em-c-btn--inverted:focus, .em-c-btn--inverted.em-is-active {
  color: #fff;
  background: #0c69b0;
}
.em-c-btn--inverted.em-c-btn--disabled {
  background: #e3e3e3;
  color: #a5a5a5;
}
.em-c-btn--inverted.em-c-btn--disabled:hover, .em-c-btn--inverted.em-c-btn--disabled:focus {
  background: #e3e3e3;
  color: #a5a5a5;
}
.em-c-btn--inverted.em-c-btn--disabled:before {
  content: none;
}
.em-c-header .em-c-btn--inverted {
  color: #ad1723;
}
.em-c-header .em-c-btn--inverted:hover, .em-c-header .em-c-btn--inverted:focus {
  border-color: #ad1723;
}
.em-c-header .em-c-btn--inverted:hover, .em-c-header .em-c-btn--inverted:focus, .em-c-header .em-c-btn--inverted.em-is-active {
  color: #fff;
  background: #ad1723;
}
.em-theme--blue .em-c-btn--inverted, .em-c-header--theme-blue .em-c-btn--inverted, .em-c-header--blue .em-c-btn--inverted {
  color: #0c69b0;
}
.em-theme--blue .em-c-btn--inverted:hover, .em-theme--blue .em-c-btn--inverted:focus, .em-c-header--theme-blue .em-c-btn--inverted:hover, .em-c-header--theme-blue .em-c-btn--inverted:focus, .em-c-header--blue .em-c-btn--inverted:hover, .em-c-header--blue .em-c-btn--inverted:focus {
  border-color: #3a397b;
}
.em-theme--blue .em-c-btn--inverted:hover, .em-theme--blue .em-c-btn--inverted:focus, .em-theme--blue .em-c-btn--inverted.em-is-active, .em-c-header--theme-blue .em-c-btn--inverted:hover, .em-c-header--theme-blue .em-c-btn--inverted:focus, .em-c-header--theme-blue .em-c-btn--inverted.em-is-active, .em-c-header--blue .em-c-btn--inverted:hover, .em-c-header--blue .em-c-btn--inverted:focus, .em-c-header--blue .em-c-btn--inverted.em-is-active {
  border-color: #fff;
  color: #fff;
  background: #3a397b;
}

/**
 * Inverted Blue Button
 * 1) Variant of the inverted button with blue text
 */
.em-c-btn--inverted-red {
  border: 1px solid #fff;
  background: #fff;
  color: #ad1723;
}
.em-c-btn--inverted-red:hover, .em-c-btn--inverted-red:focus {
  border-color: #ad1723;
}
.em-c-btn--inverted-red:hover, .em-c-btn--inverted-red:focus, .em-c-btn--inverted-red.em-is-active {
  color: #fff;
  background: #ad1723;
}
.em-c-btn--inverted-red.em-c-btn--disabled {
  background: #e3e3e3;
  color: #a5a5a5;
}
.em-c-btn--inverted-red.em-c-btn--disabled:hover, .em-c-btn--inverted-red.em-c-btn--disabled:focus {
  background: #e3e3e3;
  color: #a5a5a5;
}
.em-c-btn--inverted-red.em-c-btn--disabled:before {
  content: none;
}

/**
 * Bare button
 * 1) No background, border, or anything
 */
.em-c-btn--bare {
  border: 0;
  background: none;
  /**
  * Bare button that is active
  */
  /**
    * Icon that appears within a button in the header
    */
  /**
    * Search Icon that appears within a button in a vertical header
    */
}
.em-c-btn--bare:hover, .em-c-btn--bare:focus {
  background: rgba(0, 0, 0, 0.1);
}
.em-c-section:hover .em-c-btn--bare,
.em-c-btn--bare .em-c-section:focus {
  background: #e5e5e5;
}
.em-c-btn--bare.em-is-active {
  background: #3a397b;
  /**
    * Bare button that is active and within the red header
    */
  /**
    * ...except for blue headings
    */
}
.em-c-header .em-c-btn--bare.em-is-active {
  background: #ad1723;
}
.em-c-header--blue .em-c-btn--bare.em-is-active {
  background: #3a397b;
}
.em-c-header .em-c-btn--bare {
  display: flex;
  justify-content: center;
  width: 51px;
  height: 51px;
  padding: 1rem;
  color: #fff;
  position: relative;
  outline: 0;
}
.em-c-header--vertical .em-c-btn--bare {
  width: auto;
  height: auto;
  padding: 0.5rem 0.75rem;
  color: #fff;
  position: relative;
  top: 0;
  outline: 0;
  margin-left: auto;
  display: none;
}
@media all and (min-width: 62em) {
  .em-c-header--vertical .em-c-btn--bare {
    display: inline-block;
  }
}

/**
 * Dropdown button
 * 1) Button that acts as a dropdown trigger
 */
.em-c-btn--is-dropdown {
  display: block;
  z-index: 0;
  outline: 0;
  /**
  * Dropdown button within active dropdown check, active dropdown, or active dropdown radio
  */
}
.em-c-dropdown-check.em-is-active .em-c-btn--is-dropdown, .em-c-dropdown.em-is-active .em-c-btn--is-dropdown, .em-c-dropdown-radio.em-is-active .em-c-btn--is-dropdown {
  background: #fbfbfa;
  color: inherit;
  outline: 0;
  border: 1px solid #0c69b0;
  border-bottom: 1px solid #fbfbfa;
  z-index: 3;
  border-radius: 4px 4px 0 0;
}

/**
 * Button that contains toolbar
 1) Disappears at breakpoint when all items within toolbar are visible
 */
.em-c-btn--toolbar {
  transition: color 0.15s ease, border 0.15s ease;
  /**
  * Toolbar button witin active collapsible toolbar
  */
}
.em-c-collapsible-toolbar.em-is-active .em-c-btn--toolbar {
  background: #f5f5f5;
  color: inherit;
  border-bottom: 0;
  z-index: 3;
}
@media all and (min-width: 47em) {
  .em-c-btn--toolbar {
    display: none; /* 1 */
  }
}

/**
 * Disabled Button
 */
.em-c-btn--disabled {
  border-color: #e3e3e3;
  color: #a5a5a5;
  cursor: not-allowed;
}
.em-c-btn--disabled:hover, .em-c-btn--disabled:focus {
  border-color: #e3e3e3;
  color: #a5a5a5;
}

/*------------------------------------*\
    #TEXT BUTTON
\*------------------------------------*/
/**
 * 1) Button that is text only and doesn't contain a border or fill.
 */
.em-c-text-btn {
  color: #0c69b0;
  background: none;
  border: 0;
  padding: 0;
  text-decoration: underline;
  font-size: 0.875rem;
  transition: all 0.15s ease;
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
}
.em-c-text-btn:hover, .em-c-text-btn:focus {
  color: #3a397b;
  text-decoration: none;
}

/*------------------------------------*\
    #FOCUS VISIBLE
\*------------------------------------*/
/**
 * 1) Cyan dashed outline for keyboard navigation.
 */
.em-c-header__title-logo:focus-visible,
.em-c-btn--bare:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #fff;
  outline-offset: 4px;
  transition: none;
}

.em-c-search__body .em-c-search__input:focus-visible,
.em-c-btn--primary:focus-visible::before {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 3px;
  transition: none;
}

.em-c-solid-nav__link:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 3px;
  display: inline-block;
}

.em-c-tree__link:focus-visible > span {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 3px;
}

.em-c-header__title-link:focus-visible {
  background: none;
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #fff;
  outline-offset: 4px;
}

.em-c-header .em-c-primary-nav__link:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #fff;
  outline-offset: 2px;
  opacity: 1;
}

.em-c-primary-nav__sublink:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 3px;
}

.em-c-btn--inverted:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #fff;
  outline-offset: 4px;
}

.em-c-btn:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 3px;
  transition: none;
}

.em-c-icon-text-visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.em-c-text-btn:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 2px;
  transition: none;
}

.em-c-expandable-button {
  overflow: inherit !important;
}

/*------------------------------------*\
    #BUTTON BAR
\*------------------------------------*/
/**
 * 1) Bar containing multiple buttons
 */
/**
 * Button bar list
 */
.em-c-btn-bar__list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /**
  * Button bar list within no wrap button bar
  * 1) Keeps button bar in a row at all widths
  */
}
@media all and (min-width: 35em) {
  .em-c-btn-bar__list {
    flex-direction: row;
  }
}
.em-c-btn-bar--no-wrap .em-c-btn-bar__list {
  flex-direction: row;
  flex-wrap: nowrap;
}

/**
 * Button bar list item
 * 1) Negative margin prevents a doubled up border
 */
.em-c-btn-bar__item {
  margin-right: -1px; /* 1 */
  /**
  * first button within button bar
  */
  /**
  * Last button within button bar
  */
  /**
  * All buttons between the first and last item
  */
  /**
  *
  * DEPRECATED
  *
  * Button bar separator
  * 1) Serves to separate buttons in a group
  */
}
.em-c-btn-bar__item .em-c-btn {
  width: -webkit-fill-available;
}
.em-c-btn-bar__item:first-of-type .em-c-btn {
  border-radius: 4px 0 0 4px;
}
@media all and (max-width: 35em) {
  .em-c-btn-bar__item:first-of-type .em-c-btn {
    border-radius: 4px 4px 0 0;
    border-bottom: none;
  }
  .em-c-btn-bar__item:first-of-type .em-c-btn:hover, .em-c-btn-bar__item:first-of-type .em-c-btn:focus {
    border-bottom: 1px solid #0c69b0;
  }
}
.em-c-btn-bar__item:last-of-type .em-c-btn {
  border-radius: 0 4px 4px 0;
}
@media all and (max-width: 35em) {
  .em-c-btn-bar__item:last-of-type .em-c-btn {
    border-radius: 0 0 4px 4px;
    border-top: none;
  }
  .em-c-btn-bar__item:last-of-type .em-c-btn:hover, .em-c-btn-bar__item:last-of-type .em-c-btn:focus {
    border-top: 1px solid #0c69b0;
  }
}
.em-c-btn-bar__item:not(:first-of-type):not(:last-of-type) .em-c-btn {
  border-radius: 0;
}
.em-c-btn-bar__item--separator {
  margin-right: 1rem;
}

/*------------------------------------*\
    #BUTTON GROUP
\*------------------------------------*/
/**
 * 1) A button group displays buttons side-by-side.
 * 2) It's typically a primary and secondary button presented as a group
 */
.em-c-btn-group {
  display: flex;
  flex-wrap: nowrap;
  /**
  * Button group inside a hero
  */
  /**
  * Button group inside a modal
  */
}
.em-c-btn-group--responsive {
  flex-direction: column;
}
@media all and (min-width: 30em) {
  .em-c-btn-group--responsive {
    flex-direction: row;
  }
}
.em-c-hero .em-c-btn-group {
  margin-top: 2rem;
}
@media all and (min-width: 35em) {
  .em-c-hero .em-c-btn-group {
    margin-top: 4rem;
  }
}
.em-c-modal .em-c-btn-group {
  padding-bottom: 0.5rem;
  justify-content: end;
}

/*------------------------------------*\
    #TAGS
\*------------------------------------*/
/**
 * 1) Terms or phrases that help filter data
 * 2) Display flex used to display items in row and flex wrap wraps items to next line if not
 *    enough space
 */
.em-c-tags {
  display: flex; /* 2 */
  flex-wrap: wrap; /* 2 */
  margin: 0 -0.35rem -0.35rem 0;
  /**
   * Light tags
   * 1) Lighter in color
   */
  /**
   * Tags within a stacked block
   */
  /**
   * Tags within toolbar
   */
}
@media all and (min-width: 40em) {
  .em-c-tags {
    margin-left: 0;
  }
}
.em-c-tags--light {
  margin: 0;
}
.em-c-stacked-block .em-c-tags {
  margin-top: 0.5rem;
}
@media all and (min-width: 47em) {
  .em-c-toolbar .em-c-tags {
    margin: -0.35rem -0.35rem -0.85rem -0.35rem;
  }
}

/**
 * Table of contents title
 */
.em-c-tags__item {
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  /**
  * Last tags item
  */
}
.em-c-tags__item:last-child {
  margin-right: 0;
}

/**
 * Tags link
 */
.em-c-tags__link {
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
  display: flex;
  align-items: center;
  padding: 0.25rem 0.625rem;
  background: #111122;
  border-radius: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.625rem;
  text-decoration: none;
  color: #fff;
  transition: background 0.15s ease;
  /**
  * Tags link within light tags
  */
  /**
  * Closable tags link
  * 1) Margin and padding added to space out closing icon and text
  */
  /**
  * Tag link within table
  */
}
.em-c-tags--light .em-c-tags__link {
  font-family: "EMprintBold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #343434;
  background: #cccccd;
}
.em-c-tags--light .em-c-tags__link:hover, .em-c-tags--light .em-c-tags__link:focus {
  background: #bbbbbd;
}
.em-c-tags--closing .em-c-tags__link {
  padding: 0.4rem 0.9rem;
  margin-right: 0.5rem;
}
.em-c-table .em-c-tags__link {
  text-decoration: none;
}
.em-c-tags__link:hover, .em-c-tags__link:focus {
  background: #545459;
}

/**
 * Tags icon
 */
.em-c-tags__icon {
  margin-right: 0.5rem;
  fill: #808080;
  color: #808080;
  /**
    * Tags icon within tags mandatory
    * 1) Used on News Portal page to distinguish mandatory articles
    */
}
.em-c-tags--mandatory .em-c-tags__icon {
  color: #b12a0b;
  fill: #b12a0b;
}

/**
 * Tags icon inside
 * 1) Icon located inside tags
 */
.em-c-tags__icon-inside {
  height: 0.8rem;
  width: 0.8rem;
  color: #808080;
  margin-left: 1rem;
}

/**
 * Tags visible focus for accessibility
 */
.em-c-tags__item > a:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 3px;
  box-shadow: none;
  transition: none;
}

/**
 * Lists
 */
/*------------------------------------*\
    #BULLETED LIST
\*------------------------------------*/
/**
 * 1) List organized with bullet points
 */
.em-c-bulleted-list {
  list-style: disc;
  margin-left: 1.1em;
}

.em-c-bulleted-list__item {
  line-height: 2.2em;
}

/*------------------------------------*\
    #DEFINITION LIST
\*------------------------------------*/
/**
 * 1) Represents the navigation within the footer
 */
.em-c-definition-list {
  display: table;
  /**
  * Responsive definition list
  * 1) This definition list moves from a stacked configuration to a side-by-side
  *    configuration on larger viewports
  */
  /**
  * Small definition list
  * 1) Sets font-size smaller
  */
}
.em-c-definition-list--responsive {
  display: block;
  position: relative;
}
@media all and (min-width: 47em) {
  .em-c-definition-list--responsive {
    display: table;
  }
}
.em-c-definition-list--small {
  font-size: 0.75rem; /* 1 */
}

/**
 * Definition list item
 * 1) Set to display flex at larger screens so content within definition list item sits side by side
 */
.em-c-definition-list__item {
  display: table-row;
  margin-bottom: 0.5rem;
  /**
  * Definition list item within responsive definition list
  */
  /**
  * Definition list item within vertical definition list
  */
}
.em-c-definition-list--responsive .em-c-definition-list__item {
  display: block;
  margin-bottom: 2rem;
}
@media all and (min-width: 47em) {
  .em-c-definition-list--responsive .em-c-definition-list__item {
    display: table-row; /* 1 */
  }
}
.em-c-definition-list--vertical .em-c-definition-list__item {
  display: block;
  margin-bottom: 1rem;
}
.em-c-definition-list--vertical .em-c-definition-list__item dd {
  margin-left: 0;
}

/**
 * Definition list key
 */
.em-c-definition-list__key {
  display: table-cell;
  margin-right: 1rem;
  white-space: nowrap;
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
  /**
  * Definition list key within responsive definition list
  */
  /**
  * Definition list key within vertical definition list
  */
}
.em-c-definition-list--responsive .em-c-definition-list__key {
  display: block;
  margin-right: 0;
}
@media all and (min-width: 47em) {
  .em-c-definition-list--responsive .em-c-definition-list__key {
    display: table-cell;
  }
}
.em-c-definition-list--vertical .em-c-definition-list__key {
  display: block;
}

/**
 * Definition list value
 */
.em-c-definition-list__value {
  display: table-cell;
  padding: 0.5rem 2rem;
  line-height: 1.6;
  /**
  * Link within definition list value
  */
  /**
  * Definition list value within responsive definition list
  */
  /**
  * Definition list value within vertical definition list
  */
}
.em-c-definition-list__value a {
  color: #0c69b0;
  text-decoration: underline;
  transition: color 0.15s ease;
}
.em-c-definition-list--responsive .em-c-definition-list__value {
  display: block;
  padding: 0;
  width: 100%;
}
@media all and (min-width: 47em) {
  .em-c-definition-list--responsive .em-c-definition-list__value {
    display: table-cell;
    padding: 1rem 2rem;
  }
}
.em-c-definition-list--vertical .em-c-definition-list__value {
  display: block;
  padding: 0.5rem 0;
}

/*------------------------------------*\
    #LINK LIST
\*------------------------------------*/
/**
 * 1) List of links
 */
.em-c-link-list {
  /**
  * Bulleted link list
  */
  /**
  * Small variant
  */
  /**
   * Link list within a solid card
   */
}
.em-c-link-list--bulleted {
  list-style: disc;
  margin-left: 1.1rem;
}
.em-c-link-list--small {
  font-size: 0.75rem;
}
.em-c-solid-card .em-c-link-list {
  text-decoration: underline;
}

/**
 * 1) Link list items
 */
.em-c-link-list__item {
  margin-bottom: 1rem;
  /**
  * Link list item within small link list
  */
}
.em-c-link-list--small .em-c-link-list__item {
  margin-bottom: 0.75rem;
}

/**
 * Link within link list
 */
.em-c-link-list__link {
  color: #0c69b0;
  transition: color 0.15s ease;
  /**
    * Theming
    * 1) Apply theme color when theme class is added to the
    *    `body` (i.e. `<body class="em-theme--blue">`) or if a
    *    modifier is added to the parent block
    */
  /**
  * Link within link list
  * 1) Set to display flex so icons and text display side-by-side and text wraps appropriately
  */
  /**
     * Links that appear in a solid card
     * 1) Match the color of the surrounding component
     */
  /**
  * Link list link within small link list
  */
  /**
  * Link list link within red link list
  */
}
.em-theme--blue .em-c-link-list__link, .em-c-link-list--theme-blue .em-c-link-list__link {
  color: #0c69b0; /* 1 */
}
.em-theme--blue .em-c-link-list__link:hover, .em-theme--blue .em-c-link-list__link:focus, .em-c-link-list--theme-blue .em-c-link-list__link:hover, .em-c-link-list--theme-blue .em-c-link-list__link:focus {
  color: #3a397b;
}
.em-theme--red .em-c-link-list__link, .em-c-link-list--theme-red .em-c-link-list__link {
  color: #0c69b0; /* 1 */
}
.em-theme--red .em-c-link-list__link:hover, .em-theme--red .em-c-link-list__link:focus, .em-c-link-list--theme-red .em-c-link-list__link:hover, .em-c-link-list--theme-red .em-c-link-list__link:focus {
  color: #111122;
}
.em-c-link-list__link:hover {
  color: #104e8f;
}
.em-c-link-list__link:focus {
  outline: 1px dotted #0c69b0;
}
.em-c-link-list__link:active {
  background: none;
}
.em-c-link-list--has-icons .em-c-link-list__link {
  display: flex; /* 1 */
}
.em-c-solid-card .em-c-link-list__link {
  color: inherit;
}
.em-c-solid-card .em-c-link-list__link:hover {
  text-decoration: none;
}
.em-c-solid-card .em-c-link-list__link:focus {
  outline-color: inherit;
}
.em-c-solid-card .em-c-link-list__link:active {
  background: none;
}
.em-c-link-list--small .em-c-link-list__link {
  text-decoration: underline;
}
.em-c-link-list--red .em-c-link-list__link {
  color: #d82424;
}

/**
 * Link list icon
 */
.em-c-link-list__icon {
  margin-right: 0.5rem;
  fill: #808080;
  color: #808080;
  /**
  * Link list icon within solid card
  */
}
.em-c-solid-card .em-c-link-list__icon {
  color: #808080;
  fill: #808080;
}
.em-c-solid-card--blue .em-c-link-list__icon {
  color: #00a3e0;
  fill: #00a3e0;
}

/**
 * Link list text
 */
.em-c-link-list__text {
  /**
  * Link list text within small link list
  */
}
.em-c-link-list--small .em-c-link-list__text {
  text-decoration: underline;
}

/*------------------------------------*\
    #LINK LIST BLOCK
\*------------------------------------*/
/**
 * 1) Block that contains a link list, heading for link list, and potentially other elements
 */
.em-c-link-list-block {
  margin-bottom: 2rem;
  /**
  * Link list block within link list flex;
  */
}
.em-c-link-list-block--flex {
  flex: 1 0 auto;
}

/**
 * Link list block heading
 */
.em-c-link-list-block__heading {
  margin-bottom: 1rem;
}

/*------------------------------------*\
    #CARD LIST
\*------------------------------------*/
/**
 * 1) A list of cards
 */
.em-c-card-list {
  margin-bottom: 2rem;
}

/**
 * Card list item
 */
.em-c-card-list__item {
  /**
  * Card list item within a card list that has a flag
  */
}
.em-c-card-list--has-flag .em-c-card-list__item {
  margin-bottom: 1em;
}

/*------------------------------------*\
    #CONTACT CARD LIST
\*------------------------------------*/
/**
 * 1) A list of cards
 */
.em-c-contact-card-list {
  margin-bottom: 2rem;
}

/**
 * Card list item
 */
.em-c-contact-card-list__item {
  /**
  * Contact card list item within contact card list
  */
}
.em-c-contact-card-list--has-flag .em-c-contact-card-list__item {
  margin-bottom: 1em;
}

/*------------------------------------*\
    #HORIZONTAL LIST
\*------------------------------------*/
/**
 * 1) Horizontal list
 */
.em-c-horizontal-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-end;
}

/**
 * 1) Horizontal list items
 */
.em-c-horizontal-list__item {
  margin-bottom: 1rem;
  margin-right: 2rem;
}
.em-c-horizontal-list__item > a {
  color: #0c69b0;
  text-decoration: underline;
  transition: color 0.15s ease;
  /**
    * Theming
    * 1) Apply theme color when theme class is added to the
    *    `body` (i.e. `<body class="em-theme--blue">`) or if a
    *    modifier is added to the parent block
    */
}
.em-theme--blue .em-c-horizontal-list__item > a, .em-c-horizontal-list--theme-blue .em-c-horizontal-list__item > a {
  color: #0c69b0;
}
.em-theme--blue .em-c-horizontal-list__item > a:hover, .em-theme--blue .em-c-horizontal-list__item > a:focus, .em-c-horizontal-list--theme-blue .em-c-horizontal-list__item > a:hover, .em-c-horizontal-list--theme-blue .em-c-horizontal-list__item > a:focus {
  color: #3a397b;
}
.em-theme--red .em-c-horizontal-list__item > a, .em-c-horizontal-list--theme-red .em-c-horizontal-list__item > a {
  color: #0c69b0;
}
.em-theme--red .em-c-horizontal-list__item > a:hover, .em-theme--red .em-c-horizontal-list__item > a:focus, .em-c-horizontal-list--theme-red .em-c-horizontal-list__item > a:hover, .em-c-horizontal-list--theme-red .em-c-horizontal-list__item > a:focus {
  color: #111122;
}
.em-c-horizontal-list__item > a:hover {
  color: #104e8f;
}
.em-c-horizontal-list__item > a:focus {
  outline: 1px dotted #0c69b0;
}
.em-c-horizontal-list__item > a:active {
  background: none;
}
.em-c-horizontal-list__item:last-child {
  margin-right: 0;
}

/**
 * Messaging
 */
/*------------------------------------*\
    #ALERT
\*------------------------------------*/
/**
 * 1) A block containing a message to the user
 */
.em-c-alert {
  display: flex;
  padding: 1em;
  margin-bottom: 2rem;
  background: #d3f2ff;
  border: 1px solid #0c69b0;
  color: #0c69b0;
  align-items: center;
  /**
  * Alert error status
  */
  /**
  * Alert success status
  */
  /**
  * Alert warning status
  */
  /**
  * Alert accessibility status
  */
}
.em-c-alert--error {
  background: #fdded8;
  border-color: #b12a0b;
  color: #b12a0b;
}
.em-c-alert--success {
  background: #d4f3e6;
  border-color: #007c38;
  color: #007c38;
}
.em-c-alert--warning {
  background: #fffecf;
  border-color: #996c1c;
  color: #996c1c;
}
.em-c-alert--a11y {
  background: #ebe1f2;
  border-color: #3a397b;
  color: #3a397b;
}

/**
 * 1) Alert Icon
 */
.em-c-alert__icon {
  margin-right: 1.5rem;
}

/**
 * 1) Alert Body
 */
.em-c-alert__body {
  flex: 1;
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 0.875rem;
  /**
  * Link inside of alert body
  */
  /**
  * Last paragraph item within alert body
  */
}
.em-c-alert__body a {
  color: inherit;
  text-decoration: underline;
}
.em-c-alert__body p:last-child {
  margin-bottom: 0;
}

/**
 * 1) Alert Actions
 */
.em-c-alert__actions {
  margin-left: auto;
  padding-left: 1.5rem;
}

/*------------------------------------*\
    #BADGE
\*------------------------------------*/
/**
 * 1) A circular label, usually used in notifications
 */
.em-c-badge {
  display: inline-block;
  padding: 0.5rem 0.5rem 0.375rem;
  font-size: 0.675rem;
  font-family: "EMprintBold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
  line-height: 1;
  color: #343434;
  text-align: center;
  text-transform: uppercase;
  background: #0c69b0;
  color: #fff;
  /**
  * Positive Badge
  */
  /**
  * Negative Badge
  */
  /**
  * Caution Badge
  */
  /**
  * Description badge within primary nav item
  */
  /**
  * Badges in page header
  */
  /**
  * Badges in blue text table
  */
}
.em-c-badge--positive {
  background: #007c38;
  color: #fff;
}
.em-c-badge--negative {
  background: #b12a0b;
  color: #fff;
}
.em-c-badge--caution {
  background: #ffd700;
  color: #111122;
}
.em-c-primary-nav__item .em-c-badge {
  padding: 0.3rem;
  margin-left: 0.7rem;
  background: #fff;
  color: #d82424;
  color: #d82424;
  /**
  * ...except for badges that appear in blue headers
  */
}
.em-c-header--blue .em-c-primary-nav__item .em-c-badge, .em-c-header--theme-blue .em-c-primary-nav__item .em-c-badge, .em-theme--blue .em-c-primary-nav__item .em-c-badge {
  color: #0c69b0;
}
.em-c-page-header .em-c-badge {
  margin-left: 0.5rem;
  position: relative;
  top: -0.6rem;
}
@media all and (max-width: 47em) {
  .em-c-page-header .em-c-badge {
    top: -0.4rem;
  }
}
.em-c-table--blue-text .em-c-badge {
  margin-left: 0.5rem;
}

/*------------------------------------*\
    #Progress bar
\*------------------------------------*/
.em-c-progress-bar__label {
  display: inline-flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
}
.em-c-progress-bar__label label {
  font-family: "EMprintRegular", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-style: normal;
}
.em-c-progress-bar__bar {
  background-color: #e5e5e5;
  border-radius: 4px;
  height: 8px;
  width: 100%;
  box-sizing: border-box;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}
.em-c-progress-bar__value {
  background-color: #0c69b0;
  transition: 0.3s all linear;
  border-radius: 4px;
  height: 8px;
}

div.em-c-progress-bar__bar:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 4px;
}

.onlyScreenreader {
  display: none;
}

/*------------------------------------*\
    #Progress bar variants
\*------------------------------------*/
.em-c-progress-bar--thin .em-c-progress-bar__bar {
  border-radius: 2px;
  height: 4px;
}
.em-c-progress-bar--thin .em-c-progress-bar__value {
  border-radius: 2px;
  height: 4px;
}

.em-c-progress-bar--indeterminate__value {
  width: 97%;
  margin: 1px 0 0 1px;
  height: 6px;
  border-radius: 3px;
  background-color: #0c69b0;
  animation: em-a-progress-bar-indeterminate 1s infinite linear;
  transform-origin: 0% 50%;
}

.em-c-progress-bar--thin .em-c-progress-bar--indeterminate__value {
  margin: 0 0 0 1px;
  height: 4px;
  border-radius: 2px;
}

/*------------------------------------*\
    #Progress bar animation
\*------------------------------------*/
@keyframes em-a-progress-bar {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@-webkit-keyframes em-a-progress-bar {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes em-a-progress-bar-indeterminate {
  0% {
    transform: translateX(0) scaleX(0);
  }
  40% {
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    transform: translateX(100%) scaleX(0.5);
  }
}
@-webkit-keyframes em-a-progress-bar-indeterminate {
  0% {
    -webkit-transform: -webkit-translate(0) scaleX(0);
  }
  40% {
    -webkit-transform: -webkit-translate(0) scaleX(0.4);
  }
  100% {
    -webkit-transform: -webkit-translate(100%) scaleX(0.5);
  }
}
.em-a-animate-progress-05s {
  animation: em-a-progress-bar 0.5s ease-in-out forwards;
  -webkit-animation: em-a-progress-bar 0.5s ease-in-out forwards;
}

.em-a-animate-progress-1s {
  animation: em-a-progress-bar 1s ease-in-out forwards;
  -webkit-animation: em-a-progress-bar 1s ease-in-out forwards;
}

.em-a-animate-progress-2s {
  animation: em-a-progress-bar 2s ease-in-out forwards;
  -webkit-animation: em-a-progress-bar 2s ease-in-out forwards;
}

.em-a-animate-progress-3s {
  animation: em-a-progress-bar 3s ease-in-out forwards;
  -webkit-animation: em-a-progress-bar 3s ease-in-out forwards;
}

.em-a-animate-progress-5s {
  animation: em-a-progress-bar 5s ease-in-out forwards;
  -webkit-animation: em-a-progress-bar 5s ease-in-out forwards;
}

.em-a-animate-progress-percent-05s:before {
  animation: em-a-progress-percentage-indicator 0.5s forwards;
  -webkit-animation: em-a-progress-percentage-indicator 0.5s forwards;
  content: " ";
}

.em-a-animate-progress-percent-1s:before {
  animation: em-a-progress-percentage-indicator 1s forwards;
  -webkit-animation: em-a-progress-percentage-indicator 1s forwards;
  content: " ";
}

.em-a-animate-progress-percent-2s:before {
  animation: em-a-progress-percentage-indicator 2s forwards;
  -webkit-animation: em-a-progress-percentage-indicator 2s forwards;
  content: " ";
}

.em-a-animate-progress-percent-3s:before {
  animation: em-a-progress-percentage-indicator 3s forwards;
  -webkit-animation: em-a-progress-percentage-indicator 3s forwards;
  content: " ";
}

.em-a-animate-progress-percent-5s:before {
  animation: em-a-progress-percentage-indicator 5s forwards;
  -webkit-animation: em-a-progress-percentage-indicator 5s forwards;
  content: " ";
}

@keyframes em-a-progress-percentage-indicator {
  1% {
    content: "1%";
  }
  2% {
    content: "2%";
  }
  3% {
    content: "3%";
  }
  4% {
    content: "4%";
  }
  5% {
    content: "5%";
  }
  6% {
    content: "6%";
  }
  7% {
    content: "7%";
  }
  8% {
    content: "8%";
  }
  9% {
    content: "9%";
  }
  10% {
    content: "10%";
  }
  11% {
    content: "11%";
  }
  12% {
    content: "12%";
  }
  13% {
    content: "13%";
  }
  14% {
    content: "14%";
  }
  15% {
    content: "15%";
  }
  16% {
    content: "16%";
  }
  17% {
    content: "17%";
  }
  18% {
    content: "18%";
  }
  19% {
    content: "19%";
  }
  20% {
    content: "20%";
  }
  21% {
    content: "21%";
  }
  22% {
    content: "22%";
  }
  23% {
    content: "23%";
  }
  24% {
    content: "24%";
  }
  25% {
    content: "25%";
  }
  26% {
    content: "26%";
  }
  27% {
    content: "27%";
  }
  28% {
    content: "28%";
  }
  29% {
    content: "29%";
  }
  30% {
    content: "30%";
  }
  31% {
    content: "31%";
  }
  32% {
    content: "32%";
  }
  33% {
    content: "33%";
  }
  34% {
    content: "34%";
  }
  35% {
    content: "35%";
  }
  36% {
    content: "36%";
  }
  37% {
    content: "37%";
  }
  38% {
    content: "38%";
  }
  39% {
    content: "39%";
  }
  40% {
    content: "40%";
  }
  41% {
    content: "41%";
  }
  42% {
    content: "42%";
  }
  43% {
    content: "43%";
  }
  44% {
    content: "44%";
  }
  45% {
    content: "45%";
  }
  46% {
    content: "46%";
  }
  47% {
    content: "47%";
  }
  48% {
    content: "48%";
  }
  49% {
    content: "49%";
  }
  50% {
    content: "50%";
  }
  51% {
    content: "51%";
  }
  52% {
    content: "52%";
  }
  53% {
    content: "53%";
  }
  54% {
    content: "54%";
  }
  55% {
    content: "55%";
  }
  56% {
    content: "56%";
  }
  57% {
    content: "57%";
  }
  58% {
    content: "58%";
  }
  59% {
    content: "59%";
  }
  60% {
    content: "60%";
  }
  61% {
    content: "61%";
  }
  62% {
    content: "62%";
  }
  63% {
    content: "63%";
  }
  64% {
    content: "64%";
  }
  65% {
    content: "65%";
  }
  66% {
    content: "66%";
  }
  67% {
    content: "67%";
  }
  68% {
    content: "68%";
  }
  69% {
    content: "69%";
  }
  70% {
    content: "70%";
  }
  71% {
    content: "71%";
  }
  72% {
    content: "72%";
  }
  73% {
    content: "73%";
  }
  74% {
    content: "74%";
  }
  75% {
    content: "75%";
  }
  76% {
    content: "76%";
  }
  77% {
    content: "77%";
  }
  78% {
    content: "78%";
  }
  79% {
    content: "79%";
  }
  80% {
    content: "80%";
  }
  81% {
    content: "81%";
  }
  82% {
    content: "82%";
  }
  83% {
    content: "83%";
  }
  84% {
    content: "84%";
  }
  85% {
    content: "85%";
  }
  86% {
    content: "86%";
  }
  87% {
    content: "87%";
  }
  88% {
    content: "88%";
  }
  89% {
    content: "89%";
  }
  90% {
    content: "90%";
  }
  91% {
    content: "91%";
  }
  92% {
    content: "92%";
  }
  93% {
    content: "93%";
  }
  94% {
    content: "94%";
  }
  95% {
    content: "95%";
  }
  96% {
    content: "96%";
  }
  97% {
    content: "97%";
  }
  98% {
    content: "98%";
  }
  99% {
    content: "99%";
  }
  100% {
    content: "100%";
  }
}
@-webkit-keyframes em-a-progress-percentage-indicator {
  1% {
    content: "1%";
  }
  2% {
    content: "2%";
  }
  3% {
    content: "3%";
  }
  4% {
    content: "4%";
  }
  5% {
    content: "5%";
  }
  6% {
    content: "6%";
  }
  7% {
    content: "7%";
  }
  8% {
    content: "8%";
  }
  9% {
    content: "9%";
  }
  10% {
    content: "10%";
  }
  11% {
    content: "11%";
  }
  12% {
    content: "12%";
  }
  13% {
    content: "13%";
  }
  14% {
    content: "14%";
  }
  15% {
    content: "15%";
  }
  16% {
    content: "16%";
  }
  17% {
    content: "17%";
  }
  18% {
    content: "18%";
  }
  19% {
    content: "19%";
  }
  20% {
    content: "20%";
  }
  21% {
    content: "21%";
  }
  22% {
    content: "22%";
  }
  23% {
    content: "23%";
  }
  24% {
    content: "24%";
  }
  25% {
    content: "25%";
  }
  26% {
    content: "26%";
  }
  27% {
    content: "27%";
  }
  28% {
    content: "28%";
  }
  29% {
    content: "29%";
  }
  30% {
    content: "30%";
  }
  31% {
    content: "31%";
  }
  32% {
    content: "32%";
  }
  33% {
    content: "33%";
  }
  34% {
    content: "34%";
  }
  35% {
    content: "35%";
  }
  36% {
    content: "36%";
  }
  37% {
    content: "37%";
  }
  38% {
    content: "38%";
  }
  39% {
    content: "39%";
  }
  40% {
    content: "40%";
  }
  41% {
    content: "41%";
  }
  42% {
    content: "42%";
  }
  43% {
    content: "43%";
  }
  44% {
    content: "44%";
  }
  45% {
    content: "45%";
  }
  46% {
    content: "46%";
  }
  47% {
    content: "47%";
  }
  48% {
    content: "48%";
  }
  49% {
    content: "49%";
  }
  50% {
    content: "50%";
  }
  51% {
    content: "51%";
  }
  52% {
    content: "52%";
  }
  53% {
    content: "53%";
  }
  54% {
    content: "54%";
  }
  55% {
    content: "55%";
  }
  56% {
    content: "56%";
  }
  57% {
    content: "57%";
  }
  58% {
    content: "58%";
  }
  59% {
    content: "59%";
  }
  60% {
    content: "60%";
  }
  61% {
    content: "61%";
  }
  62% {
    content: "62%";
  }
  63% {
    content: "63%";
  }
  64% {
    content: "64%";
  }
  65% {
    content: "65%";
  }
  66% {
    content: "66%";
  }
  67% {
    content: "67%";
  }
  68% {
    content: "68%";
  }
  69% {
    content: "69%";
  }
  70% {
    content: "70%";
  }
  71% {
    content: "71%";
  }
  72% {
    content: "72%";
  }
  73% {
    content: "73%";
  }
  74% {
    content: "74%";
  }
  75% {
    content: "75%";
  }
  76% {
    content: "76%";
  }
  77% {
    content: "77%";
  }
  78% {
    content: "78%";
  }
  79% {
    content: "79%";
  }
  80% {
    content: "80%";
  }
  81% {
    content: "81%";
  }
  82% {
    content: "82%";
  }
  83% {
    content: "83%";
  }
  84% {
    content: "84%";
  }
  85% {
    content: "85%";
  }
  86% {
    content: "86%";
  }
  87% {
    content: "87%";
  }
  88% {
    content: "88%";
  }
  89% {
    content: "89%";
  }
  90% {
    content: "90%";
  }
  91% {
    content: "91%";
  }
  92% {
    content: "92%";
  }
  93% {
    content: "93%";
  }
  94% {
    content: "94%";
  }
  95% {
    content: "95%";
  }
  96% {
    content: "96%";
  }
  97% {
    content: "97%";
  }
  98% {
    content: "98%";
  }
  99% {
    content: "99%";
  }
  100% {
    content: "100%";
  }
}
/*------------------------------------*\
    #STATUS
\*------------------------------------*/
/**
 * 1) Block that contains header, body, and footer, all stacked on top of each other
 */
.em-c-status {
  display: flex;
  font-size: 0.875rem;
  /**
  * General link within status
  */
}
.em-c-status a {
  color: #0c69b0;
  text-decoration: underline;
  transition: color 0.15s ease;
}
.em-c-status a:hover {
  color: #104e8f;
}
.em-c-status a:focus {
  outline: 1px dotted #0c69b0;
}

.em-c-status:focus-visible {
  outline: none;
}

.em-c-status:focus-visible > span {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 3px;
}

/**
 * Status Icon
 * 1) Color of icon changes based on status
 */
.em-c-status__icon {
  display: inline-block;
  position: relative;
  margin-right: 0.4rem;
  /**
  * Positive status icon
  */
  /**
  * Negative status icon
  */
  /**
  * Caution status icon
  */
  /**
  * Neutral status icon
  */
  /**
  * Transparent status icon
  */
}
.em-c-status--positive .em-c-status__icon {
  color: #007c38;
}
.em-c-status--negative .em-c-status__icon {
  color: #b12a0b;
}
.em-c-status--caution .em-c-status__icon {
  color: #ed8b00;
}
.em-c-status--inactive .em-c-status__icon {
  color: #808080;
}
.em-c-status--transparent .em-c-status__icon {
  color: transparent;
}

div.em-c-text-passage {
  line-height: 1.1em;
}

/*------------------------------------*\
    #LOADER
\*------------------------------------*/
/**
 *  1) Loaders are used in several places to indicate waiting. Lazy loading, Buttons with loading state.
 *     Loader icon is a linked, animated SVG file
 */
.em-c-loader__icon {
  display: block;
  margin: 1rem auto;
}
.em-c-loader__icon-xl {
  width: 96px;
  height: 96px;
}
.em-c-loader__icon-l {
  width: 64px;
  height: 64px;
}
.em-c-loader__icon-m {
  width: 48px;
  height: 48px;
}
.em-c-loader__icon-s {
  width: 24px;
  height: 24px;
}

/**
 * Text
 */
/*------------------------------------*\
    #PAGE HEADER
\*------------------------------------*/
.em-c-page-header--underline {
  border-bottom: 3px solid #e5e5e5;
}

/**
 * Page header title
 */
.em-c-page-header__title {
  margin-bottom: 1.25rem;
  padding-top: 1rem;
  line-height: 1.15;
  /**
     * Page header title within small page header
     */
  /**
     * Page header title within small page header
     */
}
.em-c-page-header--small .em-c-page-header__title {
  font-size: 1.5rem;
}
.em-c-page-header--med .em-c-page-header__title {
  font-size: 2.2rem;
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
}

/**
 * Page description
 */
.em-c-page-header__desc {
  max-width: 45rem;
  margin-bottom: 2rem;
  font-size: 1.3rem;
}
.em-c-page-header__desc p {
  line-height: 1.3em;
}

/*------------------------------------*\
    #TEXT PASSAGE
\*------------------------------------*/
/**
 * 1) A passage of text that contains various text HTML elements.
 * 2) This is a 'scoped' element, which means that the
 */
.em-c-text-passage {
  font-size: 1rem;
  line-height: 1.6em;
  /**
  * Paragraph within text passage
  */
  /**
  * Link within the text passage
  */
  /**
  * Blockquote within text passage
  */
  /**
  * First-level heading within text passage
  */
  /**
  * Second-level heading within text passage
  */
  /**
  * Third-level heading within text passage
  */
  /**
  * Fourth-level heading within text passage
  */
  /**
  * Fifth-level heading within text passage
  */
  /**
  * Sixth-level heading within text passage
  */
  /**
  * Unordered list within text passage
  */
  /**
  * Ordered list within text passage
  */
  /**
  * Image within a text passage
  */
  /**
    * Small Text Passage
    * 1) Scales down text passage font size
    */
}
.em-c-text-passage p {
  margin-bottom: 2rem;
}
.em-c-text-passage a {
  color: #0c69b0;
  text-decoration: underline;
  transition: color 0.15s ease;
  cursor: pointer;
  /**
    * Theming
    * 1) Apply theme background when theme class is added to the
    *    `body` (i.e. `<body class="em-theme--blue">`) or if a
    *    modifier is added to the parent block
    */
}
.em-theme--blue .em-c-text-passage a, .em-c-text-passage--theme-blue .em-c-text-passage a {
  color: #0c69b0; /* 1 */
}
.em-theme--blue .em-c-text-passage a:hover, .em-theme--blue .em-c-text-passage a:focus, .em-c-text-passage--theme-blue .em-c-text-passage a:hover, .em-c-text-passage--theme-blue .em-c-text-passage a:focus {
  color: #3a397b;
}
.em-theme--red .em-c-text-passage a, .em-c-text-passage--theme-red .em-c-text-passage a {
  color: #0c69b0; /* 1 */
}
.em-theme--red .em-c-text-passage a:hover, .em-theme--red .em-c-text-passage a:focus, .em-c-text-passage--theme-red .em-c-text-passage a:hover, .em-c-text-passage--theme-red .em-c-text-passage a:focus {
  color: #111122;
}
.em-c-text-passage a:hover {
  color: #104e8f;
}
.em-c-text-passage a:focus {
  outline: 1px dotted #0c69b0;
}
.em-c-text-passage a:active {
  background: none;
}
.em-c-text-passage a.em-c-btn--primary {
  text-decoration: none;
}
.em-c-text-passage blockquote {
  padding-left: 2rem;
  border-left: 3px solid #e5e5e5;
  color: #56565e;
  font-size: 1rem;
}
.em-c-text-passage h1 {
  font-size: 3.5rem;
}
.em-c-text-passage h2 {
  font-size: 1.75rem;
  margin: 4rem 0 1.5rem;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 1rem;
}
.em-c-text-passage h2 a {
  text-decoration: none;
}
.em-c-text-passage h3 {
  font-size: 1.25rem;
  margin: 4rem 0 1.5rem;
}
.em-c-text-passage h3 a {
  text-decoration: none;
}
.em-c-text-passage h4 {
  font-size: 1rem;
  margin: 4rem 0 1.5rem;
  text-transform: uppercase;
  letter-spacing: 3px;
}
.em-c-text-passage h5 {
  font-size: 1.125rem;
  margin: 4rem 0 1.5rem;
}
.em-c-text-passage h6 {
  font-size: 1.125rem;
  margin: 4rem 0 1.5rem;
}
.em-c-text-passage ul {
  list-style: disc;
  margin-left: 1.1rem;
  margin-bottom: 1rem;
}
.em-c-text-passage ol {
  list-style: decimal;
  margin-left: 1.1rem;
  margin-bottom: 1rem;
}
.em-c-text-passage li {
  margin-bottom: 0.5rem;
  line-height: 1.6;
}
.em-c-text-passage img {
  display: block;
  margin: 2rem 0;
}
.em-c-text-passage--small {
  font-size: 1rem; /* 1 */
}

/**
 * Small Text Intro
 * 1) Emphasizes intro text
 */
.em-c-text-passage__intro {
  font-size: 1.5rem;
  line-height: 1.5em;
}

.em-c-text-passage a:focus-visible,
.em-c-definition-list__value a:focus-visible,
.em-l-linelength-container a:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 2px;
}

/*------------------------------------*\
    #PULLQUOTE
\*------------------------------------*/
/**
 * 1) A pullquote is a fancier blockquote use for one sentence quotes for more typographic interest.
 * 2) Do not use for long multi-line quotes.
 */
.em-c-pullquote {
  font-size: 1.75rem;
  line-height: 1.2;
  margin: 3rem 0;
  font-style: italic;
  position: relative;
  padding-left: 4rem;
  max-width: 48rem;
}

/**
 * Pullquote citation
 */
.em-c-pullquote__citation {
  display: block;
  font-size: 0.875rem;
  margin-top: 1rem;
  /**
  * Pullquote citation before pseudoelement
  */
}
.em-c-pullquote__citation:before {
  content: "";
  display: inline-block;
  width: 2.5rem;
  margin-right: 0.3rem;
  border-bottom: 1px solid #111122;
  position: relative;
  top: -3px;
}

/**
 * Pullquote Icon
 * 1) Only show decorative icon on larger screens
 */
.em-c-pullquote__icon {
  fill: #e5e5e5;
  color: #e5e5e5;
  display: block;
  position: absolute;
  top: -1.2rem;
  left: 0;
}

/*------------------------------------*\
    #TEXT LINK
\*------------------------------------*/
/**
 * 1) Basic text link
 */
.test {
  text-decoration: underline;
}

.em-c-text-link {
  color: #0c69b0;
  text-decoration: underline;
  transition: color 0.15s ease;
}
.em-c-text-link:hover {
  color: #104e8f;
}
.em-c-text-link:focus {
  outline: 1px dotted #0c69b0;
}
.em-c-text-link:active {
  background: none;
}

.em-c-text-link:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 2px;
}

/**
 * Tables
 */
/*------------------------------------*\
	#TABLE
\*------------------------------------*/
/**
 * 1) Data Table
 * 2) Force all tables to be at least a certain width so table cells don't collapse into
 *    unreadable chunks.
 */
.em-c-table {
  margin-bottom: 1rem;
  min-width: 600px; /* 2 */
  /**
  * Link within a table
  * 1) Used this way to add these styles to any link in table, whether the link has a class or not
  * 2) CSS should NOT be laid out this way unless targeting arbitrary text AND text with classes
  */
  /**
  * Table within table object
  * 1) Margin bottom removed when table is inside table object
  */
  /**
  * Table within simple table object
  * 1) Min width of 0 added so table sits within the viewport width at smaller screens
  */
}
.em-c-table a {
  color: #0c69b0;
  text-decoration: underline;
  transition: color 0.15s ease;
  /**
    * Theming
    * 1) Apply theme color when theme class is added to the
    *    `body` (i.e. `<body class="em-theme--blue">`) or if a
    *    modifier is added to the parent block
    */
}
.em-theme--blue .em-c-table a, .em-c-table--theme-blue .em-c-table a {
  color: #0c69b0; /* 1 */
}
.em-theme--blue .em-c-table a:hover, .em-theme--blue .em-c-table a:focus, .em-c-table--theme-blue .em-c-table a:hover, .em-c-table--theme-blue .em-c-table a:focus {
  color: #3a397b;
}
.em-theme--red .em-c-table a, .em-c-table--theme-red .em-c-table a {
  color: #0c69b0;
}
.em-theme--red .em-c-table a:hover, .em-theme--red .em-c-table a:focus, .em-c-table--theme-red .em-c-table a:hover, .em-c-table--theme-red .em-c-table a:focus {
  color: #111122;
}
.em-c-table a:hover {
  color: #104e8f;
}
.em-c-table a:focus {
  outline: 1px dotted #0c69b0;
}
.em-c-table a:active {
  background: none;
}
.em-c-table-object .em-c-table {
  margin-bottom: 0;
}
.em-c-table-object--simple .em-c-table {
  min-width: 0;
}

/**
 * Table Header
 */
.em-c-table__header {
  background: #111122;
  color: #fff;
}

/**
 * Table Header
 */
.em-c-table__header-cell {
  padding: 0.8rem;
  font-size: 0.75rem;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
  /*
  * Table header cell within condensed table
  */
}
.em-c-table--condensed .em-c-table__header-cell {
  padding: 0.375rem 0.8rem;
  font-size: 0.675rem;
}

/**
 * Table Row
 * 1) Position relative and margin bottom of -1px added to overlap active border over inactive border
 */
.em-c-table__row {
  border-bottom: 1px solid #e5e5e5;
  position: relative;
  transition: all 0.15s ease;
  /**
  * Highlighted row when selected
  */
}
.em-c-table__row:hover, .em-c-table__row:focus {
  background: rgba(0, 0, 0, 0.04);
}
.em-c-table__row.em-is-active {
  background: #e1efff;
  z-index: 1;
}
.em-c-table--striped .em-c-table__row {
  border: 0;
}
.em-c-table--striped .em-c-table__row:hover {
  background: none;
  box-shadow: inset 0px 1px 0px 0px #cccccd, inset 0px -1px 0px 0px #cccccd;
}
.em-c-table--striped .em-c-table__row:nth-child(even) {
  background: #f5f5f5;
}
.em-c-table--striped .em-c-table__row {
  border: 0;
}
.em-c-table--striped .em-c-table__row:hover {
  background: none;
  box-shadow: inset 0px 1px 0px 0px #cccccd, inset 0px -1px 0px 0px #cccccd;
}
.em-c-table--striped .em-c-table__row:nth-child(even) {
  background: #f5f5f5;
}
.em-c-table__row.em-js-table-row-draggable {
  position: relative;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.em-c-table__row.em-is-dragging {
  cursor: -moz-grabbing;
  cursor: grabbing;
  cursor: -webkit-grabbing;
}

/**
 * Table Cell
 */
.em-c-table__cell {
  padding: 1.6rem 0.8rem;
  /**
  * Table cell within condensed table
  */
  /**
  * Table cell within secondary table row
  */
  /**
  * Table cell that is also a dropdown
  */
  /**
  * Table cell that has padding
  * 1) Lines up text between cell with icon and cell without
  */
}
.em-c-table--condensed .em-c-table__cell {
  padding: 0.25rem 0.8rem;
  font-size: 0.75rem;
}
.em-c-table__row--secondary .em-c-table__cell {
  /**
  * First table cell within secondary table row
  */
}
.em-c-table__row--secondary .em-c-table__cell:first-child {
  padding-left: 2.7rem;
}
.em-c-table__cell--dropdown {
  cursor: pointer;
}
.em-c-table__cell--padding {
  padding-left: 2.7rem;
}
.em-c-table__cell.em-js-cell-editable:focus {
  outline: 0;
  border: 1px solid #0c69b0;
}
.em-js-table-row-draggable .em-c-table__cell:last-child {
  position: relative;
}
.em-js-table-row-draggable .em-c-table__cell:last-child:after {
  content: "⋮";
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  height: calc(100% - 1.6rem);
  width: 20px;
  padding-top: 1.6rem;
  background: #f5f5f5;
  font-family: "EMprintBold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
}

/**
 * Table Footer
 */
.em-c-table__footer {
  font-size: 0.75rem;
}

/**
 * Table Footer Cell
 */
.em-c-table__footer-cell {
  padding: 0.8rem;
  /*
  * Table footer cell within condensed table
  */
}
.em-c-table--condensed .em-c-table__footer-cell {
  padding: 0.375rem 0.8rem;
  font-size: 0.675rem;
}

/*
 * Table footer withPagination with condensed table
 */
.em-c-table-object__body:has(.em-c-table--condensed) + .em-c-table-object__footer > .em-c-toolbar {
  padding: 0.25rem 0.8rem;
  font-size: 0.75rem;
}

/*------------------------------------*\
	#COLLAPSIBLE TABLE
\*------------------------------------*/
/**
 * Table header cell
 */
.em-c-table__header-cell {
  /**
  * Table header cell within collapsible table object
  */
}
.em-c-table-object--collapsible .em-c-table__header-cell {
  /**
  * First table header cell in collapsible table object
  * 1) Padding added to align text
  */
}
.em-c-table-object--collapsible .em-c-table__header-cell:first-child {
  padding-left: 2.7rem;
}

/**
 * Secondary table row
 */
.em-c-table__row--secondary {
  display: none;
  /**
  * Secondary row is visible
  * 1) When JS adds em-is-visible links display.
  */
}
.em-c-table__row--secondary.em-is-visible {
  display: table-row;
}

/**
 * Table icon
 * 1) Used for the table rows that can dropdown
 */
.em-c-table__icon {
  position: relative;
  top: 2px;
  margin-right: 1rem;
  color: #111122;
  fill: #111122;
}

/**
	 * Open table row
	 * 1) When table row is open, rotate icon 180 degrees
	 */
.em-c-table__row:has(input[type=checkbox]:checked) .em-c-table__icon {
  transform: rotate(90deg); /* 1 */
}

label > .em-c-table__cell.em-c-table__cell--dropdown {
  font-family: "EMprint";
  font-weight: normal;
}

/*------------------------------------*\
	#DISPLAY-TABLE
\*------------------------------------*/
/*Default Table Styles*/
.em-dc-table-object {
  border: #e5e5e5 1px solid;
}

.em-dc-table {
  border-top: 0 !important;
  border-collapse: separate !important;
  transform-style: flat;
}

.em-dc-table_container {
  max-height: 75vh;
  min-height: 20rem;
  overflow-y: auto !important;
}

.em-dc-table-spacing {
  padding: 0.11rem 0.8rem;
}

/*Table Row Styles*/
.em-dc-table_row {
  line-height: 35px;
}

.em-dc_table_row td {
  border: 0.5px solid #e5e5e5;
}

.em-dc-table .em-dc-table_row:hover .em-dc-table-column_input input,
.em-dc-table .em-dc-table_row:hover .em-c-table__cell {
  background-color: #e1efff !important;
}

.em-dc-table-column_numeric {
  text-align: right;
}

.em-dc-table_header-cell:first-child {
  border-left: none;
}

.em-dc-table_header-fixed {
  position: sticky !important;
  top: 0 !important;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.em-dc-table_header-cell {
  padding: 0 0.75rem;
  border-left: #e5e5e5 0.5px solid;
  text-transform: none;
  letter-spacing: 1px;
  line-height: 33px;
  font-size: 0.875rem;
}

.em-dc-table-header-cell-numeric {
  text-align: center;
}

.em-dc-table_header_row_groupheaders .em-dc-table_header-cell {
  border-bottom: #e5e5e5 0.5px solid;
}

.em-dc-table_header_row_groupheaders .em-dc-table_header-cell-double {
  border-bottom: solid 1px #545459;
}

.em-dc-table_header_row_groupheaders .em-dc-table_header-cell-parent-header {
  font-family: "EMprintBold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
}

.em-dc-uom {
  font-size: 12px;
  font-family: "EMprintRegular", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
}

.em-dc-table-column_numeric {
  width: 6rem !important;
  text-align: right !important;
}

/*Header Varient Styles*/
.em-dc-header_default .em-dc-table_header-cell {
  line-height: 33px;
  font-size: 0.875rem;
}

.em-dc-header_default .em-dc-table_header_row_subheader .em-dc-table_header-cell {
  top: 33px !important;
}

.em-dc-header_medium .em-dc-table_header-cell {
  font-size: 0.875rem;
  line-height: 45px;
}

.em-dc-header_large .em-dc-table_header-cell {
  font-size: 0.875rem;
  line-height: 55px;
}

.em-dc-table_header-cell {
  font-size: 1rem !important;
}

/*Striped table styles*/
.em-dc-table_striped {
  background: #f5f5f5;
}

.em-dc-table_striped .em-dc-table_row {
  background: #fff;
  border: none;
  border-top: 0.5px #e5e5e5 solid;
}

.em-dc-table_striped .em-dc-table_row:nth-child(even) {
  background: #f5f5f5;
}

.em-c-badge:focus-visible,
.em-c-tags__link:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 3px;
}

.em-c-media-block:focus-visible {
  outline: none;
}

/*------------------------------------*\
    #TABLE OBJECT
\*------------------------------------*/
/**
 * 1) A table object is a data table with accompanying toolbars
 */
.em-c-table-object {
  margin-bottom: 2rem;
}

/**
 * Table object body
 */
.em-c-table-object__body {
  position: relative;
}

/**
 * Table Object Inner Wrapper
 * 1) Applies an overflow onto the table object body for small screen users to scroll over
 */
.em-c-table-object__body-inner {
  overflow-x: auto;
  overflow-y: hidden;
  background: linear-gradient(to right, white 30%, hsla(0deg, 0%, 100%, 0)), linear-gradient(to right, hsla(0deg, 0%, 100%, 0), white 70%) right, linear-gradient(to right, rgba(0, 0, 0, 0.2) 0%, transparent 70%), linear-gradient(to left, rgba(0, 0, 0, 0.2) 0%, transparent 70%) right;
  background-repeat: no-repeat;
  background-size: 40px 100%, 40px 100%, 14px 100%, 14px 100%;
  background-attachment: local, local, scroll, scroll;
}

/**
  * Interactive
  */
/*------------------------------------*\
    #TABS
\*------------------------------------*/
/**
 * 1) Unordered list of links where the each link targets different information
 */
.em-c-tabs {
  margin-bottom: 2rem;
}

/**
 * Tabs list
 * 1) Display flex used to put tabs in a row
 * 2) Add gravity scrolling to iOS
 * 3) Hide scrollbars in IE but keep scroll behavior
 * 4) This complex group of background images provides scrolling shadows for overflow
 *    and creates a bottom gray border for IE to display properly.
 */
.em-c-tabs__list {
  display: flex; /* 1 */
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* 2 */
  -ms-overflow-style: none; /* 3 */
  overflow-y: visible;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgMSAxIiBmaWxsPSIjZTVlNWU1IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KCSA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSIjZTVlNWU1IiAvPgo8L3N2Zz4K) bottom left, linear-gradient(to right, white 30%, hsla(0deg, 0%, 100%, 0)), linear-gradient(to right, hsla(0deg, 0%, 100%, 0), white 70%) right, linear-gradient(to right, rgba(0, 0, 0, 0.2) 0%, transparent 70%), linear-gradient(to left, rgba(0, 0, 0, 0.2) 0%, transparent 70%) right; /* 4 */
  background-repeat: repeat-x, no-repeat, no-repeat, no-repeat, no-repeat;
  background-size: 1px 1px, 40px 100%, 40px 100%, 14px 100%, 14px 100%;
  background-attachment: scroll, local, local, scroll, scroll;
  /**
    * Tabs list within tabs component that is nested in another tabs component
    * 1) Remove the padding.
    */
}
.em-c-tabs--nav .em-c-tabs .em-c-tabs__list {
  margin: -2rem -1rem 0;
}

/**
 * Tab Item
 * 1) Don't flex tab items so overflow auto can scroll them instead
 */
.em-c-tabs__item {
  flex-shrink: 0;
}

/**
 * Tabs list
 * 1) Overlap the tabs bottom border
 */
.em-c-tabs__link {
  display: block;
  padding: 0.5rem 1rem;
  min-width: 5rem;
  text-align: center;
  color: #0c69b0;
  border: 1px solid transparent;
  border-bottom-color: rgba(255, 255, 255, 0);
  transition: all 0.15s ease;
  text-decoration: none;
  /**
  * Tab link within underline tabs
  */
  /**
  * Active tab link
  */
  /**
  * Tabs link within tabs that are nested inside tabs with nav component
  */
}
.em-c-tabs--underline .em-c-tabs__link {
  border: 0;
}
.em-c-tabs__link.em-is-active {
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
  background: #fff;
  border-color: #e5e5e5;
  border-bottom-color: #fff;
  border-radius: 4px 4px 0 0;
  /**
  * Active tab link within gray tabs
  */
  /**
  * Active tab link within underline tabs
  */
}
.em-c-tabs--gray .em-c-tabs__link.em-is-active {
  background: #f5f5f5;
  border-bottom-color: #f5f5f5;
}
.em-c-tabs--underline .em-c-tabs__link.em-is-active {
  box-shadow: inset 0px -2px 0px #0c69b0;
}
.em-c-tabs--nav .em-c-tabs .em-c-tabs__link {
  font-size: 0.875rem;
}
.em-c-tabs__link:hover, .em-c-tabs__link:focus {
  color: #111122;
}

/**
 * Tab Body
 */
.em-c-tabs__body {
  padding: 1em 0;
  border: 1px solid #e5e5e5;
  border-top: 0;
  /**
  * Tab body within gray tabs
  */
}
.em-c-tabs--gray .em-c-tabs__body {
  background: #f5f5f5;
}
.em-c-tabs__body--no-border {
  border: 0;
}

/**
 * Tab Panel
 * 1) Display by default, and only hide if JS is available
 */
.em-c-tabs__panel {
  padding: 1rem;
  display: none;
  /**
  * Tabs panel when activated
  */
  /**
  * Tabs panel within tabs underline
  * 1) Removed right and left padding
  */
  /**
  * Tabs panel within another tab panel within tabs with navigation component
  * 1) Remove the right and left padding
  */
}
.em-c-tabs__panel.em-is-active {
  display: block; /* 1 */
}
.em-c-tabs--underline .em-c-tabs__panel {
  padding: 1rem 0;
}
.em-c-tabs--nav .em-c-tabs__panel .em-c-tabs__panel {
  padding: 1rem 0;
}

.em-c-tabs__link:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: -2px;
}

/*------------------------------------*\
    #MODAL
\*------------------------------------*/
/**
 * Modal
 * 1) The modal container
 * 2) Modal window and overlay positioned to sit over the rest of the content with position fixed.  
 * 3) z-index is used to position the modal over top of the rest of the content.
 *    Normally, such high z-index values shouldn't be used, but in this case it makes
 *    sense since the modal should override every other element.
 */
.em-c-modal {
  display: flex; /* 1 */
  /**
  * Modal header
  */
  /**
  * Modal body
  */
  /**
  * Modal footer
  */
  /**
  * Modal close button
  */
  /**
  * Large modal window
  */
}
.em-c-modal__window {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed; /* 2 */
  width: 40em;
  max-width: 90%;
  max-height: 90vh;
  padding: 2rem;
  background: #fff;
  border-radius: 8px;
  background-color: #fff;
  z-index: 50; /* 3 */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.em-c-modal__overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed; /* 2 */
  top: 0;
  left: 0;
  z-index: 45; /* 3 */
  background: rgba(0, 0, 0, 0.4);
  overflow: auto;
  transition: all 2s ease;
}
.em-c-modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.em-c-modal__header .em-c-modal__icon {
  margin-bottom: 1rem;
  fill: #111122;
  color: #111122;
}
.em-c-modal__header .em-c-modal__kicker {
  font-family: "EMprintLight", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 1;
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
}
.em-c-modal__header .em-c-modal__title {
  font-size: 1.5rem;
  line-height: 1.2;
  margin: 0.5rem 0;
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
}
.em-c-modal__body {
  padding: 1rem 0;
}
.em-c-modal__footer {
  margin-top: 0;
}
.em-c-modal__close-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.em-c-modal--large {
  width: 90vw;
}
.em-c-modal .em-is-closed {
  display: none;
}

/*------------------------------------*\
    #ACCORDION
\*------------------------------------*/
/**
 * 1) The accordion is typically applied to a <ul> tag.
 */
.em-c-accordion {
  margin-bottom: 2rem;
  border: 1px solid #e5e5e5;
  border-top: 0;
}
.em-c-accordion a {
  color: #343434;
  text-decoration: none;
  outline: 0;
}

/**
 * Accordion Header
 * 1) The handle of the accordion that contains the accordion title and icon
 */
.em-c-accordion__header {
  border-top: 1px solid #e5e5e5;
  cursor: pointer;
  font-size: 0.875rem;
}

/**
 * Accordion Title
 * 1) Set to display flex to span the width of the container so entire accordion header is click target
 */
.em-c-accordion__title {
  display: flex; /* 1 */
  width: 100%;
  align-items: center;
  padding: 0.75rem 1rem;
  background: #f5f5f5;
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-style: normal;
  transition: background 0.15s ease;
  border: none;
}
.em-c-accordion__title:hover, .em-c-accordion__title:focus {
  background: rgba(50, 50, 50, 0.1);
}

/**
 * Accordion Body
 * 1) The container that houses the accordion content, that can be opened and closed
 */
.em-c-accordion__body {
  overflow: hidden;
  max-height: 9999em;
  padding: 1rem;
  border-top: 1px solid #e5e5e5;
  transition: max-height 0.15s ease-out;
  font-size: 0.875rem;
  /*
  * Closed state
  * 1)
  */
}
.em-c-accordion__item.em-is-closed .em-c-accordion__body {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  border-top: 0;
}

/**
 * Accordion Icon
 *
 */
.em-c-accordion__icon {
  top: 2px;
  margin-right: 0.5rem;
  color: #343434;
  fill: #343434;
  transition: transform 0.15s ease-out;
}
.em-is-closed .em-c-accordion__icon {
  transform: rotate(-90deg);
}

.em-c-accordion__title:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 4px;
}

/*------------------------------------*\
    #TOOLTIP
\*------------------------------------*/
/**
 * 1) Information to user when you hover over an element
 */
/**
 * Actual tooltip displayed on hover, click, or tap
 */
.em-c-tooltip {
  display: inline-block;
  position: relative;
  border: none;
  background: none;
}
.em-c-tooltip p {
  margin-bottom: 0;
}

/**
 * Show focus style, but only if is an <a>. 
 * Didn't find how to show on icon.
 */
.em-c-tooltip a:focus-visible {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 3px;
}

/**
 * Actual tooltip displayed on hover, click, or tap
 */
.em-c-tooltip__content {
  display: block;
  position: absolute;
  bottom: 130%;
  left: 50%;
  margin: 0 0 5px -90px;
  padding: 0.75rem 1rem;
  height: auto;
  min-width: 11.875rem;
  max-width: 16.5rem;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
  text-align: center;
  color: #fff;
  font-size: 0.875rem;
  background-color: #111122;
  -webkit-backface-visibility: hidden;
  opacity: 0;
  z-index: 20;
  transition: all 0.15s ease;
  /**
  * Tooltip after pseudo element
  */
  /**
  * Light tooltip modifier
  */
  /**
  * Error tooltip modifier
  */
}
.em-c-tooltip:hover .em-c-tooltip__content, .em-c-tooltip:focus .em-c-tooltip__content {
  clip: auto;
  overflow: visible;
  opacity: 1;
}
.em-c-tooltip.em-is-active .em-c-tooltip__content {
  clip: auto;
  overflow: visible;
  opacity: 1;
}
.em-c-tooltip--below .em-c-tooltip__content {
  top: 130%;
  bottom: inherit;
}
.em-c-tooltip__content:after {
  content: "";
  position: absolute;
  top: 100%;
  right: 50%;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #111122;
}
.em-c-tooltip--below .em-c-tooltip__content:after {
  top: -6px;
  border-top: 0;
  border-bottom: 6px solid #111122;
}
.em-c-tooltip--light .em-c-tooltip__content {
  background: #fff;
  color: #111122;
  border: 1px solid #bbbbbd;
  /**
  	 * 1) Chevron color deviates from the main border color
       *    due to how the pixels are rendered. This matches
       *    the color more closely.
  	 */
}
.em-c-tooltip--light .em-c-tooltip__content:after, .em-c-tooltip--light .em-c-tooltip__content:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  height: 0;
  width: 0;
  border: 1px solid transparent;
}
.em-c-tooltip--light .em-c-tooltip__content:after {
  margin-left: -6px;
  border-width: 6px;
  border-top-color: #fff;
}
.em-c-tooltip--light .em-c-tooltip__content:before {
  margin-left: -7px;
  border-width: 7px;
  border-top-color: #777777; /* 1 */
}
.em-c-tooltip--error .em-c-tooltip__content {
  background: #fff;
  color: #ad1723;
  border: 1px solid #ad1723;
  /**
  	 * 1) Chevron color deviates from the main border color
       *    due to how the pixels are rendered. This matches
       *    the color more closely.
  	 */
}
.em-c-tooltip--error .em-c-tooltip__content:after, .em-c-tooltip--error .em-c-tooltip__content:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  height: 0;
  width: 0;
  border: 1px solid transparent;
}
.em-c-tooltip--error .em-c-tooltip__content:after {
  margin-left: -6px;
  border-width: 6px;
  border-top-color: #fff;
}
.em-c-tooltip--error .em-c-tooltip__content:before {
  margin-left: -7px;
  border-width: 7px;
  border-top-color: #ad1723; /* 1 */
}
.em-c-tooltip--light.em-c-tooltip--below .em-c-tooltip__content:after {
  top: -6px;
  border-top: 0;
  border-bottom: 6px solid #fff;
}
.em-c-tooltip--light.em-c-tooltip--below .em-c-tooltip__content:before {
  top: -6px;
  border-top: 0;
  border-bottom: 6px solid #111122;
}
.em-c-tooltip--error.em-c-tooltip--below .em-c-tooltip__content:after {
  top: -6px;
  border-top: 0;
  border-bottom: 6px solid #fff;
}
.em-c-tooltip--error.em-c-tooltip--below .em-c-tooltip__content:before {
  top: -6px;
  border-top: 0;
  border-bottom: 6px solid #ad1723;
}

/**
 * Tooltip Trigger
 */
.em-c-tooltip__trigger {
  /**
  * Tooltip Trigger within icon tooltip
  */
}
.em-c-tooltip--icon .em-c-tooltip__trigger {
  display: flex;
  align-items: center;
  border-radius: 50%;
  padding: 0.2rem;
  text-align: center;
}

/**
 * Tooltip Icon
 */
.em-c-tooltip__icon {
  fill: #0c69b0;
  color: #0c69b0;
}

/**
  * Sections
  */
/*------------------------------------*\
	#SECTIONS
\*------------------------------------*/
/**
 * 1) A section is a container component that lays out discrete sections of a page.
 * 2) This component makes sense for items that would be marked up with a <section> tag
 */
.em-c-section {
  margin-bottom: 2rem;
  /**
  * Split Section
  * 1) This section modifier displays the section header and body side-by-sidee
  *    on large-ish screens
  */
  /**
  * Banded section
  * 1) Background color causes banding
  */
}
@media all and (min-width: 47em) {
  .em-c-section--split {
    display: flex;
  }
}
.em-c-section--banded {
  background: #f5f5f5;
  margin-bottom: 2rem;
}

/**
 * Theming
 * 1) Apply theme color when theme class is added to the
 *    `body` (i.e. `<body class="em-theme--blue">`) or if a
 *    modifier is added to the parent block
 */
.em-c-section__header {
  padding-bottom: 0.5rem;
  border: none;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 2rem;
  background: none;
  width: 100%;
  /**
  * Bare Section header
  * 1) Has no border or padding
  */
  /**
  * Underline Section header
  * 1) Has additional color band 
  */
  /**
  * Section header within section split
  */
  /**
  * Section header in expandable section
     * 1) Display flex puts items side by side
  */
}
.em-c-section__header--bare, .em-c-section__header--underline, .em-c-section__header--underline-blue {
  padding-bottom: 0;
  border-bottom: 0;
}
.em-c-section__header--underline .em-c-section__title {
  display: inline-block;
  position: relative;
}
.em-c-section__header--underline .em-c-section__title .em-c-section__title-underline {
  height: 0.3rem;
  display: block;
  margin-top: 0.6rem;
  background: #d82424;
  background-image: linear-gradient(45deg, #ad1723 0%, #d82424 100%);
}
.em-c-section__header--underline-blue .em-c-section__title {
  display: inline-block;
  position: relative;
}
.em-c-section__header--underline-blue .em-c-section__title .em-c-section__title-underline {
  height: 0.3rem;
  display: block;
  margin-top: 0.6rem;
  background: #3a397b;
  background-image: linear-gradient(45deg, #3a397b 0%, #3190d9 100%);
}
.em-c-section--split .em-c-section__header {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}
@media all and (min-width: 47em) {
  .em-c-section--split .em-c-section__header {
    width: 16.5625rem;
    margin-right: 2rem;
  }
}
.em-c-section--expandable .em-c-section__header {
  display: flex;
  align-items: center;
  cursor: pointer;
}

/**
 * Section title
 */
.em-c-section__title {
  margin: 0.675rem 0 0.25rem;
  font-size: 1.25rem;
  font-weight: normal;
  line-height: 1.2;
  /**
  * Large section title
  */
  /**
  * Section title within section split
  * 1) Aligns right section and left section of section split.
  */
}
.em-c-section__title--large {
  font-size: 2.2rem;
  font-weight: normal;
  line-height: 1.2;
  margin-bottom: 0.3rem;
}
@media all and (min-width: 47em) {
  .em-c-section--split .em-c-section__title {
    margin-top: 0.25rem; /* 1 */
    line-height: 1; /* 1 */
  }
}

/**
 * Section title link
 */
.em-c-section__title-link {
  color: #0c69b0;
  text-decoration: underline;
  transition: color 0.15s ease;
  text-decoration: none;
}
.em-c-section__title-link:hover {
  color: #104e8f;
}
.em-c-section__title-link:focus {
  outline: 1px dotted #0c69b0;
}

/**
 * Section description
 */
.em-c-section__desc {
  max-width: 30em;
  margin-bottom: 2rem;
}

/**
 * Section Body
 */
.em-c-section__body {
  /**
  * Split Section
  * 1) This section modifier displays the section header and body side-by-sidee
  *    on large-ish screens
  */
  /**
  * Section body within expandable section
  * 1) Max height transition fast
  */
  /**
  * Section body within closed expandable section
  * 1) Max-height 0 and overflow hidden to hide section
  */
}
@media all and (min-width: 47em) {
  .em-c-section--split .em-c-section__body {
    flex: 1;
  }
}
.em-c-section--expandable .em-c-section__body {
  max-height: 9999em;
  transition: all 0.15s ease;
}
.em-c-section--expandable.em-is-closed .em-c-section__body {
  max-height: 0;
  overflow: hidden;
}

/**
 * Section Icon
 */
.em-c-section__icon {
  display: block;
  position: relative;
  top: 4px;
  margin-right: 0.5rem;
  transition: transform 0.15s ease;
  /**
    * Section Icon Within Closed sectionIcon
    * 1) Rotate -90deg to point chevron right when closed
    */
}
.em-c-section.em-is-closed .em-c-section__icon {
  transform: rotate(-90deg);
}

.em-c-section--expandable button:focus-visible > h2 {
  outline-width: 2px;
  outline-style: dashed;
  outline-color: #00a3e0;
  outline-offset: 4px;
}

/*------------------------------------*\
    #BAND
\*------------------------------------*/
/**
 * 1) A band is a container component that gives a full-bleed color treatment.
 * 2) This component will likely contain a `em-l-container` object to cap the content's width
 */
.em-c-band {
  padding: 3rem 0;
  margin-bottom: 2rem;
  /**
  * Blue Deep Band
  */
  /**
  * Blue Deep Band
  */
}
.em-c-band--blue-deep {
  background: #111122;
  color: #fff;
}
.em-c-band--gray {
  background: #f5f5f5;
}

/*------------------------------------*\
    #LAYOUT
\*------------------------------------*/
/*------------------------------------*\
    #LAYOUT
\*------------------------------------*/
/**
 * Layout container
 * 1) The layout container establishes the maxiumum width that the content can occupy.
 */
.em-l-container {
  margin: 0 auto;
  padding: 0 1rem;
}
@media all and (min-width: 40em) {
  .em-l-container {
    padding: 0 1.5625rem;
  }
}
@media all and (min-width: 47em) {
  .em-l-container {
    padding: 0 2rem;
  }
}
@media all and (min-width: 84em) {
  .em-l-container {
    max-width: 84em;
  }
}

/**
 * Line length container
 * 1) Line length container is for passages of text, so lines of text aren't uncomfortably wrong.
 */
.em-l-linelength-container {
  max-width: 38rem;
}

/*------------------------------------*\
    #TWO COLUMN LAYOUT
\*------------------------------------*/
/**
 * 1) Layout that splits the content into a main content column and sidebar column
 * 2) Display flex added to put items within two-column layout side by side
 */
.em-l--two-column {
  /**
    * Two column variation that allows for a wider secondary column
    */
}
@media all and (min-width: 62em) {
  .em-l--two-column {
    display: flex;
  }
}
@media all and (min-width: 67em) {
  .em-l--two-column-wide {
    display: flex;
  }
}

/**
 * 1) Sticky layout that splits the content into a main content column and sidebar column
 */
.em-l--two-column-sticky .em-l__main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@media all and (min-width: 62em) {
  .em-l--two-column-sticky {
    display: flex;
    overflow: auto;
    max-height: 100vh;
  }
}
/**
 * Main section
 */
.em-l__main {
  /**
  * Main section of 2 column layout
  * 2) Margin left equals width of the fixed positioned sidebar
  */
  /**
    * Main section of 2 column wide variation layout
    * 1) Calculates the width of the main section based on the width of wide sidebar
    */
}
@media all and (min-width: 62em) {
  .em-l--two-column > .em-l__main {
    flex: 1;
    width: calc(100% - 16.5625rem - 2rem);
  }
}
@media all and (min-width: 67em) {
  .em-l--two-column-wide .em-l__main {
    width: calc(100% - 20rem - 2rem);
    margin-right: 2rem;
  }
}

/**
* Main section of 2 column sticky layout
*/
@media all and (min-width: 62em) {
  .em-l--two-column-sticky > .em-l__main {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-left: 0rem;
    flex-grow: 1000;
    overflow-y: auto;
  }
}
/**
 * Secondary section
 */
.em-l__secondary {
  /**
  * Secondary section of 2 column layout
  */
  /**
    * Secondary section of 2 column wide variation layout
    * 1) Width of sidebar added to secondary section
    */
}
@media all and (min-width: 62em) {
  .em-l--two-column > .em-l__secondary {
    order: 1;
    width: 16.5625rem;
    min-width: 16.5625rem;
  }
}
@media all and (min-width: 67em) {
  .em-l--two-column-wide .em-l__secondary {
    order: 1;
    width: 20rem;
    min-width: 20rem;
  }
}

/**
* Secondary section of 2 column sticky layout
*/
@media all and (min-width: 62em) {
  .em-l--two-column-sticky > .em-l__secondary {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    width: min-content;
    flex-grow: 1;
  }
}
/*------------------------------------*\
    #GRID
\*------------------------------------*/
/**
 * Grid container
 * 1) Because each em-l-grid__item contains padding to create space between items,
 *    a negative margin is applied so the content inside the grid items is aligned.
 */
.em-l-grid {
  margin: 0 -0.5rem; /* 1 */
  /**
    * Double padding grid
    * 1) Doubles the amount of space between grid items
    */
}
.em-l-grid--pad-double {
  margin: 0 -1rem; /* 1 */
}

/**
 * Grid item
 */
.em-l-grid__item {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  /**
  * Grid item within halves grid
  */
  /**
    * Double padding grid
    * 1) Doubles the amount of space between grid items
    */
  /**
  * Grid item within halves grid
  */
  /**
  * Grid item within 2-up grid
  */
  /**
    * Grid item within 2-up grid
    */
  /**
  * Grid item within 1-2-1 grid
  */
  /**
    * Grid item within 1-2-1 grid break fast
    * 1) Grid items break faster than default when going from small to large screens
    */
  /**
    * Grid item within 1-2-1 grid break slow
    * 1) Grid items break slower than default when going from small to large screens
    */
  /**
  * Grid item within 1-to-3up grid
  */
  /**
    * Grid item within 1-to-3up grid break fast
    * 1) Grid items break faster than default when going from small to large screens
    */
  /**
    * Grid item within 1-to-3up grid break slow
    * 1) Grid items break slower than default when going from small to large screens
    */
  /**
  * Grid item within 3-up grid
   * 1) Added to get rid of bleed of stacked block images on IE on small screens
  */
  /**
    * Grid item within 3-up grid break fast
    * 1) Grid items break faster than default when going from small to large screens
    */
  /**
    * Grid item within 3-up grid break slow
    * 1) Grid items break slower than default when going from small to large screens
    */
  /**
  * Grid item within 1-2-4-up grid
  */
  /**
    * Grid item within 1-2-4-up grid break fast
    * 1) Grid items break faster than default when going from small to large screens
    */
  /**
    * Grid item within 1-2-4-up grid break slow
    * 1) Grid items break slower than default when going from small to large screens
    */
  /**
  * Grid item within 4-up grid
  */
  /**
    * Grid item within 4-up grid break fast
    * 1) Grid items break faster than default when going from small to large screens
    */
  /**
    * Grid item within 4-up grid break slow
    * 1) Grid items break slower than default when going from small to large screens
    */
  /**
  * Grid item within 4-up grid
  */
  /**
    * Grid item within 5-up grid break fast
    * 1) Grid items break faster than default when going from small to large screens
    */
  /**
    * Grid item within 5-up grid break slow
    * 1) Grid items break slower than default when going from small to large screens
    */
  /**
  * Grid item within 2-3-6up grid
  */
  /**
    * Grid item within 2-3-6-up grid break fast
    * 1) Grid items break faster than default when going from small to large screens
    */
  /**
    * Grid item within 2-3-6-up grid break slow
    * 1) Grid items break slower than default when going from small to large screens
    */
  /**
  * Grid item within 6up grid
  */
  /**
    * Grid item within 6-up grid break fast
    * 1) Grid items break faster than default when going from small to large screens
    */
  /**
    * Grid item within 6-up grid break slow
    * 1) Grid items break slower than default when going from small to large screens
    */
}
.em-l-grid__item--repel-right {
  margin-left: auto;
}
.em-l-grid--pad-double .em-l-grid__item {
  padding: 1rem;
}
.em-l-grid--halves .em-l-grid__item {
  width: 50%;
}
@media all and (min-width: 40em) {
  .em-l-grid--2up .em-l-grid__item {
    width: 50%;
  }
}
@media all and (min-width: 30rem) {
  .em-l-grid--2up.em-l-grid--break-fast .em-l-grid__item {
    width: 50%;
  }
}
@media all and (min-width: 40em) {
  .em-l-grid--2up.em-l-grid--break-slow .em-l-grid__item {
    width: inherit;
  }
}
@media all and (min-width: 50rem) {
  .em-l-grid--2up.em-l-grid--break-slow .em-l-grid__item {
    width: 50%;
  }
}
.em-l-grid--1-2-1 .em-l-grid__item {
  margin-bottom: 2rem;
}
.em-l-grid--1-2-1 .em-l-grid__item:last-child {
  margin-bottom: 0;
}
@media all and (min-width: 35em) {
  .em-l-grid--1-2-1 .em-l-grid__item {
    width: 50%;
    margin-bottom: 0;
  }
}
@media all and (min-width: 67em) {
  .em-l-grid--1-2-1 .em-l-grid__item {
    width: 100%;
    margin-bottom: 2rem;
  }
  .em-l-grid--1-2-1 .em-l-grid__item:last-child {
    margin-bottom: 0;
  }
}
@media all and (min-width: 62em) {
  .em-l--two-column .em-l-grid--1-2-1 .em-l-grid__item {
    width: 100%;
    margin-bottom: 2rem;
  }
  .em-l--two-column .em-l-grid--1-2-1 .em-l-grid__item:last-child {
    margin-bottom: 0;
  }
}
@media all and (min-width: 30em) {
  .em-l-grid--1-2-1.em-l-grid--break-fast .em-l-grid__item {
    width: 50%;
    margin-bottom: 0;
  }
}
@media all and (min-width: 62em) {
  .em-l-grid--1-2-1.em-l-grid--break-fast .em-l-grid__item {
    width: 100%;
    margin-bottom: 2rem;
  }
  .em-l-grid--1-2-1.em-l-grid--break-fast .em-l-grid__item:last-child {
    margin-bottom: 0;
  }
}
@media all and (min-width: 35em) {
  .em-l-grid--1-2-1.em-l-grid--break-slow .em-l-grid__item {
    width: inherit;
  }
}
@media all and (min-width: 47em) {
  .em-l-grid--1-2-1.em-l-grid--break-slow .em-l-grid__item {
    width: 50%;
    margin-bottom: 0;
  }
}
@media all and (min-width: 84em) {
  .em-l-grid--1-2-1.em-l-grid--break-slow .em-l-grid__item {
    width: 100%;
    margin-bottom: 2rem;
  }
  .em-l-grid--1-2-1.em-l-grid--break-slow .em-l-grid__item:last-child {
    margin-bottom: 0;
  }
}
@media all and (min-width: 40em) {
  .em-l-grid--1-to-3up .em-l-grid__item {
    width: 33.33333%;
  }
}
@media all and (min-width: 30em) {
  .em-l-grid--1-to-3up.em-l-grid--break-fast .em-l-grid__item {
    width: 33.33333%;
  }
}
@media all and (min-width: 40em) {
  .em-l-grid--1-to-3up.em-l-grid--break-slow .em-l-grid__item {
    width: inherit;
  }
}
@media all and (min-width: 50em) {
  .em-l-grid--1-to-3up.em-l-grid--break-slow .em-l-grid__item {
    width: 33.33333%;
  }
}
@media all and (min-width: 40em) {
  .em-l-grid--3up .em-l-grid__item {
    width: 50%;
  }
}
@media all and (min-width: 50em) {
  .em-l-grid--3up .em-l-grid__item {
    width: 33.33333%;
  }
}
@media all and (min-width: 30em) {
  .em-l-grid--3up.em-l-grid--break-fast .em-l-grid__item {
    width: 50%;
  }
}
@media all and (min-width: 40em) {
  .em-l-grid--3up.em-l-grid--break-fast .em-l-grid__item {
    width: 33.33333%;
  }
}
@media all and (min-width: 40em) {
  .em-l-grid--3up.em-l-grid--break-slow .em-l-grid__item {
    width: inherit;
  }
}
@media all and (min-width: 50em) {
  .em-l-grid--3up.em-l-grid--break-slow .em-l-grid__item {
    width: 50%;
  }
}
@media all and (min-width: 60em) {
  .em-l-grid--3up.em-l-grid--break-slow .em-l-grid__item {
    width: 33.33333%;
  }
}
@media all and (min-width: 40em) {
  .em-l-grid--1-2-4up .em-l-grid__item {
    width: 50%;
  }
}
@media all and (min-width: 50em) {
  .em-l-grid--1-2-4up .em-l-grid__item {
    width: 25%;
  }
}
@media all and (min-width: 30em) {
  .em-l-grid--1-2-4up.em-l-grid--break-fast .em-l-grid__item {
    width: 50%;
  }
}
@media all and (min-width: 40em) {
  .em-l-grid--1-2-4up.em-l-grid--break-fast .em-l-grid__item {
    width: 25%;
  }
}
@media all and (min-width: 40em) {
  .em-l-grid--1-2-4up.em-l-grid--break-slow .em-l-grid__item {
    width: inherit;
  }
}
@media all and (min-width: 50em) {
  .em-l-grid--1-2-4up.em-l-grid--break-slow .em-l-grid__item {
    width: 50%;
  }
}
@media all and (min-width: 60em) {
  .em-l-grid--1-2-4up.em-l-grid--break-slow .em-l-grid__item {
    width: 25%;
  }
}
@media all and (min-width: 40em) {
  .em-l-grid--4up .em-l-grid__item {
    width: 50%;
  }
}
@media all and (min-width: 50em) {
  .em-l-grid--4up .em-l-grid__item {
    width: 33.33333%;
  }
}
@media all and (min-width: 60em) {
  .em-l-grid--4up .em-l-grid__item {
    width: 25%;
  }
}
@media all and (min-width: 30em) {
  .em-l-grid--4up.em-l-grid--break-fast .em-l-grid__item {
    width: 50%;
  }
}
@media all and (min-width: 40em) {
  .em-l-grid--4up.em-l-grid--break-fast .em-l-grid__item {
    width: 33.33333%;
  }
}
@media all and (min-width: 50em) {
  .em-l-grid--4up.em-l-grid--break-fast .em-l-grid__item {
    width: 25%;
  }
}
@media all and (min-width: 40em) {
  .em-l-grid--4up.em-l-grid--break-slow .em-l-grid__item {
    width: inherit;
  }
}
@media all and (min-width: 50em) {
  .em-l-grid--4up.em-l-grid--break-slow .em-l-grid__item {
    width: 50%;
  }
}
@media all and (min-width: 60em) {
  .em-l-grid--4up.em-l-grid--break-slow .em-l-grid__item {
    width: 33.33333%;
  }
}
@media all and (min-width: 70em) {
  .em-l-grid--4up.em-l-grid--break-slow .em-l-grid__item {
    width: 25%;
  }
}
@media all and (min-width: 40em) {
  .em-l-grid--5up .em-l-grid__item {
    width: 50%;
  }
}
@media all and (min-width: 50em) {
  .em-l-grid--5up .em-l-grid__item {
    width: 33.33333%;
  }
}
@media all and (min-width: 60em) {
  .em-l-grid--5up .em-l-grid__item {
    width: 25%;
  }
}
@media all and (min-width: 70em) {
  .em-l-grid--5up .em-l-grid__item {
    width: 20%;
  }
}
@media all and (min-width: 30em) {
  .em-l-grid--5up.em-l-grid--break-fast .em-l-grid__item {
    width: 50%;
  }
}
@media all and (min-width: 40em) {
  .em-l-grid--5up.em-l-grid--break-fast .em-l-grid__item {
    width: 33.33333%;
  }
}
@media all and (min-width: 50em) {
  .em-l-grid--5up.em-l-grid--break-fast .em-l-grid__item {
    width: 25%;
  }
}
@media all and (min-width: 60em) {
  .em-l-grid--5up.em-l-grid--break-fast .em-l-grid__item {
    width: 20%;
  }
}
@media all and (min-width: 40em) {
  .em-l-grid--5up.em-l-grid--break-slow .em-l-grid__item {
    width: inherit;
  }
}
@media all and (min-width: 50em) {
  .em-l-grid--5up.em-l-grid--break-slow .em-l-grid__item {
    width: 50%;
  }
}
@media all and (min-width: 60em) {
  .em-l-grid--5up.em-l-grid--break-slow .em-l-grid__item {
    width: 33.33333%;
  }
}
@media all and (min-width: 70em) {
  .em-l-grid--5up.em-l-grid--break-slow .em-l-grid__item {
    width: 25%;
  }
}
@media all and (min-width: 80em) {
  .em-l-grid--5up.em-l-grid--break-slow .em-l-grid__item {
    width: 20%;
  }
}
.em-l-grid--2-3-6up .em-l-grid__item {
  width: 50%;
}
@media all and (min-width: 40rem) {
  .em-l-grid--2-3-6up .em-l-grid__item {
    width: 33.33333%;
  }
}
@media all and (min-width: 60rem) {
  .em-l-grid--2-3-6up .em-l-grid__item {
    width: 16.66666%;
  }
}
@media all and (min-width: 30em) {
  .em-l-grid--2-3-6up.em-l-grid--break-fast .em-l-grid__item {
    width: 33.33333%;
  }
}
@media all and (min-width: 50rem) {
  .em-l-grid--2-3-6up.em-l-grid--break-fast .em-l-grid__item {
    width: 16.66666%;
  }
}
@media all and (min-width: 40em) {
  .em-l-grid--2-3-6up.em-l-grid--break-slow .em-l-grid__item {
    width: 50%;
  }
}
@media all and (min-width: 50em) {
  .em-l-grid--2-3-6up.em-l-grid--break-slow .em-l-grid__item {
    width: 33.33333%;
  }
}
@media all and (min-width: 70em) {
  .em-l-grid--2-3-6up.em-l-grid--break-slow .em-l-grid__item {
    width: 16.66666%;
  }
}
@media all and (min-width: 40rem) {
  .em-l-grid--6up .em-l-grid__item {
    width: 50%;
  }
}
@media all and (min-width: 50rem) {
  .em-l-grid--6up .em-l-grid__item {
    width: 33%;
    margin-bottom: 0;
  }
}
@media all and (min-width: 60rem) {
  .em-l-grid--6up .em-l-grid__item {
    width: 25%;
  }
}
@media all and (min-width: 70rem) {
  .em-l-grid--6up .em-l-grid__item {
    width: 20%;
  }
}
@media all and (min-width: 80rem) {
  .em-l-grid--6up .em-l-grid__item {
    width: 16.66666%;
  }
}
@media all and (min-width: 30em) {
  .em-l-grid--6up.em-l-grid--break-fast .em-l-grid__item {
    width: 50%;
  }
}
@media all and (min-width: 40em) {
  .em-l-grid--6up.em-l-grid--break-fast .em-l-grid__item {
    width: 33.33333%;
  }
}
@media all and (min-width: 50em) {
  .em-l-grid--6up.em-l-grid--break-fast .em-l-grid__item {
    width: 25%;
  }
}
@media all and (min-width: 60em) {
  .em-l-grid--6up.em-l-grid--break-fast .em-l-grid__item {
    width: 20%;
  }
}
@media all and (min-width: 70rem) {
  .em-l-grid--6up.em-l-grid--break-fast .em-l-grid__item {
    width: 16.66666%;
  }
}
@media all and (min-width: 40em) {
  .em-l-grid--6up.em-l-grid--break-slow .em-l-grid__item {
    width: inherit;
  }
}
@media all and (min-width: 50em) {
  .em-l-grid--6up.em-l-grid--break-slow .em-l-grid__item {
    width: 50%;
  }
}
@media all and (min-width: 60em) {
  .em-l-grid--6up.em-l-grid--break-slow .em-l-grid__item {
    width: 33.33333%;
  }
}
@media all and (min-width: 70em) {
  .em-l-grid--6up.em-l-grid--break-slow .em-l-grid__item {
    width: 25%;
  }
}
@media all and (min-width: 80em) {
  .em-l-grid--6up.em-l-grid--break-slow .em-l-grid__item {
    width: 20%;
  }
}
@media all and (min-width: 90em) {
  .em-l-grid--6up.em-l-grid--break-slow .em-l-grid__item {
    width: 16.66666%;
  }
}

/**
 * Halves grid
 * 1) This grid splits the grid items into 2 halves for all widths
 * 2) Display flex and flex wrap used to place items side by side
 */
.em-l-grid--halves {
  display: flex;
}

/**
 * Repel grid
 * 1) This grid splits the grid items into 2 halves for all widths
 * 2) Display flex is used to
 */
.em-l-grid--repel {
  display: flex;
  width: 100%;
}

/**
 * 1-2-1 grid
 * 1) These grid items grow from 1-up, to 2-up on medium screens, and 1 up on large screens
 * 2) Display flex and flex wrap used to place items side by side on larger screens and
 *    wrap items to the next line that don't take up the remaining space
 */
.em-l-grid--1-2-1 {
  /**
    * 1-2-1 grid that breaks fast
    * 1) Breaks into two columns then back to one faster than default when moving from small to large screens
    */
  /**
    * 1-2-1 grid that breaks slower
    * 1) Breaks into two columns then back to one slower than default when moving from small to large screens
    */
}
@media all and (min-width: 30em) {
  .em-l-grid--1-2-1.em-l-grid--break-fast {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5rem;
  }
}
@media all and (min-width: 62em) {
  .em-l-grid--1-2-1.em-l-grid--break-fast {
    flex-direction: column;
    margin: 0;
  }
}
@media all and (min-width: 35em) {
  .em-l-grid--1-2-1.em-l-grid--break-slow {
    flex-direction: column;
    margin: 0;
  }
}
@media all and (min-width: 47em) {
  .em-l-grid--1-2-1.em-l-grid--break-slow {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -0.5rem;
  }
}
@media all and (min-width: 84em) {
  .em-l-grid--1-2-1.em-l-grid--break-slow {
    flex-direction: column;
    margin: 0;
  }
}
@media all and (min-width: 35em) {
  .em-l-grid--1-2-1 {
    display: flex; /* 2 */
    flex-wrap: wrap; /* 2 */
    margin: 0 -0.5rem;
  }
}
@media all and (min-width: 67em) {
  .em-l-grid--1-2-1 {
    flex-direction: column;
  }
}

/**
 * 2-up grid
 * 1) These grid items grow from 1-up to 2-up
 * 2) Display flex and flex wrap used to place items side by side on larger screens and
 *    wrap items to the next line that don't take up the remaining space
 */
.em-l-grid--2up {
  /**
    * 2-up grid that breaks fast
    * 1) Breaks into two columns faster than default when moving from small to large screens
    */
  /**
    * 2-up grid that breaks slower
    * 1) Breaks into two columns slower than default when moving from small to large screens
    */
}
@media all and (min-width: 30em) {
  .em-l-grid--2up.em-l-grid--break-fast {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5rem;
  }
}
@media all and (min-width: 40em) {
  .em-l-grid--2up.em-l-grid--break-slow {
    flex-direction: column;
    margin: 0;
  }
}
@media all and (min-width: 50em) {
  .em-l-grid--2up.em-l-grid--break-slow {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -0.5rem;
  }
}
@media all and (min-width: 40em) {
  .em-l-grid--2up {
    display: flex; /* 2 */
    flex-wrap: wrap; /* 2 */
    margin: 0 -0.5rem;
  }
}

/**
 * 1-to-3-up grid
 * 1) These grid items grow from 1-up to 3-up
 * 2) Display flex and flex wrap used to place items side by side on larger screens and
 *    wrap items to the next line that don't take up the remaining space
 */
.em-l-grid--1-to-3up {
  /**
    * 1-to-3up grid that breaks fast
    * 1) Content breaks into two and then three columns faster than default when moving from small to large screens
    */
  /**
    * 1-to-3up grid that breaks slower
    * 1) Content breaks into two and then three columns slower than default when moving from small to large screens
    */
}
@media all and (min-width: 30em) {
  .em-l-grid--1-to-3up.em-l-grid--break-fast {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5rem;
  }
}
@media all and (min-width: 40em) {
  .em-l-grid--1-to-3up.em-l-grid--break-slow {
    flex-direction: column;
    margin: 0;
  }
}
@media all and (min-width: 50em) {
  .em-l-grid--1-to-3up.em-l-grid--break-slow {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -0.5rem;
  }
}
@media all and (min-width: 40em) {
  .em-l-grid--1-to-3up {
    display: flex; /* 2 */
    flex-wrap: wrap; /* 2 */
    margin: 0 -0.5rem;
  }
}

/**
 * 3-up grid
 * 1) These grid items grow from 1-up, to 2-up, to 3-up
 * 2) Display flex and flex wrap used to place items side by side on larger screens and
 *    wrap items to the next line that don't take up the remaining space
 */
.em-l-grid--3up {
  /**
    * 3-up grid that breaks fast
    * 1) Content breaks into two and then three columns faster than default when moving from small to large screens
    */
  /**
    * 3-up grid that breaks slower
    * 1) Content breaks into two and then three columns slower than default when moving from small to large screens
    */
}
@media all and (min-width: 30em) {
  .em-l-grid--3up.em-l-grid--break-fast {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5rem;
  }
}
@media all and (min-width: 40em) {
  .em-l-grid--3up.em-l-grid--break-slow {
    flex-direction: column;
    margin: 0;
  }
}
@media all and (min-width: 50em) {
  .em-l-grid--3up.em-l-grid--break-slow {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -0.5rem;
  }
}
@media all and (min-width: 40em) {
  .em-l-grid--3up {
    display: flex; /* 2 */
    flex-wrap: wrap; /* 2 */
    margin: 0 -0.5rem;
  }
}

/**
 * 1-2-4-up grid
 * 1) These grid items grow from 1-up, to 2-up, to 4-up
 * 2) Display flex and flex wrap used to place items side by side on larger screens and
 *    wrap items to the next line that don't take up the remaining space
 */
.em-l-grid--1-2-4up {
  /**
    * 1-2-4-up grid that breaks fast
    * 1) Content breaks into two then four columns faster than default when moving from small to large screens
    */
  /**
    * 1-2-4-up grid that breaks slower
    * 1) Content breaks into two then four columns slower than default when moving from small to large screens
    */
}
@media all and (min-width: 30em) {
  .em-l-grid--1-2-4up.em-l-grid--break-fast {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5rem;
  }
}
@media all and (min-width: 40em) {
  .em-l-grid--1-2-4up.em-l-grid--break-slow {
    flex-direction: column;
    margin: 0;
  }
}
@media all and (min-width: 50em) {
  .em-l-grid--1-2-4up.em-l-grid--break-slow {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -0.5rem;
  }
}
@media all and (min-width: 40em) {
  .em-l-grid--1-2-4up {
    display: flex; /* 2 */
    flex-wrap: wrap; /* 2 */
    margin: 0 -0.5rem;
  }
}

/**
 * 4-up grid
 * 1) These grid items grow from 1-up, to 2-up, to 3-up, to 4-up
 * 2) Display flex and flex wrap used to place items side by side on larger screens and
 *    wrap items to the next line that don't take up the remaining space
 */
.em-l-grid--4up {
  /**
    * 4-up grid that breaks fast
    * 1) Content breaks into two, three, and four columns faster than default when moving from small to large screens
    */
  /**
    * 4-up grid that breaks slower
    * 1) Content breaks into two, three, and four columns slower than default when moving from small to large screens
    */
}
@media all and (min-width: 30em) {
  .em-l-grid--4up.em-l-grid--break-fast {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5rem;
  }
}
@media all and (min-width: 40em) {
  .em-l-grid--4up.em-l-grid--break-slow {
    flex-direction: column;
    margin: 0;
  }
}
@media all and (min-width: 50em) {
  .em-l-grid--4up.em-l-grid--break-slow {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -0.5rem;
  }
}
@media all and (min-width: 40em) {
  .em-l-grid--4up {
    display: flex; /* 2 */
    flex-wrap: wrap; /* 2 */
    margin: 0 -0.5rem;
  }
}

/**
 * 5-up grid
 * 1) These grid items grow from 1-up, to 2-up, to 3-up, to 4-up, to 5-up
 * 2) Display flex and flex wrap used to place items side by side on larger screens and
 *    wrap items to the next line that don't take up the remaining space
 */
.em-l-grid--5up {
  /**
    * 5-up grid that breaks fast
    * 1) Content breaks into two, three, four, and five columns faster than default when moving from small to large screens
    */
  /**
    * 5-up grid that breaks slower
    * 1) Content breaks into two, three, four, and five columns slower than default when moving from small to large screens
    */
}
@media all and (min-width: 30em) {
  .em-l-grid--5up.em-l-grid--break-fast {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5rem;
  }
}
@media all and (min-width: 40em) {
  .em-l-grid--5up.em-l-grid--break-slow {
    flex-direction: column;
    margin: 0;
  }
}
@media all and (min-width: 50em) {
  .em-l-grid--5up.em-l-grid--break-slow {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -0.5rem;
  }
}
@media all and (min-width: 40em) {
  .em-l-grid--5up {
    display: flex; /* 2 */
    flex-wrap: wrap; /* 2 */
    margin: 0 -0.5rem;
  }
}

/**
 * 2-3-6 grid
 * 1) These grid items grow from 2-up, to 3-up, to 6-up
 * 2) Display flex and flex wrap used to place items side by side on larger screens and
 *    wrap items to the next line that don't take up the remaining space
 */
.em-l-grid--2-3-6up {
  display: flex; /* 2 */
  flex-wrap: wrap; /* 2 */
  margin: 0 -0.5rem;
}

/**
 * 6-up grid
 * 1) These grid items grow from 1-up, to 2-up, to 3-up, to 4-up, to 5-up, to 6-up
 * 2) Display flex and flex wrap used to place items side by side on larger screens and
 *    wrap items to the next line that don't take up the remaining space
 */
.em-l-grid--6up {
  /**
    * 6-up grid that breaks fast
    * 1) Content breaks into two, three, four, five, and six columns faster than default when moving from small to large screens
    */
  /**
    * 6-up grid that breaks slower
    * 1) Content breaks into two, three, four, five, and six columns slower than default when moving from small to large screens
    */
}
@media all and (min-width: 30em) {
  .em-l-grid--6up.em-l-grid--break-fast {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5rem;
  }
}
@media all and (min-width: 40em) {
  .em-l-grid--6up.em-l-grid--break-slow {
    flex-direction: column;
    margin: 0;
  }
}
@media all and (min-width: 50em) {
  .em-l-grid--6up.em-l-grid--break-slow {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -0.5rem;
  }
}
@media all and (min-width: 40em) {
  .em-l-grid--6up {
    display: flex; /* 2 */
    flex-wrap: wrap; /* 2 */
    margin: 0 -0.5rem;
  }
}

/*------------------------------------*\
    #MASONRY GRID
\*------------------------------------*/
.em-l-masonry-grid {
  max-width: 84em;
  counter-reset: em-l-grid__item;
}
@media all and (max-width: 40em) {
  .em-l-masonry-grid .em-l-grid__item {
    width: -webkit-fill-available;
  }
}

.em-l-grid__item.em-is-expanded {
  z-index: 2;
}

/*------------------------------------*\
    #UTILITIES
\*------------------------------------*/
/*------------------------------------*\
    #SPACING UTILITIES
\*------------------------------------*/
/**
 * 1) Spacing utilities space out DOM elements on
 * the page.
 * 2) TODO: Remove `em-u-space-` classes for 1.1
 */
/**
 * No margin
 */
.em-u-space-none,
.em-u-margin-none {
  margin: 0 !important;
}

/**
 * Add margin of 0.5rem
 */
.em-u-space-half,
.em-u-margin-half {
  margin: 0.5rem !important;
}

/**
 * Add margin of 1rem
 */
.em-u-space,
.em-u-margin {
  margin: 1rem !important;
}

/**
 * Add margin of 2rem
 */
.em-u-space-double,
.em-u-margin-double {
  margin: 2rem !important;
}

/**
 * Add margin of 4rem
 */
.em-u-space-quad,
.em-u-margin-quad {
  margin: 4rem !important;
}

/**
 * No margin top
 */
.em-u-space-top-none,
.em-u-margin-top-none {
  margin-top: 0 !important;
}

/**
 * Add margin top of 0.5rem
 */
.em-u-space-top-half,
.em-u-margin-top-half {
  margin-top: 0.5rem !important;
}

/**
 * Add margin top of 1rem
 */
.em-u-space-top,
.em-u-margin-top {
  margin-top: 1rem !important;
}

/**
 * Add margin top of 2rem
 */
.em-u-space-top,
.em-u-margin-top-double {
  margin-top: 2rem !important;
}

/**
 * Add margin top of 4rem
 */
.em-u-space-top-quad,
.em-u-margin-top-quad {
  margin-top: 4rem !important;
}

/**
 * No margin right
 */
.em-u-space-right-none,
.em-u-margin-right-none {
  margin-right: 0 !important;
}

/**
 * Add margin right of 0.5rem
 */
.em-u-space-right-half,
.em-u-margin-right-half {
  margin-right: 0.5rem !important;
}

/**
 * Add margin right of 1rem
 */
.em-u-space-right,
.em-u-margin-right {
  margin-right: 1rem !important;
}

/**
 * Add margin right of 2rem
 */
.em-u-space-right-double,
.em-u-margin-right-double {
  margin-right: 2rem !important;
}

/**
 * Add margin right of 4rem
 */
.em-u-space-right-double,
.em-u-margin-right-quad {
  margin-right: 4rem !important;
}

/**
 * No Spacing
 */
.em-u-space-bottom-none,
.em-u-margin-bottom-none {
  margin-bottom: 0 !important;
}

/**
 * Margin bottom Half Spacing
 */
.em-u-space-bottom-none,
.em-u-margin-bottom-half {
  margin-bottom: 0.5rem !important;
}

/**
 * Margin bottom spacing
 */
.em-u-space-bottom,
.em-u-margin-bottom {
  margin-bottom: 1rem !important;
}

/**
 * Margin bottom Double Spacing
 */
.em-u-space-bottom-double,
.em-u-margin-bottom-double {
  margin-bottom: 2rem !important;
}

/**
 * Margin bottom quad spacing
 */
.em-u-space-bottom-quad,
.em-u-margin-bottom-quad {
  margin-bottom: 4rem !important;
}

/**
 * Margin left none
 */
.em-u-space-left-quad,
.em-u-margin-left-none {
  margin-left: 0 !important;
}

/**
 * Add margin left of 0.5rem
 */
.em-u-space-left-half,
.em-u-margin-left-half {
  margin-left: 0.5rem !important;
}

/**
 * Add margin left of 1rem
 */
.em-u-space-left,
.em-u-margin-left {
  margin-left: 1rem !important;
}

/**
 * Add margin left of 2rem
 */
.em-u-space-left-double,
.em-u-margin-left-double {
  margin-left: 2rem !important;
}

/**
 * Add margin left of 4rem
 */
.em-u-space-left-quad,
.em-u-margin-left-quad {
  margin-left: 4rem !important;
}

/*------------------------------------*\
    #PADDING
\*------------------------------------*/
/**
 * No Padding
 */
.em-u-padding-none {
  padding: 0 !important;
}

/**
 * Half Padding
 */
.em-u-padding-half {
  padding: 0.5rem !important;
}

/**
 * Default Padding
 */
.em-u-padding {
  padding: 1rem !important;
}

/**
 * Double Padding
 */
.em-u-padding-double {
  padding: 2rem !important;
}

/**
 * No Padding
 * 1) Adds 2rem to all sides padding
 */
.em-u-padding-quad {
  padding: 4rem !important;
}

/**
 * No Top Padding
 * 1) Adds 0 to top padding
 */
.em-u-padding-top-none {
  padding-top: 0 !important;
}

/**
 * Half Top Padding
 * 1) Adds 0.5rem to top padding
 */
.em-u-padding-top-half {
  padding-top: 0.5rem !important;
}

/**
 * Single Top Padding
 * 1) Adds 1rem of top padding
 */
.em-u-padding-top {
  padding-top: 1rem !important;
}

/**
 * Double Top Padding
 * 1) Adds 2rem of top padding
 */
.em-u-padding-top-double {
  padding-top: 2rem !important;
}

/**
 * quad Top Padding
 * 1) Adds 4rem of top padding
 */
.em-u-padding-top-quad {
  padding-top: 4rem !important;
}

/**
 * No right Padding
 * 1) Adds 0 to right padding
 */
.em-u-padding-right-none {
  padding-right: 0 !important;
}

/**
 * Half Right Padding
 * 1) Adds 0.5rem of right padding
 */
.em-u-padding-right-half {
  padding-right: 0.5rem !important;
}

/**
 * Single Right Padding
 * 1) Adds 1rem of right padding
 */
.em-u-padding-right {
  padding-right: 1rem !important;
}

/**
 * Double Right Padding
 * 1) Adds 2rem of right padding
 */
.em-u-padding-right-double {
  padding-right: 2rem !important;
}

/**
 * quad Right Padding
 * 1) Adds 4rem of right padding
 */
.em-u-padding-right-quad {
  padding-right: 4rem !important;
}

/**
 * No bottom Padding
 * 1) Adds 0 to bottom padding
 */
.em-u-padding-bottom-none {
  padding-bottom: 0 !important;
}

/**
 * Half Bottom Padding
 * 1) Adds 0.5rem of bottom padding
 */
.em-u-padding-bottom-half {
  padding-bottom: 0.5rem !important;
}

/**
 * Single Bottom Padding
 * 1) Adds 1rem of bottom padding
 */
.em-u-padding-bottom {
  padding-bottom: 1rem !important;
}

/**
 * Double Bottom Padding
 * 1) Adds 2rem of bottom padding
 */
.em-u-padding-bottom-double {
  padding-bottom: 2rem !important;
}

/**
 * quad Bottom Padding
 * 1) Adds 4rem of bottom padding
 */
.em-u-padding-bottom-quad {
  padding-bottom: 4rem !important;
}

.em-u-padding-bottom-quad-header {
  padding-bottom: 10rem !important;
}

/**
 * No left Padding
 * 1) Adds 0 to left padding
 */
.em-u-padding-left-none {
  padding-left: 0 !important;
}

/**
 * Half Left Padding
 * 1) Adds 0.5rem of left padding
 */
.em-u-padding-left-half {
  padding-left: 0.5rem !important;
}

/**
 * Single Left Padding
 * 1) Adds 1rem of left padding
 */
.em-u-padding-left {
  padding-left: 1rem !important;
}

/**
 * Double Left Padding
 * 1) Adds 2rem of left padding
 */
.em-u-padding-left-double {
  padding-left: 2rem !important;
}

/**
 * quad Left Padding
 * 1) Adds 4rem of left padding
 */
.em-u-padding-left-quad {
  padding-left: 4rem !important;
}

/*------------------------------------*\
    #ALIGNMENT UTILITIES
\*------------------------------------*/
/**
 * Force margin left and right 0
 */
.em-u-align-center {
  margin-left: auto !important;
  margin-right: auto !important;
}

/**
 * Force float: left
 */
.em-u-align-center {
  margin-left: auto !important;
  margin-right: auto !important;
}

/**
 * Force text-align: left
 */
.em-u-text-align-left {
  text-align: left !important;
}

/**
 * Force text-align: center
 */
.em-u-text-align-center {
  text-align: center !important;
}

/**
 * Force text-align: right
 */
.em-u-text-align-right {
  text-align: right !important;
}

/*------------------------------------*\
    #VISIBILITY UTILITIES
\*------------------------------------*/
/**
 * Completely remove from the flow and screen readers.
 */
.em-u-is-hidden {
  display: none !important;
  visibility: hidden !important;
}

/**
 * Completely remove from the flow but leave available to screen readers.
 */
.em-u-is-vishidden {
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
}

/**
 * Show Element
 */
.em-u-is-visible {
  display: block !important;
  visibility: visible !important;
}

/**
 * Hide elements on small screens and larger
 * 1) Hide elements for any screen larger than the small breakpoint ($bp-small)
 */
@media all and (min-width: 24em) {
  .em-u-hide-small-up {
    display: none !important;
    visibility: hidden !important;
  }
}

/**
 * Hide elements small-2 screens and larger
  * 1) Hide elements for any screen larger than the small-2 breakpoint ($bp-small-2)
 */
@media all and (min-width: 30em) {
  .em-u-hide-small-2-up {
    display: none !important;
    visibility: hidden !important;
  }
}

/**
 * Hide elements medium screens and larger
  * 1) Hide elements for any screen larger than the med breakpoint ($bp-med)
 */
@media all and (min-width: 35em) {
  .em-u-hide-med-up {
    display: none !important;
    visibility: hidden !important;
  }
}

/**
 * Hide elements for large screens and larger
 * 1) Hide elements for any screen larger than the large breakpoint ($bp-large)
 */
@media all and (min-width: 47em) {
  .em-u-hide-large-up {
    display: none !important;
    visibility: hidden !important;
  }
}

/**
 * Hide elements xl screens and larger
 * 1) Hide elements for any screen larger than the xl breakpoint ($bp-xl)
 */
@media all and (min-width: 62em) {
  .em-u-hide-xl-up {
    display: none !important;
    visibility: hidden !important;
  }
}

/**
 * Hide elements xl-2 screens and larger
 * 1) Hide elements for any screen larger than the xl-2 breakpoint ($bp-xl-2)
 */
@media all and (min-width: 67em) {
  .em-u-hide-xl-2-up {
    display: none !important;
    visibility: hidden !important;
  }
}

/**
 * Hide elements on small screens and smaller
 * 1) Hide elements for any screen smaller than the small breakpoint ($bp-small)
 */
@media all and (max-width: 24em) {
  .em-u-hide-small-down {
    display: none !important;
    visibility: hidden !important;
  }
}

/**
 * Hide elements small-2 screens and smaller
  * 1) Hide elements for any screen smaller than the small-2 breakpoint ($bp-small-2)
 */
@media all and (max-width: 30em) {
  .em-u-hide-small-2-down {
    display: none !important;
    visibility: hidden !important;
  }
}

/**
 * Hide elements medium screens and smaller
  * 1) Hide elements for any screen smaller than the med breakpoint ($bp-med)
 */
@media all and (max-width: 35em) {
  .em-u-hide-med-down {
    display: none !important;
    visibility: hidden !important;
  }
}

/**
 * Hide elements for large screens and smaller
 * 1) Hide elements for any screen smaller than the large breakpoint ($bp-large)
 */
@media all and (max-width: 47em) {
  .em-u-hide-large-down {
    display: none !important;
    visibility: hidden !important;
  }
}

/**
 * Hide elements xl screens and smaller
 * 1) Hide elements for any screen smaller than the xl breakpoint ($bp-xl)
 */
@media all and (max-width: 62em) {
  .em-u-hide-xl-down {
    display: none !important;
    visibility: hidden !important;
  }
}

/**
 * Hide elements xl-2 screens and smaller
 * 1) Hide elements for any screen smaller than the xl-2 breakpoint ($bp-xl-2)
 */
@media all and (max-width: 67em) {
  .em-u-hide-xl-2-down {
    display: none !important;
    visibility: hidden !important;
  }
}

/*------------------------------------*\
    #FONT SIZES
\*------------------------------------*/
.em-u-font-size-xsmall {
  font-size: 0.675rem !important;
}

.em-u-font-size-small {
  font-size: 0.75rem !important;
}

.em-u-font-size-small-2 {
  font-size: 0.875rem !important;
}

.em-u-font-size-med {
  font-size: 1rem !important;
}

.em-u-font-size-med-2 {
  font-size: 1.125rem !important;
}

.em-u-font-size-med-3 {
  font-size: 1.25rem !important;
}

.em-u-font-size-large {
  font-size: 1.5rem !important;
}

.em-u-font-size-large-2 {
  font-size: 1.75rem !important;
}

.em-u-font-size-large-3 {
  font-size: 2rem !important;
}

.em-u-font-size-xl {
  font-size: 2.2rem !important;
}

.em-u-font-size-xl-2 {
  font-size: 3.5rem !important;
}

/*------------------------------------*\
    #FONT WEIGHT UTILITIES
\*------------------------------------*/
/*
 * Font Light
 * 1) Forces the font to be lighter in weight
 * 2) DO NOT USE unless force is needed
 */
.em-u-font-style-light {
  font-family: "EMprintLight", "HelveticaNeue", "Helvetica", "Arial", sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
}

/*
 * Font Regular
 * 1) Forces the font to be regular in weight
 * 2) DO NOT USE unless force is needed
 */
.em-u-font-style-regular {
  font-family: "EMprintRegular", "HelveticaNeue", "Helvetica", "Arial", sans-serif !important;
  font-weight: 500 !important;
  font-style: normal !important;
}

/*
 * Font Regular Italic
 * 1) Forces the font to be EMprint regular italic
 * 2) DO NOT USE unless force is needed
 */
.em-u-font-style-regular-italic {
  font-family: "EMprintRegularItalic", "HelveticaNeue", "Helvetica", "Arial", sans-serif !important;
  font-weight: 500 !important;
  font-style: italic !important;
}

/*
 * Font Semibold
 * 1) Forces the font to be semibold in weight
 * 2) DO NOT USE unless force is needed
 */
.em-u-font-style-semibold {
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif !important;
  font-weight: 600 !important;
  font-style: normal !important;
}

/*
 * Font Semibold Italic
 * 1) Forces the font to be semibold in weight and italic in style
 * 2) DO NOT USE unless force is needed
 */
.em-u-font-style-semibold-italic {
  font-family: "EMprintSemiboldItalic", "HelveticaNeue", "Helvetica", "Arial", sans-serif !important;
  font-weight: 600 !important;
  font-style: italic !important;
}

/*
 * Font Bold
 * 1) Forces the font to be bolder in weight
 * 2) DO NOT USE unless force is needed
 */
.em-u-font-style-bold {
  font-family: "EMprintBold", "HelveticaNeue", "Helvetica", "Arial", sans-serif !important;
  font-weight: 700 !important;
  font-style: normal !important;
}

/*------------------------------------*\
    #WIDTH UTILITIES
\*------------------------------------*/
/**
 *
 * 1) Forces width of element
 * 2) Can be applied to table columns, divs, and any other element
 */
/**
  * Width of 5% for element
  */
.em-u-width-5 {
  width: 5% !important;
}

/**
 * Width of 10% for element
 */
.em-u-width-10 {
  width: 10% !important;
}

/**
 * Width of 20% for element
 */
.em-u-width-20 {
  width: 20% !important;
}

/**
 * Width of 25% for element
 */
.em-u-width-25 {
  width: 25% !important;
}

/**
 * Width of 33% for element
 */
.em-u-width-33 {
  width: 33.33333% !important;
}

/**
 * Width of 50% for element
 */
.em-u-width-50 {
  width: 50% !important;
}

/**
 * Width of 66.6666% for element
 */
.em-u-width-66 {
  width: 66.6666% !important;
}

/**
 * Width of 75% for element
 */
.em-u-width-75 {
  width: 75% !important;
}

/**
 * Width of 100% for element
 */
.em-u-width-100 {
  width: 100% !important;
}

/*------------------------------------*\
    #MAX WIDTHS
\*------------------------------------*/
/**
 *
 * 1) Forces max-width of element
 * 2) Can be applied to tables, divs, and any other element
 */
/**
 * Max width of 5em for element
 */
.em-u-max-width-05 {
  max-width: 5rem;
}

/**
 * Max width of 10em for element
 */
.em-u-max-width-10 {
  max-width: 10rem;
}

/**
 * Max width of 15em for element
 */
.em-u-max-width-15 {
  max-width: 15rem;
}

/**
 * Max width of 20em for element
 */
.em-u-max-width-20 {
  max-width: 20rem;
}

/**
 * Max width of 30em for element
 */
.em-u-max-width-30 {
  max-width: 30rem;
}

/**
 * Max width of 40em for element
 */
.em-u-max-width-40 {
  max-width: 40rem;
}

/**
 * Max width of 50em for element
 */
.em-u-max-width-50 {
  max-width: 50rem;
}

/**
 * Max width of 60em for element
 */
.em-u-max-width-60 {
  max-width: 60rem;
}

/**
 * Max width of 70em for element
 */
.em-u-max-width-70 {
  max-width: 70rem;
}

/**
 * Max width of 80em for element
 */
.em-u-max-width-80 {
  max-width: 80rem;
}

/**
 * Max width of 40em for element
 */
.em-u-max-width-layout {
  max-width: 84em;
}

/*------------------------------------*\
    #MIN WIDTHS
\*------------------------------------*/
/**
 *
 * 1) Forces min-width of element
 * 2) Can be applied to tables, divs, and any other element
 */
/**
  * Min width of 5em for element
  */
.em-u-min-width-05 {
  min-width: 5rem;
}

/**
  * Min width of 10em for element
  */
.em-u-min-width-10 {
  min-width: 10rem;
}

/**
  * Min width of 15em for element
  */
.em-u-min-width-15 {
  min-width: 15rem;
}

/**
  * Min width of 20em for element
  */
.em-u-min-width-20 {
  min-width: 20rem;
}

/**
  * Min width of 30em for element
  */
.em-u-min-width-30 {
  min-width: 30rem;
}

/**
  * Min width of 40em for element
  */
.em-u-min-width-40 {
  min-width: 40rem;
}

/**
  * Min width of 50em for element
  */
.em-u-min-width-50 {
  min-width: 50rem;
}

/**
  * Min width of 60em for element
  */
.em-u-min-width-60 {
  min-width: 60rem;
}

/**
  * Min width of 70em for element
  */
.em-u-min-width-70 {
  min-width: 70rem;
}

/**
  * Min width of 80em for element
  */
.em-u-min-width-80 {
  min-width: 80rem;
}

/*------------------------------------*\
    #DISPLAY UTILITIES
\*------------------------------------*/
/**
 * Force display block
 * 1) DO NOT use as general display block. Apply display block to pattern
 * 2) Used to display elements correctly that stretch within the iframe (tooltip, buttons)
 */
.em-u-display-block {
  display: block !important;
}

/**
 * Force display flex
 */
.em-u-display-flex {
  display: flex !important;
}

/**
 * Force display inline
 */
.em-u-display-inline {
  display: inline !important;
}

/**
 * Force display inline-block
 */
.em-u-display-inline-block {
  display: inline-block !important;
}

/*------------------------------------*\
	#COLOR UTILITIES
\*------------------------------------*/
/**
 *
 * COLOR SWATCHES
 * 1) Display a block of color
 * 2) Add color variations
 */
.em-u-color-swatch {
  min-height: 3rem;
  padding: 1rem;
  background-color: #ad1723 !important;
  color: #fff !important;
  border: 1px solid #808080;
}

.em-u-color-swatch--white {
  background-color: #fff !important;
  color: #111122 !important;
}

.em-u-color-swatch--bluedeep {
  background-color: #111122 !important;
}

.em-u-color-swatch--medgray {
  background-color: #545459 !important;
}

.em-u-color-swatch--darkgray {
  background-color: #212131 !important;
}

.em-u-color-swatch--denim {
  background-color: #0c69b0 !important;
}

.em-u-color-swatch--curiousblue {
  background-color: #3190d9 !important;
}

.em-u-color-swatch--violet {
  background-color: #3a397b !important;
}

.em-u-color-swatch--cyan {
  background-color: #00a3e0 !important;
}

/**
 *
 * COLOR SWATCH GRADIENT
 * 1) Show a block of color 
 */
.em-u-color-swatch--gradient {
  background: #d82424;
  background-image: linear-gradient(45deg, #ad1723 0%, #d82424 100%);
}

.em-u-color-swatch--gradient-blue {
  /* #233190 not our default $color-app-blue */
  background: #3a397b !important;
  background-image: linear-gradient(45deg, #3a397b 0%, #0c69b0 100%) !important;
}

/*------------------------------------*\
    #COLOR UTILITIES
\*------------------------------------*/
/* MAIN UI COLORS */
.em-u-color-white {
  color: #fff;
}

.em-u-color-med-gray {
  color: #545459;
}

.em-u-color-dark-gray {
  color: #212131;
}

.em-u-color-blue-deep {
  color: #111122;
}

.em-u-color-red {
  color: #d82424;
}

.em-u-color-blue {
  color: #0c69b0;
}

.em-u-color-curious-blue {
  color: #3190d9;
}

.em-u-color-cyan {
  color: #00a3e0;
}

/* ADDITIONAL COLORS */
.em-u-color-burgundy {
  color: #ad1723;
}

.em-u-color-burgundy-deep {
  color: #741120;
}

.em-u-color-burgundy-dark {
  color: #460a13;
}

.em-u-color-red-lighter {
  color: #fe000c;
}

.em-u-color-vermilion {
  color: #d93900;
}

.em-u-color-orange {
  color: #ed8b00;
}

.em-u-color-amber {
  color: #f2ac33;
}

.em-u-color-yellow {
  color: #ffd700;
}

.em-u-color-lime {
  color: #b4d405;
}

.em-u-color-green {
  color: #00af53;
}

.em-u-color-turquoise {
  color: #00aca8;
}

.em-u-color-sea-blue {
  color: #005f7f;
}

.em-u-color-blue-light {
  color: #e1efff;
}

.em-u-color-blue-darker {
  color: #104e8f;
}

.em-u-color-indigo {
  color: #3a397b;
}

.em-u-color-indigo-darker {
  color: #002f6c;
}

.em-u-color-violet {
  color: #3a397b;
}

.em-u-color-purple {
  color: #7a4183;
}

.em-u-color-cerise {
  color: #a71065;
}

.em-u-color-plum {
  color: #890c58;
}

.em-u-color-ruby {
  color: #b10040;
}

.em-u-color-med-gray-2 {
  color: #76767e;
}

.em-u-color-dark-gray {
  color: #212131;
}

/* NEUTRAL COLORS */
.em-u-color-gray-02 {
  color: #fbfbfa;
}

.em-u-color-gray-04 {
  color: #f5f5f5;
}

.em-u-color-gray-10 {
  color: #e5e5e5;
}

.em-u-color-gray-15 {
  color: #e3e3e3;
}

.em-u-color-gray-20 {
  color: #cccccd;
}

.em-u-color-gray-30 {
  color: #bbbbbd;
}

.em-u-color-gray-35 {
  color: #a5a5a5;
}

.em-u-color-gray-50 {
  color: #808080;
}

.em-u-color-gray-60 {
  color: #666666;
}

.em-u-color-gray-70 {
  color: #4d4d4d;
}

.em-u-color-gray-80 {
  color: #343434;
}

.em-u-color-gray-90 {
  color: #021834;
}

.em-u-color-black {
  color: #000;
}

/* DIM COLORS */
.em-u-color-dim-white-10 {
  color: rgba(255, 255, 255, 0.1);
}

.em-u-color-dim-white-20 {
  color: rgba(255, 255, 255, 0.2);
}

.em-u-color-dim-gray-10 {
  color: rgba(50, 50, 50, 0.1);
}

.em-u-color-dim-gray-40 {
  color: rgba(50, 50, 50, 0.4);
}

.em-u-color-dim-20 {
  color: rgba(0, 0, 0, 0.2);
}

.em-u-color-dim-30 {
  color: rgba(0, 0, 0, 0.3);
}

.em-u-color-dim-50 {
  color: rgba(0, 0, 0, 0.5);
}

.em-u-color-dim-blue-deep-50 {
  color: rgba(17, 17, 34, 0.5);
}

/* UTILITY COLORS */
.em-u-color-utility-positive {
  color: #007c38;
}

.em-u-color-utility-positive-subtle {
  color: #d4f3e6;
}

.em-u-color-utility-caution-dark {
  color: #996c1c;
}

.em-u-color-utility-caution-subtle {
  color: #fffecf;
}

.em-u-color-utility-negative-subtle {
  color: #fdded8;
}

.em-u-color-utility-neutral-subtle {
  color: #d3f2ff;
}

.em-u-color-utility-a11y-subtle {
  color: #ebe1f2;
}

/*------------------------------------*\
    #BACKGROUND COLOR UTILITIES
\*------------------------------------*/
/* MAIN UI COLORS */
.em-u-background-white {
  background-color: #fff;
}

.em-u-background-med-gray {
  background-color: #545459;
}

.em-u-background-dark-gray {
  background-color: #212131;
}

.em-u-background-blue-deep {
  background-color: #111122;
}

.em-u-background-red {
  background-color: #d82424;
}

.em-u-background-blue {
  background-color: #0c69b0;
}

.em-u-background-curious-blue {
  background-color: #3190d9;
}

.em-u-background-cyan {
  background-color: #00a3e0;
}

/* ADDITIONAL COLORS */
.em-u-background-burgundy {
  background-color: #ad1723;
}

.em-u-background-burgundy-deep {
  background-color: #741120;
}

.em-u-background-burgundy-dark {
  background-color: #460a13;
}

.em-u-background-red-lighter {
  background-color: #fe000c;
}

.em-u-background-vermilion {
  background-color: #d93900;
}

.em-u-background-orange {
  background-color: #ed8b00;
}

.em-u-background-amber {
  background-color: #f2ac33;
}

.em-u-background-yellow {
  background-color: #ffd700;
}

.em-u-background-lime {
  background-color: #b4d405;
}

.em-u-background-green {
  background-color: #00af53;
}

.em-u-background-turquoise {
  background-color: #00aca8;
}

.em-u-background-sea-blue {
  background-color: #005f7f;
}

.em-u-background-blue-light {
  background-color: #e1efff;
}

.em-u-background-blue-darker {
  background-color: #104e8f;
}

.em-u-background-indigo {
  background-color: #3a397b;
}

.em-u-background-indigo-darker {
  background-color: #002f6c;
}

.em-u-background-violet {
  background-color: #3a397b;
}

.em-u-background-purple {
  background-color: #7a4183;
}

.em-u-background-cerise {
  background-color: #a71065;
}

.em-u-background-plum {
  background-color: #890c58;
}

.em-u-background-ruby {
  background-color: #b10040;
}

.em-u-background-med-gray-2 {
  background-color: #76767e;
}

.em-u-background-dark-gray {
  background-color: #212131;
}

/* NEUTRAL COLORS */
.em-u-background-gray-02 {
  background-color: #fbfbfa;
}

.em-u-background-gray-04 {
  background-color: #f5f5f5;
}

.em-u-background-gray-10 {
  background-color: #e5e5e5;
}

.em-u-background-gray-15 {
  background-color: #e3e3e3;
}

.em-u-background-gray-20 {
  background-color: #cccccd;
}

.em-u-background-gray-30 {
  background-color: #bbbbbd;
}

.em-u-background-gray-35 {
  background-color: #a5a5a5;
}

.em-u-background-gray-50 {
  background-color: #808080;
}

.em-u-background-gray-60 {
  background-color: #666666;
}

.em-u-background-gray-70 {
  background-color: #4d4d4d;
}

.em-u-background-gray-80 {
  background-color: #343434;
}

.em-u-background-gray-90 {
  background-color: #021834;
}

.em-u-background-black {
  background-color: #000;
}

/* DIM COLORS */
.em-u-background-dim-white-10 {
  background-color: rgba(255, 255, 255, 0.1);
}

.em-u-background-dim-white-20 {
  background-color: rgba(255, 255, 255, 0.2);
}

.em-u-background-dim-gray-10 {
  background-color: rgba(50, 50, 50, 0.1);
}

.em-u-background-dim-gray-40 {
  background-color: rgba(50, 50, 50, 0.4);
}

.em-u-background-dim-20 {
  background-color: rgba(0, 0, 0, 0.2);
}

.em-u-background-dim-30 {
  background-color: rgba(0, 0, 0, 0.3);
}

.em-u-background-dim-50 {
  background-color: rgba(0, 0, 0, 0.5);
}

.em-u-background-dim-blue-deep-50 {
  background-color: rgba(17, 17, 34, 0.5);
}

/* UTILITY COLORS */
.em-u-background-utility-positive {
  background-color: #007c38;
}

.em-u-background-utility-positive-subtle {
  background-color: #d4f3e6;
}

.em-u-background-utility-caution-dark {
  background-color: #996c1c;
}

.em-u-background-utility-caution-subtle {
  background-color: #fffecf;
}

.em-u-background-utility-negative-subtle {
  background-color: #fdded8;
}

.em-u-background-utility-neutral-subtle {
  background-color: #d3f2ff;
}

.em-u-background-utility-a11y-subtle {
  background-color: #ebe1f2;
}

/*------------------------------------*\
    #LINK UTILITIES
\*------------------------------------*/
/**
 * Style for links that open in an external window
 */
.em-u-link--external {
  padding-right: 1.65em;
}

/**
 * Force removal of text-decoration on links
 */
.em-u-no-underline {
  text-decoration: none !important;
}

/*------------------------------------*\
    #CLICKABLE UTILITY
\*------------------------------------*/
/**
 * Force cursor to be pointer
 */
.em-u-clickable {
  cursor: pointer !important;
}

/*------------------------------------*\
    #VENDOR
\*------------------------------------*/
/**
 * 1) Vendor styles are styles that control vendor libraries.
 */
:root {
  --text-color: #111;
  --secondary-color: #767676;
  --border-color: #949494;
  --bg-color: #fff;
  --disabled-color: #959595;
  --hover-color: #f2f3f5;
  --bg2-color: #f2f3f5;
  --focus-color: #0000ff;
  --error-color: #d4351c;
}

inclusive-dates-calendar,
inclusive-dates-calendar *,
inclusive-dates-calendar *:before,
inclusive-dates-calendar *:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

inclusive-dates-calendar {
  display: inline-flex;
}

inclusive-dates *:focus-visible {
  outline: 0.25rem solid var(--focus-color);
}

.inclusive-dates-calendar,
.inclusive-dates {
  display: block;
  width: min-content;
  min-width: 18rem;
  max-width: 24rem;
  border: 0.0625rem solid var(--border-color);
  border-radius: 0.375rem;
  color: var(--text-color);
  background-color: var(--bg-color);
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

@media screen and (min-width: 23.5rem) {
  .inclusive-dates-calendar,
.inclusive-dates {
    min-width: 20rem;
    max-width: 24rem;
  }
}
.inclusive-dates-calendar--disabled *[aria-disabled] {
  opacity: 0.5;
  cursor: default;
}

.inclusive-dates-calendar--disabled .inclusive-dates-calendar__weekday {
  color: var(--disabled-color);
}

.inclusive-dates-calendar--disabled .inclusive-dates-calendar__date:focus > * {
  outline: none;
}

.inclusive-dates-calendar--disabled .inclusive-dates-calendar__date {
  cursor: default;
  opacity: 0.5;
}

.inclusive-dates-calendar--disabled .inclusive-dates-calendar__date:hover > * {
  background-color: rgba(0, 0, 0, 0);
}

.inclusive-dates-calendar--disabled .inclusive-dates-calendar__date--in-range:hover > * {
  background-color: #f2f3f5;
}

.inclusive-dates-calendar--disabled .inclusive-dates-calendar__date--selected:hover > * {
  color: #fff;
  background-color: var(--hover-color);
}

.inclusive-dates-calendar__header {
  display: flex;
  padding: 0.75rem;
  align-items: center;
  gap: 0.5rem;
}

.inclusive-dates-calendar__current-month {
  display: flex;
  flex-grow: 1;
  gap: 0.5rem;
}

.inclusive-dates-calendar__month-select,
.inclusive-dates-calendar__year-select {
  display: flex;
  height: 2rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  align-items: center;
  border: none;
  border-radius: 0.25rem;
  color: var(--text-color);
  background: var(--bg2-color);
  font: inherit;
  line-height: 1.125;
}

@media screen and (forced-colors: active) {
  .inclusive-dates-calendar__month-select,
.inclusive-dates-calendar__year-select {
    border: 1px solid rgba(0, 0, 0, 0);
  }
}
.inclusive-dates-calendar__month-select {
  flex-grow: 1;
  cursor: pointer;
  appearance: none;
}

.inclusive-dates-calendar__year-select {
  max-width: 5rem;
}

.inclusive-dates-calendar__previous-month-button,
.inclusive-dates-calendar__next-month-button,
.inclusive-dates-calendar__previous-year-button,
.inclusive-dates-calendar__next-year-button {
  display: inline-flex;
  width: 2rem;
  height: 2rem;
  padding: 0.375rem;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 0.25rem;
  color: var(--text-color);
  background-color: var(--bg2-color);
  cursor: pointer;
}

.inclusive-dates-calendar__previous-month-button[aria-disabled],
.inclusive-dates-calendar__next-month-button[aria-disabled],
.inclusive-dates-calendar__previous-year-button[aria-disabled],
.inclusive-dates-calendar__next-year-button[aria-disabled] {
  color: var(--disabled-color);
}

@media screen and (forced-colors: active) {
  .inclusive-dates-calendar__previous-month-button[aria-disabled],
.inclusive-dates-calendar__next-month-button[aria-disabled],
.inclusive-dates-calendar__previous-year-button[aria-disabled],
.inclusive-dates-calendar__next-year-button[aria-disabled] {
    color: GrayText;
  }
}
.inclusive-dates-calendar__body {
  padding-right: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 0.75rem;
}

.inclusive-dates-calendar__calendar {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.inclusive-dates-calendar__weekday > span {
  display: flex;
  padding: 0.2rem;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  aspect-ratio: 1;
}

.inclusive-dates-calendar__date {
  padding: 0.125rem;
  text-align: center;
  cursor: pointer;
}

.inclusive-dates-calendar__date:focus {
  outline: none;
}

.inclusive-dates-calendar__date:focus-visible > * {
  outline: 0.25rem solid var(--focus-color);
}

@media screen and (forced-colors: active) {
  .inclusive-dates-calendar__date:focus-visible > * {
    outline-color: Highlight;
  }
}
.inclusive-dates-calendar__date:hover > * {
  background-color: var(--hover-color);
}

.inclusive-dates-calendar__date > * {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  aspect-ratio: 1;
}

.inclusive-dates-calendar__date--today > * {
  font-weight: 700;
  font-style: normal;
  color: var(--focus-color);
}

.inclusive-dates-calendar__date--selected > *,
.inclusive-dates-calendar__date--selected:hover > *,
.inclusive-dates-calendar__date--in-range:hover > *,
.inclusive-dates-calendar__date--in-range > * {
  color: var(--bg-color);
  background-color: var(--focus-color);
  font-weight: 500;
  outline-offset: 0.125rem;
}

@media screen and (forced-colors: active) {
  .inclusive-dates-calendar__date--selected > *,
.inclusive-dates-calendar__date--selected:hover > *,
.inclusive-dates-calendar__date--in-range:hover > *,
.inclusive-dates-calendar__date--in-range > * {
    background-color: Highlight;
  }
}
.inclusive-dates-calendar__date--disabled {
  color: var(--disabled-color);
  cursor: default;
  position: relative;
}

@media screen and (forced-colors: active) {
  .inclusive-dates-calendar__date--disabled {
    color: GrayText;
  }
}
.inclusive-dates-calendar__date--disabled::after {
  content: "";
  position: absolute;
  height: 1.5rem;
  width: 0.078125rem;
  background-color: var(--disabled-color);
  transform: rotate(45deg);
  right: 50%;
  top: 25%;
}

.inclusive-dates-calendar__date--disabled:not(.inclusive-dates-calendar__date--selected):not(.inclusive-dates-calendar__date--in-range):hover > * {
  background-color: rgba(0, 0, 0, 0);
}

.inclusive-dates-calendar__date--disabled.inclusive-dates-calendar__date--in-range:not(.inclusive-dates-calendar__date--selected):not(.inclusive-dates-calendar__date--in-range) > * {
  background-color: rgba(0, 0, 0, 0);
}

.inclusive-dates-calendar__footer {
  padding-right: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.inclusive-dates-calendar__footer-buttons {
  align-items: center;
  display: flex;
  gap: 0.5rem;
}

.inclusive-dates-calendar__clear-button,
.inclusive-dates-calendar__today-button,
.inclusive-dates__quick-button,
.inclusive-dates__calendar-button {
  display: inline-flex;
  height: 2rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 0.25rem;
  color: var(--text-color);
  background-color: var(--bg2-color);
  font: inherit;
  line-height: 1;
  cursor: pointer;
}

.label {
  font-weight: 600;
  display: block;
}

.inclusive-dates__calendar-button {
  margin-left: 0.5rem;
  height: 2.5rem;
}

.inclusive-dates__quick-group {
  margin-top: 0.5rem;
  display: inline-grid;
  grid-auto-flow: column;
  gap: 0.5rem;
}

.inclusive-dates__quick-button {
  color: var(--text-color);
  background-color: inherit;
  text-decoration: underline;
}

.inclusive-dates__input {
  box-sizing: border-box;
  display: inline-flex;
  height: 2.5rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  color: var(--text-color);
  background: var(--bg2-color);
  line-height: 1;
  border: 1px solid var(--border-color);
  font-size: 1rem;
}

.inclusive-dates__input[aria-invalid] {
  border-color: var(--error-color);
}

.inclusive-dates-calendar__keyboard-hint {
  color: var(--secondary-color);
  display: flex;
  align-items: center;
  gap: 0.25rem;
  text-decoration: underline;
  text-underline-offset: 0.2em;
  border: none;
  margin: 0;
  width: auto;
  overflow: visible;
  background: rgba(0, 0, 0, 0);
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  padding: 0.25rem;
}

.inclusive-dates__label {
  font-weight: 600;
  margin-bottom: 0.5rem;
  display: inline-block;
  font-family: "Segoe UI", sans-serif;
}

.inclusive-dates__input {
  flex-grow: 1;
}

.inclusive-dates__input-container {
  min-width: 300px;
  max-width: 400px;
  display: flex;
}

.inclusive-dates__input-error {
  display: flex;
  padding: 0.5rem;
  color: var(--error-color);
  font-weight: 700;
} /*# sourceMappingURL=light.css.map */