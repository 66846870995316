@import "../../Assets/constants";
.email-modal{
    background: white;
    width: 40rem;
    min-height: 35rem;
    padding: 2rem;
    @include respond-to('medium') {
        max-width: 29rem;
    }
    @include respond-to('small') {
        max-width: 19rem;       
    }
    @include respond-to('smaller') {
        padding: 1.5rem !important;      
    }
    &__content{
        &--header{
            display: flex;
            font-size: 1.75rem;
            margin-bottom: 1.3rem;
            align-items: flex-end;
            font-family: EMPrintSemibold;
            @include respond-to('smaller') {
                margin-bottom: 0 !important;      
            }
            @include respond-to('small') {
                align-items: center;
                font-size: 1.25rem;
            }
            @include respond-to('medium') {
                align-items: center;
                font-size: 1.25rem;
            }
            svg{
                margin-right: 1rem;
                color: black;
                width: 3rem;
                height: 3rem;
                @include respond-to('smaller') {
                    height: 2.5rem;
                }
            }
        }
        &--field{
            display: flex;
            justify-content: space-between;
            margin-top: 0.5rem;
            @include respond-to('smaller') {
                display: block !important;
            }
            @include respond-to('small') {
                text-align: inherit;
                display: flex;
                flex-direction: column;
            }
            @include respond-to('medium') {
                text-align: inherit;
                display: flex;
                flex-direction: column;
            }
            &__label{
                margin: 0.6rem 0.5rem 0.5rem 0;
                @include respond-to('smaller') {
                    width: 28% !important;
                }
                @include respond-to('small') {
                    width: 100%;
                }
                @include respond-to('medium') {
                    width: 100%;
                }
            }
            .em-c-input{
                border-radius: 0px;
                width: 31.5rem;
                z-index: 10;
                @include respond-to('small') {
                    width: 15rem;       
                }
                @include respond-to('medium') {
                    width: 25rem;
                }
            }
        }
        &--file{
            margin: 1rem 0;
            display: table;
            align-items: center;
            height: 2rem;
            width: fit-content;
            padding: 0.5rem;
            border-radius: 4px;
            border: 1px solid #ccc;
            background: rgb(235, 235, 228);
            font-size: 0.8rem;
            cursor: pointer;
            font-family: 'EMprintSemibold';
            svg{
                margin-right: 0.5rem;
                height: 0.8rem;
                width: 0.8rem;
            }
        }
        .em-c-field__note{
            margin-left: 4.5rem;
            font-family: EMPrintLight;
            color: rgb(99, 98, 98);
        }
        &--textarea{
            margin-bottom: 1rem;
            textarea{
                @include respond-to('medium') {
                    min-height: 11rem;
                    width: 25rem;
                }
                @include respond-to('small') {
                    width: 15rem;       
                }
                @include respond-to('smaller') {
                    min-height: 6.5rem !important;      
                }
                border-radius: 0px;
                min-height: 15rem;
                resize: none;
                overflow: auto;       
            }
        }
    }
    &__buttons{
        display: flex;
        .em-c-btn{
            margin-right: 1rem;
            border-radius: 0px;
            .spinner > div{
                background-color: white;
            }
        }
    }
}